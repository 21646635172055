/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import React, { useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function DocUploader({
  file,
  setFile,
  readonly,
  filename,
  isFileRequired,
}) {
  const fileInputRef = useRef();

  const { t } = useTranslation();

  const dropHandler = (event) => {
    if (readonly) {
      return;
    }

    event.preventDefault();

    let file;

    if (event.dataTransfer.items) {
      const item = event.dataTransfer.items[0];
      if (item.kind === 'file') {
        file = item.getAsFile();
      }
    } else {
      file = event.dataTransfer.files[0];
    }

    if (!file) {
      return;
    }

    if (file.type !== 'application/pdf' && file.type !== 'application/msword') {
      return;
    }

    setFile(file);
  };

  return (
    <div className={classes.DocUploader}>
      <input
        type="file"
        ref={fileInputRef}
        accept=".doc,.docx,.pdf"
        onChange={(event) => {
          setFile(event.target.files[0]);
        }}
      />
      <header className={classes.header}>
        <h1>
          {t(
            'components.RecordVideo.AdditionalInfoForm.DocUploader.attachment'
          )}
        </h1>
        <i>
          <svg
            width="3"
            height="10"
            viewBox="0 0 3 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.25H1.03125"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 8.75V4.75"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </i>
        {!isFileRequired && (
          <span>
            (
            {t(
              'components.RecordVideo.AdditionalInfoForm.DocUploader.optional'
            )}
            )
          </span>
        )}
      </header>
      <div
        onDragOver={(event) => event.preventDefault()}
        className={classes.uploader}
        onClick={() => {
          if (readonly) {
            return;
          }

          fileInputRef.current.click();
        }}
        onDrop={dropHandler}
      >
        <div className={classes.text}>
          {file || readonly ? (
            <>
              <span
                className={classNames(classes.fileType, {
                  [classes.hasFile]: !!file,
                })}
              >
                {file?.name ||
                  file?.fileName ||
                  file?.[0]?.displayName ||
                  filename}
                <i
                  onClick={(event) => {
                    event.stopPropagation();
                    setFile(null);
                  }}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.99975 3.99582L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00299 4.99906L9.79233 8.7884C10.0694 9.06544 10.0694 9.5146 9.79233 9.79164C9.51529 10.0687 9.06612 10.0687 8.78908 9.79164L4.99975 6.00231L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99651 4.99906L0.209068 1.21162C-0.0679701 0.934586 -0.0679701 0.485419 0.209068 0.208381C0.486105 -0.0686568 0.935272 -0.0686568 1.21231 0.208381L4.99975 3.99582Z"
                      fill="white"
                    />
                  </svg>
                </i>
              </span>
            </>
          ) : (
            <>
              <span>
                {t(
                  'components.RecordVideo.AdditionalInfoForm.DocUploader.dragDropOr'
                )}{' '}
                <span className={classes.browse}>
                  {t(
                    'components.RecordVideo.AdditionalInfoForm.DocUploader.browse'
                  )}
                </span>
              </span>
              <span className={classes.fileType}>
                {t(
                  'components.RecordVideo.AdditionalInfoForm.DocUploader.fileType'
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
