import React from 'react';

import Notifications from '../../components/Notifications';

import classes from './styles.module.scss';

export default function MyNotificationsPage() {
  return (
    <div className={classes.MyNotificationsPage}>
      <Notifications />
    </div>
  );
}
