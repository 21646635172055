import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Button from '../../../Button';
import BlackButton from '../BlackButton';
import Dropdown from '../../../Dropdown';

import classes from './styles.module.scss';

export default function DeclineForm({
  options,
  onBackButtonClick,
  onSubmit,
  title,
  subtitle,
  label,
  placeholder,
  name,
  optionActions,
}) {
  const [value, setValue] = useState('');

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const submitForm = () => {
    const action = optionActions.find((oA) => oA.value === value);

    if (action) {
      action.action();
    } else {
      onSubmit(value);
    }
  };

  return (
    <div className={classes.DeclineForm}>
      <i className={classes.backButton} onClick={onBackButtonClick}>
        Back
      </i>
      <div className={classes.container}>
        <h3 className={classes.heading}>{title}</h3>
        <div className={classes.dropdownContainer}>
          <p className={classes.subtitle}>{subtitle}</p>
          <Dropdown
            label={label}
            placeholder={placeholder}
            options={options}
            withShadow
            value={value}
            name={name}
            setFieldValue={(_name, val) => setValue(val)}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <BlackButton
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={() => setValue('')}
        >
          {t('common.clear')}
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          jobApplicationInfo
          disabled={!value}
          onClick={submitForm}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
