import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

import classes from './styles.module.scss';

export default function CookiesPolicy() {
  console.log('CookiesPolicy');
  const [isAgreedToCookiesPolicy, setIsAgreedToCookiesPolicy] = useState(false);

  const { t } = useTranslation();

  const agreeToCookiesPolicy = () => {
    localStorage.setItem('isAgreedToCookiesPolicy', true);
    setIsAgreedToCookiesPolicy(true);
  };

  useEffect(() => {
    const isAgreed = localStorage.getItem('isAgreedToCookiesPolicy');

    setIsAgreedToCookiesPolicy(isAgreed);
  }, []);

  if (isAgreedToCookiesPolicy) {
    return null;
  }

  return (
    <div className={classes.CookiesPolicy}>
      <p>
        {t('components.CookiesPolicy.cookiesPolicyText1')} {'  '}
        <Link to="/policy">
          {t('components.CookiesPolicy.cookiesPolicyText2')}
        </Link>{' '}
        {t('components.CookiesPolicy.cookiesPolicyText3')}
      </p>
      <Button
        width={96}
        height={40}
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 24,
          paddingTop: 2,
          boxShadow:
            '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
        }}
        onClick={agreeToCookiesPolicy}
      >
        {t('components.CookiesPolicy.cookiesPolicyButtonText')}
      </Button>
    </div>
  );
}
