import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../components/Button';
import classes from './styles.module.scss';

export default function TakePictureModal({ show, handleClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.TakePictureModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h1>{t('modals.TakePictureModal.title')}</h1>
        <p>{t('modals.TakePictureModal.description')}</p>
        <Button
          width={128}
          height={37}
          style={{ fontSize: 18 }}
          onClick={handleClose}
        >
          {t('modals.TakePictureModal.buttonLabel')}
        </Button>
      </div>
    </Modal>
  );
}
