import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Title({ step }) {
  const { t } = useTranslation();

  let title = '';

  if (step?.name === 'Picture') {
    title = t('components.RecordVideo.RecordVideoControls.title.selfie');
  } else if (step?.name === 'Review & Upload') {
    title = t('components.RecordVideo.RecordVideoControls.title.fantastic');
  } else {
    title = step?.name;
  }

  return <h1 className={classes.Title}>{title}</h1>;
}
