import React, { useState, useEffect } from 'react';

import Star from './Star';

import classes from './styles.module.scss';

export default function Rating({ label, value, setValue }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setCurrentValue(value);
    }
  }, [isHovered, value]);

  return (
    <div className={classes.Rating}>
      <h3 className={classes.label}>{label}</h3>
      <div className={classes.stars}>
        {Array(5)
          .fill(null)
          .map((_, i) => i)
          .map((num, index) => (
            <Star
              index={index}
              key={num}
              currentValue={currentValue}
              setCurrentValue={setCurrentValue}
              setIsHovered={setIsHovered}
              setValue={setValue}
            />
          ))}
      </div>
    </div>
  );
}
