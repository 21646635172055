import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

const formatTime = (time) => {
  if (time < 0) {
    return '0:00';
  }

  const minutes = time >= 60 ? Math.trunc(time / 60) : 0;
  const seconds = time - minutes * 60;

  return `${minutes}:${
    seconds.toString().length === 1 ? `0${seconds}` : seconds
  }`;
};

let interval;

export default function Timer({
  time,
  isVideoRecording,
  isVideoRecordingPaused,
  stopVideoRecording,
}) {
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('transparent');

  useEffect(() => {
    setSecondsLeft(time);
  }, [time]);

  useEffect(() => {
    if (secondsLeft <= 0 && isVideoRecording) {
      stopVideoRecording();
    }
  }, [isVideoRecording, secondsLeft, stopVideoRecording]);

  /* useEffect(() => {
    if (isVideoRecording) {
      interval = setInterval(() => {
        setSecondsLeft((prevValue) => prevValue - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setSecondsLeft(time);
    }
  }, [isVideoRecording, time]);

  useEffect(() => {
    if (secondsLeft <= 0) {
      clearInterval(interval);
    }
  }, [secondsLeft]); */

  useEffect(() => {
    if (isVideoRecordingPaused && isVideoRecording) {
      clearInterval(interval);
    } else if (isVideoRecording && !isVideoRecordingPaused) {
      interval = setInterval(() => {
        setSecondsLeft((prevValue) => prevValue - 1);
      }, 1000);
    } else if (!isVideoRecording) {
      clearInterval(interval);
      setSecondsLeft(time);
    }
  }, [isVideoRecording, isVideoRecordingPaused, time]);

  useEffect(() => {
    if (isVideoRecording) {
      if (secondsLeft >= time / 2) {
        setBackgroundColor('#0ACF83');
      } else if (secondsLeft < time / 2 && secondsLeft >= time / 4) {
        setBackgroundColor('#FFBF00');
      } else {
        setBackgroundColor('#FF2D55');
      }
    } else {
      setBackgroundColor('transparent');
    }
  }, [time, isVideoRecording, secondsLeft]);

  return (
    <div className={classes.Timer} style={{ backgroundColor }}>
      <span>{formatTime(secondsLeft)}</span>
    </div>
  );
}
