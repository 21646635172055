import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import JobApplicationInfo from '../../components/MyDashboard/JobApplicationInfo';
import Job from '../../components/Jobs/Job';
import NoFavorites from './NoFavorites';

import { getAuthData } from '../../helpers/authStorage';
import AnonymousService from '../../services/AnonymousService';
import PublicService from '../../services/PublicService';
import classes from './styles.module.scss';

export default function MyFavoritesPage() {
  const [isJobApplicationInfoVisible, setIsJobApplicationInfoVisible] =
    useState(false);
  const [isJobApplicationInfoMounted, setIsJobApplicationInfoMounted] =
    useState(false);
  const [currentJobApplicationId, setCurrentJobApplicationId] = useState(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userId } = getAuthData();

  const {
    data: jobPages,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['favoriteJobs', userId],
    queryFn: () =>
      AnonymousService.getJobs({
        Filters: `isFavorite:eq:true`,
        PageSize: 99999,
      }),
    keepPreviousData: true,
  });

  const { data: jobApplications } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: PublicService.getJobApplications,
    keepPreviousData: true,
  });

  const jobs = jobPages?.data?.jobPosts;

  const showJobApplicationInfo = (jobApplicationId) => {
    setCurrentJobApplicationId(jobApplicationId);
    setIsJobApplicationInfoMounted(true);

    setTimeout(() => {
      setIsJobApplicationInfoVisible(true);
    }, 0);
  };

  const hideJobsApplicationInfo = () => {
    setIsJobApplicationInfoVisible(false);
  };

  return (
    <div className={classes.MyFavoritesPage}>
      {isFetching && !jobs && <Loader />}
      <div className={classes.header}>
        <button
          type="button"
          className={classes.backButton}
          onClick={() => navigate(-1)}
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
              fill="#242833"
            />
          </svg>
        </button>
        <h2>
          {t('components.Header.my')} {t('components.Header.favorites')}
        </h2>
      </div>
      {jobs && jobs?.length ? (
        <>
          <ul className={classes.jobsList}>
            {jobs?.map((job) => {
              return (
                <li key={job.id}>
                  <Job
                    showJobApplicationInfo={showJobApplicationInfo}
                    stretch
                    refetch={refetch}
                    job={job}
                    jobApplication={jobApplications?.find((jobApplication) =>
                      job.jobApplications?.some(
                        (jobApp) => jobApp.id === jobApplication.id
                      )
                    )}
                  />
                </li>
              );
            })}
          </ul>
          {isJobApplicationInfoMounted && (
            <JobApplicationInfo
              isVisible={isJobApplicationInfoVisible}
              currentJobApplication={jobApplications?.find(
                (jobApplication) =>
                  jobApplication.id === currentJobApplicationId
              )}
              hideJobsApplicationInfo={hideJobsApplicationInfo}
              refetch={refetch}
            />
          )}
        </>
      ) : (
        <NoFavorites />
      )}
    </div>
  );
}
