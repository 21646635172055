import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';
// import pauseButton from '../../../assets/images/video-player-pause-button-red.svg';

export default function PlayButton({
  onClick,
  onPauseClick,
  playAnimation,
  isPlaying,
}) {
  return (
    <button
      type="button"
      onClick={isPlaying ? onPauseClick : onClick}
      className={classNames(classes.PlayButton, {
        [classes.animate]: playAnimation,
      })}
    >
      <span className={classes.button}>
        {isPlaying ? (
          <span className={classes.lines}>
            <span className={classes.line} />
            <span className={classes.line} />
          </span>
        ) : (
          <span className={classes.triangle} />
        )}
      </span>
    </button>
  );
}
