import React, { useState, useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PreviousJobApplicationModal from '../../modals/PreviousJobApplicationModal';
import SignInModal from '../../modals/SignInModal';
import NavHeader from '../../components/NavHeader';
import QrCodeModal from '../../modals/QrCodeModal';

import useLogoutBlockedUser from '../../hooks/useLogoutBlockedUser';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

export default function ApplyJobPage() {
  const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
  const [
    isPreviousJobApplicationModalVisible,
    setIsPreviousJobApplicationModalVisible,
  ] = useState(false);
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { isUserProfileFilled } = useContext(UiContext);

  const { checkAndLogout } = useLogoutBlockedUser();

  const apply = async (type) => {
    if (isUserProfileFilled === false) {
      setIsSignInModalVisible(true);
      return;
    }

    // If user is blocked, logout
    await checkAndLogout();

    const query = new URLSearchParams(location.search);
    const jobApplicationId = query.get('jobApplicationId');

    if (type === 'video') {
      if (isTabletOrMobile) {
        navigate(
          jobApplicationId
            ? `video?jobApplicationId=${jobApplicationId}`
            : 'video'
        );
      } else {
        setIsQrCodeModalVisible(true);
      }
    } else {
      navigate(
        jobApplicationId ? `info?jobApplicationId=${jobApplicationId}` : 'info'
      );
    }
  };

  const showPreviousApplicationModal = () => {
    if (isUserProfileFilled === false) {
      setIsSignInModalVisible(true);
      return;
    }

    setIsPreviousJobApplicationModalVisible(true);
  };

  return (
    <div className={classes.ApplyJobPage}>
      <div className={classes.header}>
        <NavHeader noBack top={46} />
      </div>
      <div className={classes.container}>
        <h1>{t('common.apply')}</h1>
        <p className={classes.info}>{t('pages.ApplyJobPage.altText')}</p>
        <div className={classes.cards}>
          <div
            className={classNames(classes.card, classes.recommended)}
            onClick={() => apply('video')}
          >
            <h2>{t('pages.ApplyJobPage.recordVideo')}</h2>
            <p>{t('pages.ApplyJobPage.quickProcessText')}</p>
          </div>
          <div className={classes.or}>{t('pages.ApplyJobPage.or')}</div>
          <div className={classes.shadow} />
          <div className={classes.card} onClick={() => apply('info')}>
            <h2>{t('pages.ApplyJobPage.completeAppText')}</h2>
            <p>{t('pages.ApplyJobPage.longProcessText')}</p>
          </div>
        </div>
        <p className={classes.savedAccount}>
          {t('pages.ApplyJobPage.savedAppLinkText')}{' '}
          <span onClick={showPreviousApplicationModal}>
            {t('pages.ApplyJobPage.clickHere')}
          </span>
        </p>
      </div>
      <SignInModal
        profile
        show={isSignInModalVisible}
        handleClose={() => setIsSignInModalVisible(false)}
      />
      <PreviousJobApplicationModal
        show={isPreviousJobApplicationModalVisible}
        handleClose={() => setIsPreviousJobApplicationModalVisible(false)}
      />
      <QrCodeModal
        show={isQrCodeModalVisible}
        handleClose={() => {
          setIsQrCodeModalVisible(false);
        }}
      />
    </div>
  );
}
