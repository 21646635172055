import React from 'react';

import FeedbackForm from './FeedbackForm';

import classes from './styles.module.scss';

export default function Feedback({
  jobApplicationId,
  hideJobsApplicationInfo,
}) {
  return (
    <div className={classes.Feedback}>
      <FeedbackForm
        jobApplicationId={jobApplicationId}
        hideJobsApplicationInfo={hideJobsApplicationInfo}
      />
    </div>
  );
}
