import React from 'react';

import NavHeader from '../../components/NavHeader';

import classes from './styles.module.scss';

export default function FullScreenModal({ children }) {
  return (
    <div className={classes.FullScreenModal}>
      <div className={classes.header}>
        <div className={classes.navContainer}>
          <NavHeader top={30} />
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
