import React from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../../../Dropdown';

import classes from './styles.module.scss';

export default function DeclineInterview({ declineReason, setDeclineReason }) {
  const { t } = useTranslation();

  const options = [
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.option1'
      ),
      value: 'Reschedule',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.option2'
      ),
      value: 'AnotherJob',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.option3'
      ),
      value: 'NotIntereseted',
    },
  ];
  return (
    <div className={classes.DeclineInterview}>
      <div className={classes.header}>
        <p className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.headerHeading'
          )}
        </p>
        <p className={classes.question}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.headerQuestion'
          )}
        </p>
      </div>
      <div className={classes.dropdownContainer}>
        <Dropdown
          options={options}
          withShadow
          placeholder={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.DeclineInterview.dropdownContainerPlaceholder'
          )}
          value={declineReason}
          name="declineReason"
          setFieldValue={(_name, value) => setDeclineReason(value)}
        />
      </div>
    </div>
  );
}
