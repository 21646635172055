import React from 'react';

import { useTranslation } from 'react-i18next';

import notFoundIcon from '../../assets/images/notFound.svg';
import classes from './styles.module.scss';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className={classes.NotFound}>
      <div className={classes.container}>
        <div className={classes.message}>
          <span className={classes.message}>
            {t('components.NotFound.sorry')}
          </span>
        </div>
        <div className={classes.illustration}>
          <img src={notFoundIcon} alt="Not found" />
        </div>
        <div className={classes.message}>
          <h1>{t('components.NotFound.somethingWentWrong')}</h1>
        </div>
      </div>
    </div>
  );
}
