/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import video1Placeholder from '../../../assets/images/landing/video-1-placeholder.jpg';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';

export default function AutoPlayVideo() {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const componentRef = useRef();
  const videoRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const [setNode, entry] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: isTabletOrMobile ? 1 : 0.75,
  });

  useEffect(() => {
    if (componentRef.current) {
      setNode(componentRef.current);
    }
  }, [setNode]);

  useEffect(() => {
    if (entry.isIntersecting) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [entry]);

  // Show video instead of image if it is loaded properly
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.oncanplay = () => {
        setIsVideoVisible(true);
      };
    }
  }, []);

  const setNodeHeaderLinkHighlighting = useHeaderLinkHighlighting('');

  return (
    <div
      className={classNames(classes.AutoPlayVideo, {
        [classes.videoVisible]: isVideoVisible,
      })}
      ref={componentRef}
    >
      <div ref={setNodeHeaderLinkHighlighting}>
        <video
          playsInline
          ref={videoRef}
          src={`${process.env.PUBLIC_URL}/videos/video1.mp4`}
          muted
          loop
        />
        <img src={video1Placeholder} alt="Placeholder" />
      </div>
    </div>
  );
}
