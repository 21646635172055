/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import PlayButton from '../PlayButton';

import classes from './styles.module.scss';
import video2Placeholder from '../../../assets/images/landing/video-2-placeholder.jpg';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
// import useVideoRewindOnScroll from '../../../hooks/useVideoRewindOnScroll';

export default function WordAbout() {
  const [isImageCoverVisible, setIsImageCoverVisible] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const { t } = useTranslation();

  const videoRef = useRef();

  // useVideoRewindOnScroll(videoRef, isVideoPlaying);

  const [setNode, entry] = useIntersectionObserver({
    threshold: 0.4,
  });

  const playVideo = () => {
    setIsImageCoverVisible(false);
    videoRef.current.play();
    setIsVideoPlaying(true);
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setIsVideoPlaying(false);
  };

  // Pause video when it's not in the viewport
  useEffect(() => {
    setNode(videoRef.current);
  }, [setNode]);

  useEffect(() => {
    if (!entry) {
      return;
    }

    if (!entry.isIntersecting) {
      pauseVideo();
    }
  }, [entry]);

  const setNodeHeaderLinkHighlighting = useHeaderLinkHighlighting('word-about');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      id="word-about-kfc"
      className={classNames(classes.WordAbout, {
        [classes.playing]: isVideoPlaying,
      })}
      ref={setNodeHeaderLinkHighlighting}
    >
      <div className={classes.header}>
        <h2 className={classes.title}>
          {t('pages.LandingPage.WordAbout.text.0')}
          {'  '}
          <span className={classes.white}>
            {t('pages.LandingPage.WordAbout.text.1')}
          </span>
        </h2>
      </div>
      <div className={classes.videoContainer}>
        {isImageCoverVisible && (
          <img
            src={video2Placeholder}
            alt="Placeholder"
            className={classes.imageCover}
          />
        )}
        {(!isTabletOrMobile || !isVideoPlaying) && (
          <div className={classes.buttonContainer}>
            <PlayButton
              onClick={playVideo}
              isPlaying={isVideoPlaying}
              onPauseClick={pauseVideo}
            />
          </div>
        )}
        <video
          playsInline
          ref={videoRef}
          onClick={() => {
            if (isTabletOrMobile) {
              pauseVideo();
            }
          }}
          src="https://stzaphireplatformkfc.blob.core.windows.net/static/vid3-enc.mp4"
          loop
          className={classes.video}
        />
      </div>
    </div>
  );
}
