/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

export default function Country({
  country,
  activeCountry,
  setActiveCountry,
  handleClose,
}) {
  return (
    <li
      className={classNames(classes.Country, {
        [classes.isActive]: activeCountry?.name === country?.name,
      })}
      onClick={() => {
        setActiveCountry(country);
        handleClose();
      }}
    >
      <img
        src={`https://flagcdn.com/w40/${country.iso.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w80/${country.iso.toLowerCase()}.png 2x`}
        width="30"
        alt="Country"
      />
      <span className={classes.name}>{country?.name}</span>
      <span className={classes.code}>{country?.phoneCode}</span>
    </li>
  );
}
