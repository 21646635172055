import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import PublicService from '../../../../services/PublicService';
import Button from '../../../Button';

import Textarea from '../../../Textarea';
import BlackButton from '../BlackButton';

import { UiContext } from '../../../../context/UiContext';
import classes from './styles.module.scss';

export default function ContactUs({
  jobApplicationId,
  hideJobsApplicationInfo,
}) {
  const [message, setMessage] = useState('');

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const sendMessage = async () => {
    try {
      setIsFetching(true);
      await PublicService.sendJobApplicationMessage({
        message,
        jobApplicationId,
      });
      hideJobsApplicationInfo();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.ContactUs}>
      <div className={classes.container}>
        <div className={classes.textareaContainer}>
          <Textarea
            height={isTabletOrMobile ? 241 : 138}
            label={t(
              'components.MyDashboard.JobApplicationInfo.ContactUs.message'
            )}
            info={t('components.MyDashboard.JobApplicationInfo.ContactUs.info')}
            placeholder={t(
              'components.MyDashboard.JobApplicationInfo.ContactUs.placeholder'
            )}
            name="message"
            value={message}
            setValue={setMessage}
          />
        </div>
        <p className={classes.tip}>
          {t('components.MyDashboard.JobApplicationInfo.ContactUs.tip')}
        </p>
      </div>
      <div className={classes.buttons}>
        <BlackButton
          width={300}
          onClick={() => {
            setMessage('');
          }}
        >
          {t('common.clear')}
        </BlackButton>
        <Button
          width={300}
          jobApplicationInfo
          onClick={sendMessage}
          disabled={!message}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
