/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DropdownSelector from '../DropdownSelector';
import Job from './Job';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import experienceIcon from '../../../../assets/images/experience.svg';
import classes from './styles.module.scss';

export default function WorkExperienceDropdown({
  hasWorkExperience,
  setHasWorkExperience,
  jobs,
  setJobs,
  isFresher,
  setIsFresher,
  hasTriedToSubmit,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [prevJobs, setPrevJobs] = useState([]);

  const optionsRef = useRef();

  useOnClickOutside(optionsRef, () => setIsOptionsListVisible(false));

  const { t } = useTranslation();

  const changeJob = (id, field, value) => {
    setJobs((prevState) => {
      return prevState.map((job) => {
        if (job.id !== id) {
          return job;
        }
        return { ...job, [field]: value };
      });
    });
  };

  const addJob = () => {
    setJobs((prevState) => [
      ...prevState,
      {
        id: Math.random(),
        jobTitle: '',
        companyName: '',
        startDate: null,
        endDate: null,
        isCurrent: false,
      },
    ]);
  };

  const deleteJob = (id) => {
    setJobs((prevState) => prevState.filter((job) => job.id !== id));
  };

  const resetJobs = () => {
    setPrevJobs(jobs);
    setJobs([
      {
        id: Math.random(),
        jobTitle: '',
        companyName: '',
        startDate: null,
        endDate: null,
        isCurrent: false,
      },
    ]);
  };

  useEffect(() => {
    if (!jobs?.length) {
      setHasWorkExperience(false);
      setIsFresher(true);
    }
  }, [jobs, setHasWorkExperience, setIsFresher]);

  const currentOption = (
    <div className={classNames(classes.no, classes.active, classes.option)}>
      {hasWorkExperience ? t('common.yes') : t('common.no')}
    </div>
  );

  return (
    <DropdownSelector
      icon={experienceIcon}
      title={t(
        'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.workExperienceTitle'
      )}
      isOptionsListVisible={isOptionsListVisible}
      toggleOptions={() => setIsOptionsListVisible((prevState) => !prevState)}
      currentOption={currentOption}
      value={hasWorkExperience || isFresher}
    >
      <div className={classes.options} ref={optionsRef}>
        <header
          className={classes.header}
          onClick={() => setIsOptionsListVisible(false)}
        >
          <span className={classes.title}>
            {t(
              'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.workExperienceTitle'
            )}
          </span>
        </header>
        <div className={classes.question}>
          <span>
            {t(
              'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.haveWorkedBeforeLabel'
            )}
          </span>
        </div>
        <ul>
          <li
            onClick={() => {
              setHasWorkExperience(false);
              setIsOptionsListVisible(false);
              setIsFresher(true);
              resetJobs();
            }}
            className={classNames(classes.no, classes.option, {
              [classes.active]: hasWorkExperience === false && isFresher,
            })}
          >
            {t('common.no')}
          </li>
          <li
            onClick={() => {
              setHasWorkExperience(true);
              setIsFresher(false);
              if (prevJobs.length) {
                setJobs(prevJobs);
              } else if (!jobs?.length) {
                addJob();
              }
            }}
            className={classNames(classes.yes, classes.option, {
              [classes.active]: hasWorkExperience,
            })}
          >
            <span>{t('common.yes')}</span>
            {hasWorkExperience &&
              jobs.map((job) => {
                return (
                  <Job
                    startDate={job.startDate}
                    endDate={job.endDate}
                    key={job.id}
                    job={job}
                    changeJob={changeJob}
                    deleteJob={deleteJob}
                    hasTriedToSubmit={hasTriedToSubmit}
                  />
                );
              })}
            {hasWorkExperience && (
              <div className={classes.addExperience}>
                <div
                  className={classes.inner}
                  onClick={(event) => {
                    event.stopPropagation();
                    addJob();
                  }}
                >
                  {t(
                    'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.addAnotherExperienceLabel'
                  )}
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </DropdownSelector>
  );
}
