/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

import face1 from '../../../assets/images/landing/faces/1.png';
import face2 from '../../../assets/images/landing/faces/2.png';
import face3 from '../../../assets/images/landing/faces/3.png';
import face4 from '../../../assets/images/landing/faces/4.png';
import face5 from '../../../assets/images/landing/faces/5.png';
import face6 from '../../../assets/images/landing/faces/6.png';
import face7 from '../../../assets/images/landing/faces/7.png';
import face8 from '../../../assets/images/landing/faces/8.png';
import face9 from '../../../assets/images/landing/faces/9.png';
// import sanders from '../../../assets/images/landing/sanders.svg';

const faces = [face1, face2, face3, face4, face5, face6, face7, face8, face9];

let timer;

// Function to get the computed background image URL of an element
function getBackgroundImageUrl(element) {
  const style = window.getComputedStyle(element);
  const url = style.backgroundImage.slice(4, -1).replace(/['"]/g, '');
  return url;
}

// Function to get the container's dimensions
function getContainerSize(element) {
  const rect = element.getBoundingClientRect();
  return { width: rect.width, height: rect.height };
}

// Function to determine the exact size of the resized background image
function getResizedBackgroundImageSize(element) {
  return new Promise((resolve, reject) => {
    const url = getBackgroundImageUrl(element);

    if (!url) {
      reject('No background image found');
      return;
    }

    const img = new Image();
    img.onload = function () {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const imgAspectRatio = imgWidth / imgHeight;

      const containerSize = getContainerSize(element);
      const containerWidth = containerSize.width;
      const containerHeight = containerSize.height;
      const containerAspectRatio = containerWidth / containerHeight;

      let finalWidth;
      let finalHeight;

      if (imgAspectRatio > containerAspectRatio) {
        // Image is wider relative to the container
        finalWidth = containerWidth;
        finalHeight = containerWidth / imgAspectRatio;
      } else {
        // Image is taller relative to the container
        finalWidth = containerHeight * imgAspectRatio;
        finalHeight = containerHeight;
      }

      resolve({ width: finalWidth, height: finalHeight });
    };
    img.onerror = function () {
      reject('Error loading image');
    };
    img.src = url;
  });
}

export default function Faces() {
  const [currentFaceIndex, setCurrentFaceIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  /*  const [backgroundImageSize, setBackgroundImageSize] = useState({
    width: 0,
    height: 0,
  }); */

  const backgroundRef = useRef();

  const setBackgroundImageSize = () => {
    getResizedBackgroundImageSize(backgroundRef.current)
      .then((size) => {
        backgroundRef.current.style.setProperty(
          '--background-image-width',
          `${size.width}px`
        );
        backgroundRef.current.style.setProperty(
          '--background-image-height',
          `${size.height}px`
        );

        // setBackgroundImageSize(size);
      })
      .catch((error) => {
        console.error('ERRROR', error);
      });
  };

  // Switch faces every second
  useEffect(() => {
    if (!isAnimating) {
      clearInterval(timer);
      return;
    }

    timer = setInterval(() => {
      setCurrentFaceIndex((prevIndex) => {
        if (prevIndex === faces.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });

      return () => clearInterval(timer);
    }, 1000);
  }, [isAnimating]);

  // Start animation after 3 seconds
  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(true);
      setBackgroundImageSize();
    }, 2500);
  }, []);

  // Set the background image size on resize
  useEffect(() => {
    window.addEventListener('resize', setBackgroundImageSize);

    return () => {
      window.removeEventListener('resize', setBackgroundImageSize);
    };
  }, []);

  // console.log(backgroundImageSize);

  return (
    <div
      className={classNames(classes.Faces, {
        [classes.animating]: isAnimating,
      })}
      ref={backgroundRef}
    >
      <div className={classes.faceContainer}>
        <div className={classes.face}>
          {faces.map((face, index) => (
            <img
              key={face}
              src={face}
              alt="face"
              className={classNames(classes.faceImage, {
                [classes.active]: currentFaceIndex === index,
              })}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
