import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import Dropdown from './Dropdown';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function CountryCitySelector({
  close,
  countryCitySelectorContainerRef,
  cityOptions,
  setCity,
  city,
  setAppliedCity,
  style,
}) {
  const { t } = useTranslation();
  const [country, setCountry] = useState({
    label: t('common.Turkey'),
    value: 'whatever',
  });

  const countryCitySelectorRef = useRef();

  useOnClickOutside(
    countryCitySelectorRef,
    close,
    countryCitySelectorContainerRef
  );

  return (
    <div
      className={classes.CountryCitySelector}
      ref={countryCitySelectorRef}
      style={{ ...style }}
    >
      <h1>{t('components.JobsSearch.CountryCitySelector.locationHeading')}</h1>
      <div className={classes.dropdowns}>
        <div className={classes.dropdownContainer}>
          <h2>
            {t(
              'components.JobsSearch.CountryCitySelector.countryRegionHeading'
            )}
          </h2>
          <Dropdown
            options={[{ label: t('common.Turkey'), value: 'whatever' }]}
            value={country}
            setValue={setCountry}
          />
        </div>
        <div className={classes.dropdownContainer}>
          <h2>{t('components.JobsSearch.CountryCitySelector.cityHeading')}</h2>
          <Dropdown
            options={country ? cityOptions : []}
            value={city}
            setValue={setCity}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          disabled={!city}
          onClick={() => {
            setAppliedCity(city);
            close();
          }}
          width={96}
          height={35}
          style={{
            borderRadius: 7,
            fontSize: 18,
            lineHeight: '20px',
            paddingLeft: 30,
          }}
        >
          {t('common.apply')}
        </Button>
        <button
          className={classes.clearButton}
          type="button"
          onClick={() => {
            setCity('');
            setCountry('');
          }}
        >
          {t('common.clear')}
        </button>
      </div>
    </div>
  );
}
