import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';

import useUiContext from '../../../../hooks/useUiContext';
import classes from './styles.module.scss';
import isInIframe from '../../../../helpers/isInIframe';

export default function Links({
  myNotificationsButtonRef,
  setIsNotificationsPopupVisible,
  onLinkClick,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { currentLandingSection, isAuthenticated } = useUiContext();

  const { t } = useTranslation();

  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const isIframe = isInIframe();

  const unathorizedLinks = (
    <>
      <li className={classes.listItem}>
        <NavLink
          to="/#word-about-kfc"
          className={
            currentLandingSection === 'word-about-kfc' && isLandingPage
              ? classes.active
              : null
          }
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            {t('components.Header.navItems.1')}
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          to="/#future-at-kfc"
          className={
            currentLandingSection === 'future-at-kfc' && isLandingPage
              ? classes.active
              : null
          }
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            {t('components.Header.navItems.2')}
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          to="/#who-we-are"
          className={
            currentLandingSection === 'who-we-are' && isLandingPage
              ? classes.active
              : null
          }
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            {t('components.Header.navItems.0')}
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          to="/#giving-back"
          className={
            currentLandingSection === 'giving-back' && isLandingPage
              ? classes.active
              : null
          }
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            {t('components.Header.navItems.3')}
          </span>
        </NavLink>
      </li>
    </>
  );

  const authorizedLinks = (
    <>
      <li className={classes.listItem}>
        <NavLink
          to="/jobs"
          className={({ isActive }) => (isActive ? classes.active : null)}
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            <span>{t('components.Header.jobs')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          to="/my-dashboard"
          className={({ isActive }) => (isActive ? classes.active : null)}
          onClick={onLinkClick}
        >
          <span className={classes.navLink}>
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.dashboard')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          onClick={onLinkClick}
          to="/favorites"
          className={({ isActive }) => (isActive ? classes.active : null)}
        >
          <span className={classes.navLink}>
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.favorites')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          ref={myNotificationsButtonRef}
          to="/notifications"
          className={({ isActive }) => (isActive ? classes.active : null)}
          onClick={(event) => {
            if (!isTabletOrMobile) {
              event.preventDefault();
            }

            onLinkClick?.();
          }}
        >
          <span
            className={classes.navLink}
            onClick={() => {
              if (!isTabletOrMobile) {
                setIsNotificationsPopupVisible((prevState) => !prevState);
              }
            }}
          >
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.notifications')}</span>
          </span>
        </NavLink>
      </li>
    </>
  );

  return isAuthenticated && !isIframe ? authorizedLinks : unathorizedLinks;
}
