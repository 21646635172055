import React, { useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import useScreenOrientation from '../../../hooks/useScreenOrientation';
import useUiContext from '../../../hooks/useUiContext';

import classes from './styles.module.scss';

const pauseVideoPreview = () => {
  const videoPlayer = document.getElementById('preview');

  if (videoPlayer && !videoPlayer.paused) {
    videoPlayer.pause();
  }
};

export default function BlockRecordingOnLandscape({ pauseVideoRecording }) {
  const [isBlocked, setIsBlocked] = useState(false);

  const { showModal, hideModal } = useUiContext();

  const { t } = useTranslation();

  const orientation = useScreenOrientation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (isTabletOrMobile && orientation === 'landscape-primary') {
      setIsBlocked(true);
      try {
        showModal({
          title: t(
            'components.RecordVideo.BlockRecordingOnLandscape.modalTitle'
          ),
          text: (
            <>
              {t(
                'components.RecordVideo.BlockRecordingOnLandscape.modalText.0'
              )}
              <br />{' '}
              <span
                style={{
                  color: '#d52d34',
                }}
              >
                {t(
                  'components.RecordVideo.BlockRecordingOnLandscape.modalText.1'
                )}
              </span>{' '}
              <br />{' '}
              {t(
                'components.RecordVideo.BlockRecordingOnLandscape.modalText.2'
              )}
              .
            </>
          ),
        });
        pauseVideoPreview();
        pauseVideoRecording();
      } catch (error) {
        //
      }
    } else {
      setIsBlocked(false);
      hideModal();
    }
  }, [
    hideModal,
    isTabletOrMobile,
    orientation,
    pauseVideoRecording,
    showModal,
  ]);

  // Increase z-index of the alert modal so it displayed over the blur
  useEffect(() => {
    if (isBlocked) {
      const alerModal = document.querySelector('.alert-modal');
      if (alerModal) {
        alerModal.style.zIndex = 9999;
      }
    }

    return () => {
      const alerModal = document.querySelector('.alert-modal');
      if (alerModal) {
        alerModal.style.zIndex = 1050;
      }
    };
  }, [isBlocked]);

  if (isBlocked) {
    return <div className={classes.blur} />;
  }

  return null;
}
