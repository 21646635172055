import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';

import successImage from '../../../../assets/images/success.svg';
import classes from './styles.module.scss';

export default function VideoUploadSuccess({ onClose }) {
  const { t } = useTranslation();
  return (
    <div className={classes.VideoUploadSuccess}>
      <img src={successImage} alt="Awesome" />
      <h1>
        {t('components.RecordVideo.MobileScreens.VideoUploadSuccess.awesome')}
      </h1>
      <p className={classes.details}>
        {t(
          'components.RecordVideo.MobileScreens.VideoUploadSuccess.uploadSuccessDetails'
        )}
      </p>
      <p className={classes.info}>
        {t(
          'components.RecordVideo.MobileScreens.VideoUploadSuccess.infoMessage'
        )}
      </p>
      <div className={classes.buttonContainer}>
        <Button style={{ fontSize: 22 }} onClick={onClose}>
          {t(
            'components.RecordVideo.MobileScreens.VideoUploadSuccess.continue'
          )}
        </Button>
      </div>
    </div>
  );
}
