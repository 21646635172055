import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';

import classNames from 'classnames';
import { format } from 'timeago.js';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import JobApplicationInfo from '../../components/MyDashboard/JobApplicationInfo';
import DocModal from '../../modals/DocModal';
import MapModal from '../../modals/MapModal';
import NavHeader from '../../components/NavHeader';
import VideoPlayer from '../../components/VideoPlayer';
import SignInModal from '../../modals/SignInModal';
import Button from '../../components/Button';
import ShareIcons from '../../components/ShareIcons';
import QrCodeModal from '../../modals/QrCodeModal';

import getResizedImageUrl from '../../helpers/getResizedImageUrl';
import useLogoutBlockedUser from '../../hooks/useLogoutBlockedUser';
import isInIframe from '../../helpers/isInIframe';
import { JOBPOST_IMG_URL } from '../../constants/main';
import PublicService from '../../services/PublicService';
import { hasAuthData, getAuthData } from '../../helpers/authStorage';
import { UiContext } from '../../context/UiContext';
import AnonymousService from '../../services/AnonymousService';
import bookmarkIcon from '../../assets/bookmark-icon.svg';
import bookmarkIconActive from '../../assets/bookmark-icon-active.svg';
import locationIcon from '../../assets/location-icon.svg';
import clipIcon from '../../assets/clip-icon.svg';
import arrowLeftIcon from '../../assets/arrowleft-icon.svg';
import classes from './styles.module.scss';
import sessionId from '../../helpers/sessionId';
import useResizeObserver from '../../hooks/useResizeObserver';
import defaultCover from '../../assets/images/jobs/cover.jpg';

export default function JobPage() {
  const [show, setShow] = useState(false);
  const [isDocModalVisible, setIsDocModalVisible] = useState(false);
  const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const [isHiddenButtonVisible, setIsHiddenButtonVisible] = useState(false);
  const [isJobApplicationInfoVisible, setIsJobApplicationInfoVisible] =
    useState(false);
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);
  const [qrCodeModalUrl, setQrCodeModalUrl] = useState('');

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isUserProfileFilled,
  } = useContext(UiContext);
  const isLoggedIn = hasAuthData();

  const hiddenButtonRef = useRef();
  const visibleButtonRef = useRef();
  const jobPageRef = useRef();

  const { height } = useResizeObserver({ current: document.body });

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigate = useNavigate();
  const { jobId } = useParams();

  const isIframe = isInIframe();

  const {
    data: job,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => AnonymousService.getJobById({ jobId }),
  });

  const { data: jobApplications, refetch: refetchJobApplication } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: PublicService.getJobApplications,
    keepPreviousData: true,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (job && isIframe) {
      window.parent.postMessage(
        {
          type: 'resizeIframe',
          height,
        },
        '*'
      );
    }
  }, [isIframe, job, height]);

  const filteredJobApplications = jobApplications
    ?.filter((jobApp) => {
      return jobApp.jobPostId === +jobId;
    })
    ?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const jobApplication = filteredJobApplications?.[0];

  const isJobApplicationDraft =
    jobApplication?.status === 'Draft' &&
    jobApplication?.subStatus === 'Pending';
  const hasWithdrawn = jobApplication?.subStatus === 'Withdrawn';

  const { userId } = getAuthData();

  const isFavorited = job?.jobPostFavorites?.some((fav) => {
    return fav.userId === +userId;
  });

  const handleCloseModal = () => {
    setShow(!show);
  };

  const showDocModal = () => {
    setIsDocModalVisible(true);
  };

  const { checkAndLogout } = useLogoutBlockedUser();

  const hasBeenInvited = jobApplication?.status === 'Invited';

  const applyForJob = useCallback(
    async (disableAuthCheck) => {
      console.log('applyForJob');
      if (isIframe) {
        return;
      }

      try {
        // Check if user is logged in
        if (!isLoggedIn && !disableAuthCheck) {
          setIsSignInModalVisible(true);
          return;
        }

        // If user is blocked, logout
        await checkAndLogout();

        if (
          job?.applicationType === 'VideoOnly' ||
          job?.applicationType === 'VideoAndDocument'
        ) {
          if (isUserProfileFilled === false && !disableAuthCheck) {
            setIsProfileModalVisible(true);
            return;
          }

          const url = hasBeenInvited
            ? `/jobs/${jobId}/apply/video?jobApplicationId=${jobApplication?.id}`
            : `/jobs/${jobId}/apply/video`;

          if (isTabletOrMobile) {
            console.log('CALLING NAVIGATE');
            navigate(url);
          } else {
            setIsQrCodeModalVisible(true);
            console.log(window.location.hostname + url);
            setQrCodeModalUrl(window.location.origin + url);
          }
        } else if (job?.applicationType === 'DocumentOnly') {
          if (isUserProfileFilled === false && !disableAuthCheck) {
            setIsProfileModalVisible(true);
            return;
          }

          const url = hasBeenInvited
            ? `/jobs/${jobId}/apply/info?jobApplicationId=${jobApplication?.id}`
            : `/jobs/${jobId}/apply/info`;

          console.log('CALLING NAVIGATE');
          navigate(url);
        } else {
          const url = hasBeenInvited
            ? `/jobs/${jobId}/apply?jobApplicationId=${jobApplication?.id}`
            : `/jobs/${jobId}/apply`;

          console.log('CALLING NAVIGATE');
          navigate(url);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      checkAndLogout,
      hasBeenInvited,
      isIframe,
      isLoggedIn,
      isTabletOrMobile,
      isUserProfileFilled,
      job?.applicationType,
      jobApplication?.id,
      jobId,
      navigate,
    ]
  );

  const applyForJobWithoutAuthCheck = useCallback(() => {
    applyForJob(true);
  }, [applyForJob]);

  const closeSigninModal = useCallback(() => {
    setIsSignInModalVisible(false);
  }, []);

  const withdrawJobApplication = async () => {
    showModal({
      title: t('pages.JobPage.withdrawApplication'),
      text: t('pages.JobPage.progressWillBeLostDoYouStillWantToContinue'),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await PublicService.withdrawJobApplication({
            jobApplicationId: jobApplication.id,
          });
          await refetchJobApplication();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
    });
  };

  const toggleFavorite = async () => {
    try {
      if (!isLoggedIn) {
        setIsSignInModalVisible(true);
        return;
      }

      setIsFetching(true);
      if (isFavorited) {
        await PublicService.removeJobFromFavorites({ jobId: job.id });
      } else {
        await PublicService.addJobToFavorites({ jobId: job.id });
      }
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsPageScrolled(true);
      } else {
        setIsPageScrolled(false);
      }

      const hiddenButtonCoords =
        hiddenButtonRef.current?.getBoundingClientRect();
      const visibleButtonCoords =
        visibleButtonRef.current?.getBoundingClientRect();

      if (hiddenButtonCoords?.y - visibleButtonCoords?.y < 0) {
        setIsHiddenButtonVisible(true);
      } else {
        setIsHiddenButtonVisible(false);
      }
    });
  }, []);

  const addViewToJob = useCallback(async () => {
    try {
      await PublicService.addViewToJobPost({ jobId, ZapSessionId: sessionId });
    } catch (error) {
      console.log(error);
    }
  }, [jobId]);

  useEffect(() => {
    const viewedJobs = JSON.parse(localStorage.getItem('viewedJobs')) || [];

    if (viewedJobs?.includes(jobId)) {
      return;
    }

    addViewToJob();

    viewedJobs?.push(jobId);

    localStorage.setItem('viewedJobs', JSON.stringify(viewedJobs));
  }, [addViewToJob, jobId]);

  const isInvited =
    jobApplication?.status === 'Invited' &&
    jobApplication?.subStatus === 'Pending';

  const isRejected = jobApplication?.subStatus === 'Rejected';

  const hasApplied =
    jobApplication && !isInvited && !hasWithdrawn && !isJobApplicationDraft;

  let jobButtonLabel = hasApplied
    ? t('pages.JobPage.withdraw')
    : t('common.apply');

  if (isRejected) {
    jobButtonLabel = t('components.Jobs.Job.labels.Not Selected');
  }

  if (hasBeenInvited) {
    jobButtonLabel = t('pages.JobPage.Invited');
  }

  return (
    <div
      ref={jobPageRef}
      className={classNames(classes.JobPage, {
        [classes.scrolled]: isPageScrolled && !isIframe,
        [classes.iframe]: isIframe,
        [classes.noMedia]: !job?.jobPostVideo && !job?.coverFileName,
        [classes.fetching]:
          (!filteredJobApplications || isFetching) && isLoggedIn,
      })}
    >
      {!isTabletOrMobile && (
        <div className={classes.header}>
          <NavHeader top={25} />
        </div>
      )}
      {isFetching && !job && <Loader />}
      <div className={classes.container}>
        {isTabletOrMobile && (
          <div className={classes['JobPage__title-container']}>
            <div className={classes['JobPage__title-container-mobile']}>
              <button
                type="button"
                className={classes.JobPage__bookmarkBtn}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  width={20}
                  height={20}
                  className={classes.icon}
                  src={arrowLeftIcon}
                  alt="Icon"
                />
              </button>
              <h1 className={classes.JobPage__title}>{job?.jobTitle}</h1>
            </div>
            <button
              onClick={toggleFavorite}
              type="button"
              className={classes.JobPage__bookmarkBtn}
            >
              <img
                width={20}
                height={20}
                className={classes.icon}
                src={isFavorited ? bookmarkIconActive : bookmarkIcon}
                alt="Icon"
              />
            </button>
          </div>
        )}
        <div className={classes.coverContainer}>
          {job?.jobPostVideo ? (
            <VideoPlayer
              url={job.jobPostVideo.url}
              centerButton
              jobPage
              width={isIframe ? 343 : ''}
              height={isIframe ? 608 : ''}
            />
          ) : (
            <div
              className={classes.jobPostCoverImageContainer}
              style={job?.coverFileName ? {} : { display: 'none' }}
            >
              <img
                className={classes.jobPostCoverImage}
                // src={`${JOBPOST_IMG_URL}/${job?.coverFileName}`}
                src={
                  getResizedImageUrl({
                    url: job?.coverFileName,
                    imagesSource: JOBPOST_IMG_URL,
                    width: 800,
                  }) || defaultCover
                }
                alt="Cover"
              />
            </div>
          )}
        </div>
        {isTabletOrMobile && (
          <div className={classes['JobPage__apply-mobile']}>
            <Button
              disabled={isRejected}
              reference={visibleButtonRef}
              onClick={
                hasApplied ? withdrawJobApplication : () => applyForJob()
              }
              width="calc(100vw - 38px)"
              height={50}
              style={{
                visibility: isHiddenButtonVisible ? 'hidden' : 'visible',
                zIndex: 1,
                borderRadius: 16,
                fontSize: 24,
                lineHeight: '1.25',
                paddingLeft: 30,
                // width: '10px',
                position: isIframe ? '' : 'fixed',
                right: 19,
                left: 19,
              }}
              isBlackButton={!!hasApplied}
            >
              {jobButtonLabel}
            </Button>
            <p
              style={{
                display:
                  jobApplication && !isInvited && !isJobApplicationDraft
                    ? ''
                    : 'none',
              }}
              className={classes.jobApplicationStatus}
            >
              {hasWithdrawn ? (
                <>
                  <span>
                    {t('pages.JobPage.withdrawn')}{' '}
                    {format(jobApplication?.statusChangedAt)}.
                  </span>{' '}
                </>
              ) : (
                <>
                  <span>Applied {format(jobApplication?.createdAt)}.</span>{' '}
                  <button
                    onClick={() => setIsJobApplicationInfoVisible(true)}
                    type="button"
                    // className={classes['JobPage__check-status-btn']}
                    className={classes.checkStatusButton}
                  >
                    {t('pages.JobPage.checkStatus')}
                  </button>
                </>
              )}
            </p>
          </div>
        )}
        <div className={classes.JobPage__content}>
          {!isTabletOrMobile && (
            <div className={classes['JobPage__title-container']}>
              <h1 className={classes.JobPage__title}>{job?.jobTitle}</h1>
              <div className={classes.applyButtonContainer}>
                <button
                  onClick={toggleFavorite}
                  type="button"
                  className={classes.JobPage__bookmarkBtn}
                >
                  <img
                    width={20}
                    height={20}
                    className={classes.icon}
                    src={isFavorited ? bookmarkIconActive : bookmarkIcon}
                    alt="Icon"
                  />
                </button>
                <Button
                  onClick={
                    hasApplied ? withdrawJobApplication : () => applyForJob()
                  }
                  width={200}
                  height={50}
                  style={{
                    borderRadius: 16,
                    fontSize: 24,
                    lineHeight: '1.25',
                    paddingLeft: 30,
                  }}
                  isBlackButton={!!hasApplied}
                  disabled={isRejected}
                >
                  {jobButtonLabel}
                </Button>
                <p
                  style={{
                    display:
                      jobApplication && !isInvited && !isJobApplicationDraft
                        ? ''
                        : 'none',
                    width: 200,
                    textAlign: 'center',
                  }}
                  className={classes.jobApplicationStatus}
                >
                  {hasWithdrawn ? (
                    <>
                      <span>{t('pages.JobPage.withdrawn')}</span>{' '}
                      {format(jobApplication?.statusChangedAt)}.
                    </>
                  ) : (
                    <>
                      <span>Applied</span> {format(jobApplication?.createdAt)}.
                      <button
                        onClick={() => setIsJobApplicationInfoVisible(true)}
                        type="button"
                        className={classes.checkStatusButton}
                      >
                        {t('pages.JobPage.checkStatus')}
                      </button>
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
          {job?.jobPostTags?.length || !isIframe ? (
            <div
              className={classNames(classes.JobPage__badges, classes.badges)}
            >
              {job?.jobPostTags.map((tag) => {
                return (
                  <div className={classes.badge} key={tag.id} height={36}>
                    {tag.name}
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className={classes.jobConditions}>
            <div className={classes.col}>
              <h2 className={classes.title}>{t('pages.JobPage.jobType')}</h2>
              <div className={classes.value}>
                <span>{job?.jobTypes.map((jT) => jT.name)?.join(', ')}</span>
              </div>
            </div>
            {job?.corporateTitle && (
              <div className={classes.col}>
                <h2 className={classes.title}>{t('pages.JobPage.position')}</h2>
                <div className={classes.value}>
                  <span>{job?.corporateTitle || '--N/A--'}</span>
                </div>
              </div>
            )}
          </div>
          <div className={classes['JobPage__location-wrap']}>
            <p className={classes.JobPage__location}>
              <img
                width={30}
                height={30}
                style={{ marginRight: '12px' }}
                className={classes.icon}
                src={locationIcon}
                alt="Icon"
              />
              <span>
                {`${
                  job?.locationCity?.country
                    ? `${job?.locationCity?.country}, `
                    : ''
                }${job?.locationCity?.name}`}
              </span>
            </p>
            <button
              type="button"
              className={classes['JobPage__property-btn']}
              onClick={() => setShow(true)}
            >
              {t('pages.JobPage.showOnMap')}
            </button>
          </div>
          <div className={classes['JobPage__skills-wrap']}>
            <h2
              className={classes['JobPage__property-title']}
              style={{ marginBottom: '10px' }}
            >
              {t('pages.JobPage.skills')}
            </h2>
            <ul className={classes['JobPage__skills-list']}>
              {job?.skills.map((el) => {
                return (
                  <li
                    key={el.id + el.name}
                    className={classes['JobPage__skills-item']}
                  >
                    <p className={classes.JobPage__property}>{el.name}</p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classes.jobRequirements}>
            <div className={classes.experience}>
              <h2 className={classes.title}>{t('pages.JobPage.experience')}</h2>
              <div className={classes.value}>
                <span>{job?.workExperience?.name || '--N/A--'}</span>
              </div>
            </div>
            <div className={classes.education}>
              <h2 className={classes.title}>{t('pages.JobPage.education')}</h2>
              <div className={classes.value}>
                <span>{job?.graduation?.name || '--N/A--'}</span>
              </div>
            </div>
          </div>
          <div className={classes['JobPage__description-wrap']}>
            <div className={classes['JobPage__description-title-wrap']}>
              <h2
                className={classes['JobPage__property-title']}
                style={{ marginBottom: '13px' }}
              >
                {t('pages.JobPage.description')}
              </h2>
              {!isTabletOrMobile &&
              job?.jobPostAttachments.length &&
              !isIframe ? (
                <button
                  type="button"
                  className={classes['JobPage__description-add-info']}
                  onClick={showDocModal}
                >
                  <img
                    width={isTabletOrMobile ? 20 : 30}
                    height={isTabletOrMobile ? 20 : 30}
                    style={{ marginRight: '12px' }}
                    className={classes.icon}
                    src={clipIcon}
                    alt="Icon"
                  />
                  <span>{t('pages.JobPage.additionalInfo')}</span>
                </button>
              ) : null}
            </div>
            <p className={classes.JobPage__description}>
              {job?.jobDescription}
            </p>
            {isTabletOrMobile && job?.jobPostAttachments.length ? (
              <button
                type="button"
                className={classes['JobPage__description-add-info']}
                onClick={showDocModal}
              >
                <img
                  width={isTabletOrMobile ? 20 : 30}
                  height={isTabletOrMobile ? 20 : 30}
                  style={{ marginRight: '12px' }}
                  className={classes.icon}
                  src={clipIcon}
                  alt="Icon"
                />
                <span>{t('pages.JobPage.additionalInfo')}</span>
              </button>
            ) : null}
          </div>
          <div className={classes['JobPage__share-wrap']}>
            {isTabletOrMobile && (
              <h2 className={classes['JobPage__property-title']}>Share this</h2>
            )}
            <div className={classes['JobPage__share-mobile']}>
              {!isTabletOrMobile && (
                <h2 className={classes['JobPage__property-title']}>
                  {t('pages.JobPage.shareThis')}
                </h2>
              )}
              <ShareIcons classes={classes} jobTitle={job?.jobTitle} />
              <div className={classes['JobPage__active-wrap']}>
                <p className={classes.jobCreatedAt}>
                  Opened <br /> {format(job?.createdAt)}
                </p>
                <div
                  className={classNames(classes.jobStatus, {
                    [classes.onHold]: job?.status === 'OnHold',
                    [classes.closed]: job?.status === 'Closed',
                  })}
                >
                  {/* {job?.status.replace(/([a-z])([A-Z])/g, '$1 $2')} */}
                  {t(`pages.JobPage.${job?.status}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  {!isTabletOrMobile && <div>pagination</div>} */}
      {job?.locations && (
        <MapModal
          show={show}
          handleClose={handleCloseModal}
          locations={job?.locations}
        />
      )}
      <DocModal
        doc={job?.jobPostAttachments[0]?.url}
        jobTitle={job?.jobPostAttachments[0]?.displayName}
        show={isDocModalVisible}
        handleClose={() => setIsDocModalVisible(false)}
      />
      <SignInModal
        show={isSignInModalVisible}
        handleClose={closeSigninModal}
        onLogin={applyForJobWithoutAuthCheck}
      />
      <SignInModal
        profile
        show={isProfileModalVisible}
        handleClose={() => setIsProfileModalVisible(false)}
      />
      {isTabletOrMobile && !isIframe && (
        <Button
          reference={hiddenButtonRef}
          onClick={hasApplied ? withdrawJobApplication : () => applyForJob()}
          height={50}
          style={{
            visibility: isHiddenButtonVisible ? 'visible' : 'hidden',
            zIndex: 1,
            marginTop: 40,
            borderRadius: 16,
            fontSize: 24,
            lineHeight: '1.25',
            paddingLeft: 30,
          }}
          isBlackButton={!!hasApplied}
          disabled={isRejected}
        >
          {jobButtonLabel}
        </Button>
      )}
      <JobApplicationInfo
        currentJobApplication={jobApplication}
        isVisible={isJobApplicationInfoVisible}
        hideJobsApplicationInfo={() => setIsJobApplicationInfoVisible(false)}
        refetch={refetchJobApplication}
      />
      <QrCodeModal
        show={isQrCodeModalVisible}
        handleClose={() => setIsQrCodeModalVisible(false)}
        url={qrCodeModalUrl}
        onSkip={() => {
          const url = qrCodeModalUrl?.split('/jobs')?.[1];
          navigate(`/jobs${url}`);
        }}
      />
    </div>
  );
}
