import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

const iconClasses = {
  Date: classes.date,
  Time: classes.time,
  Type: classes.type,
  Contact: classes.contact,
  'Meeting Link': classes.link,
  'Meeting Location': classes.location,
};

export default function InfoField({ title, value }) {
  return (
    <div className={classNames(classes.InfoField, iconClasses[title])}>
      <h4>{title}</h4>
      <span className={classes.value}>{value}</span>
    </div>
  );
}
