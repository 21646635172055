import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Video from './Video';
import ApplyButton from './ApplyButton';

import classes from './styles.module.scss';
import video1Preview from '../../../assets/images/landing/video-3-placeholder.jpg';
import video2Preview from '../../../assets/images/landing/video-4-placeholder.jpg';
import video3Preview from '../../../assets/images/landing/video-5-placeholder.jpg';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';

export default function Videos() {
  const containerRef = useRef();

  const { t } = useTranslation();

  const videos = [
    {
      title: t('pages.LandingPage.Videos.Ebru Turkan'),
      subTitle: t('pages.LandingPage.Videos.Career story in KFC'),
      src: 'https://stzaphireplatformkfc.blob.core.windows.net/static/vid1-enc.mp4',
      imageSrc: video1Preview,
    },
    {
      title: t('pages.LandingPage.Videos.RGMs'),
      subTitle: t('pages.LandingPage.Videos.Career story in KFC'),
      src: 'https://stzaphireplatformkfc.blob.core.windows.net/static/vid2-enc.mp4',
      imageSrc: video2Preview,
    },
    {
      isApplyButton: true,
    },
    {
      title: t('pages.LandingPage.Videos.Team members'),
      subTitle: t('pages.LandingPage.Videos.Career story in KFC'),
      src: 'https://stzaphireplatformkfc.blob.core.windows.net/static/vid4-enc.mp4',
      imageSrc: video3Preview,
    },
  ];

  const setNode = useHeaderLinkHighlighting('future-at-kfc');

  return (
    <div className={classes.Videos} ref={setNode}>
      <div className={classes.container} ref={containerRef}>
        {videos.map((video) => {
          return video.isApplyButton ? (
            <ApplyButton />
          ) : (
            <Video key={video.title} video={video} />
          );
        })}
      </div>
    </div>
  );
}
