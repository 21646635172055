import React, { useState, useContext, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import EmailForm from '../../components/SignIn/EmailForm';
import OtpVerificationForm from '../../components/SignIn/OtpVerificationForm';

import { saveAuthData } from '../../helpers/authStorage';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';

export default function SignInPage() {
  const [currentForm, setCurrentForm] = useState('email');
  const [otp, setOtp] = useState('');
  const [hasEmailFormTriedToSubmit, setHasEmailFormTriedToSubmit] =
    useState(false);

  const { showUnknownErrorModal, showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const emailValidationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .trim()
          .email(t('pages.SignInPage.emailFormat'))
          .required(t('common.requiredField')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      try {
        await UserService.requestOtp({ email: values.email });
        setCurrentForm('otp');
        showModal({
          title: t('pages.SignInPage.codeSentTitle'),
          text: t('pages.SignInPage.codeSentMessage'),
        });
      } catch (error) {
        console.log(error);
        if (error.response.data.message === 'Deactivated') {
          showModal({
            title: t('pages.SignInPage.inactiveTitle'),
            text: t('pages.SignInPage.inactiveMessage'),
          });
        } else {
          showUnknownErrorModal();
        }
      }
    },
  });

  const resendOtp = async () => {
    try {
      setOtp('');
      await UserService.requestOtp({ email: formik.values.email });

      showModal({
        title: t('pages.SignInPage.codeSentTitle'),
        text: t('pages.SignInPage.newCodeSentMessage'),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    }
  };

  const submitOtpPassword = async () => {
    try {
      if (otp?.length < 4) {
        return;
      }
      const authData = await UserService.loginByOtp({
        email: formik.values.email,
        code: otp,
      });
      saveAuthData({
        accessToken: authData.token,
        userName: authData.userName,
        userId: authData.id,
      });
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showModal({
          title: t('pages.SignInPage.incorrectOtpTitle'),
          text: t('pages.SignInPage.incorrectOtpMessage'),
        });
      } else {
        showUnknownErrorModal();
      }
    }
  };

  const submitEmailForm = (event) => {
    event.preventDefault();
    setHasEmailFormTriedToSubmit(true);
    formik.handleSubmit();
  };

  return (
    <div className={classes.SignInPage}>
      <div className={classes.formContainer}>
        {currentForm === 'email' && (
          <EmailForm
            title={t('pages.SignInPage.welcome')}
            formik={formik}
            onSubmit={submitEmailForm}
            hasTriedToSubmit={hasEmailFormTriedToSubmit}
          />
        )}
        {currentForm === 'otp' && (
          <OtpVerificationForm
            otp={otp}
            setOtp={setOtp}
            onSubmit={submitOtpPassword}
            resendOtp={resendOtp}
            email={formik.values.email}
          />
        )}
      </div>
    </div>
  );
}
