/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import useDraggable from '../../../../hooks/useDraggable';

import classes from './styles.module.scss';

export default function VideoPreview({
  videoPreviewRef,
  isVideoPreviewPlaying,
  videoPreviewPausePosition,
  videoDuration,
}) {
  const [progress, setProgress] = useState(0);

  const timeLineRef = useRef();
  const handleRef = useRef();

  const { xPosition, isDragging } = useDraggable(handleRef);

  const rewindVideo = useCallback(
    (xPos) => {
      const { left } = timeLineRef.current.getBoundingClientRect();
      const relativeClickPosition = Math.max(0, xPos - left);

      const percentage =
        relativeClickPosition / timeLineRef.current.clientWidth;

      const currentTime = videoDuration * percentage;

      videoPreviewRef.current.currentTime =
        Number.isNaN(currentTime) || !Number.isFinite(currentTime)
          ? 0
          : currentTime;

      if (!isVideoPreviewPlaying) {
        videoPreviewPausePosition.current = currentTime;
      }
    },
    [
      isVideoPreviewPlaying,
      videoDuration,
      videoPreviewPausePosition,
      videoPreviewRef,
    ]
  );

  const rewindOnClick = (event) => {
    rewindVideo(event.clientX);
  };

  useEffect(() => {
    if (isDragging) {
      rewindVideo(xPosition);
    }
  }, [isDragging, rewindVideo, xPosition]);

  useEffect(() => {
    let animationId;

    const animateProgressBar = () => {
      const duration = !Number.isFinite(videoDuration)
        ? videoPreviewRef.current?.duration
        : videoDuration;

      setProgress((videoPreviewRef.current?.currentTime / duration) * 100);

      animationId = requestAnimationFrame(animateProgressBar);
    };

    animationId = requestAnimationFrame(animateProgressBar);

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [videoDuration, videoPreviewRef]);

  return (
    <>
      <div className={classes.VideoProgress} ref={timeLineRef}>
        <div className={classes.progressBar} onClick={rewindOnClick} />
        <div
          className={classes.handle}
          ref={handleRef}
          style={{ left: `${progress}%` }}
        />
      </div>
      {/*   <div
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 2222,
          color: 'white',
          boeder: '1px solid red',
        }}
      >
        {videoPreviewRef.current?.currentTime} {videoDuration} /{' '}
        {videoPreviewRef.current?.duration}
      </div> */}
    </>
  );
}
