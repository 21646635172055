import React, { useState } from 'react';

import CounterOffer from './CounterOffer';
import DeclineOffer from './DeclineOffer';
import OfferDetails from './OfferDetails';
import Congratulations from './Congratulations';

import classes from './styles.module.scss';

export default function Offered({ offer, jobId, refetch }) {
  const [mode, setMode] = useState('congratulations');

  const showOffer = () => {
    setMode('offer');
  };

  const showDeclineOfferForm = () => {
    setMode('decline');
  };

  const showCounterOfferForm = () => {
    setMode('counter');
  };

  return (
    <div className={classes.Offered}>
      {mode === 'congratulations' && <Congratulations showOffer={showOffer} />}
      {mode === 'offer' && (
        <OfferDetails
          refetch={refetch}
          offer={offer}
          jobId={jobId}
          showDeclineOfferForm={showDeclineOfferForm}
        />
      )}
      {mode === 'decline' && (
        <DeclineOffer
          showOffer={showOffer}
          showCounterOfferForm={showCounterOfferForm}
          refetch={refetch}
          offer={offer}
          jobId={jobId}
        />
      )}
      {mode === 'counter' && (
        <CounterOffer
          offer={offer}
          jobId={jobId}
          refetch={refetch}
          showDeclineOfferForm={showDeclineOfferForm}
        />
      )}
    </div>
  );
}
