import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';
import courage from '../../../assets/images/landing/courage.png';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';

export default function HowWeLead() {
  const { t } = useTranslation();

  const setNode = useHeaderLinkHighlighting('future-at-kfc');

  return (
    <div className={classes.HowWeLead} ref={setNode}>
      <h2 className={classes.title}>
        {t('pages.LandingPage.HowWeLead.title.0')}{' '}
        <span className={classes.red}>
          {t('pages.LandingPage.HowWeLead.title.1')}
        </span>
      </h2>
      <p className={classes.text}>
        {t('pages.LandingPage.HowWeLead.texts.0.0')} <br />
        <span className={classes.red}>
          {t('pages.LandingPage.HowWeLead.texts.0.1')}
        </span>{' '}
        {t('pages.LandingPage.HowWeLead.texts.0.2')}
        <br />
        <span className={classes.red}>
          {t('pages.LandingPage.HowWeLead.texts.0.3')}
        </span>
      </p>
      <img src={courage} alt="Smart heart courage" className={classes.image} />
      <p className={classNames(classes.text, classes.secondText)}>
        {t('pages.LandingPage.HowWeLead.texts.1.0')}
      </p>
      <p className={classes.smallText}>
        {t('pages.LandingPage.HowWeLead.texts.2.0')}
      </p>
    </div>
  );
}
