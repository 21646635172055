import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import FilterButton from '../../FilterButton';

import PublicService from '../../../services/PublicService';
import classes from './styles.module.scss';

export default function Carousel() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: newJobPostsCount } = useQuery({
    queryKey: ['newJobPostsCount'],
    queryFn: PublicService.getNewJobPostsCount,
  });

  return (
    <div className={classes.Carousel}>
      <div
        className={classes.filterButtonContainer}
        style={{ display: newJobPostsCount?.count ? 'block' : 'none' }}
      >
        <div onClick={() => navigate('/jobs')}>
          <FilterButton
            filter={{
              title: t('components.MyDashboard.Carousel.newJobs'),
              count: newJobPostsCount?.count,
            }}
            setCurrentFilter={() => {}}
            isActive
          />
        </div>
        <div className={classes.switchButtons}>
          <button type="button">1</button>
          <button className={classes.active} type="button">
            2
          </button>
          <button type="button">3</button>
        </div>
      </div>
    </div>
  );
}
