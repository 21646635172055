import React from 'react';

import classes from './styles.module.scss';

export default function Action({ title, text, date }) {
  return (
    <div className={classes.Action}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.date}>{date}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}
