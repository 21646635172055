import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import bucket from '../../../../assets/images/landing/bucket.png';

const bucketClasses = ['one', 'two', 'three'];
const bucketAnimationDelays = [0, 1750, 3200];
const dotAnimationDelays = [300, 1500, 3500];

export default function Event({ event, inView, index }) {
  const [shouldAnimateBucket, setShouldAnimateBucket] = useState(false);
  const [shouldAnimateDot, setShouldAnimateDot] = useState(false);
  const [shouldAnimateYear, setShouldAnimateYear] = useState(false);
  const [shouldAnimateText, setShouldAnimateText] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setShouldAnimateBucket(true);
      }, bucketAnimationDelays[index]);

      setTimeout(() => {
        setShouldAnimateDot(true);
      }, dotAnimationDelays[index]);

      setTimeout(() => {
        setShouldAnimateYear(true);
      }, dotAnimationDelays[index] + 200);

      setTimeout(() => {
        setShouldAnimateText(true);
      }, dotAnimationDelays[index] + (index === 0 ? 750 : 1500));
    }
  }, [inView, index]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className={classes.Event}
      style={{
        left: isTabletOrMobile ? event.mobileLeftPosition : event.leftPosition,
        right: isTabletOrMobile
          ? event.mobileRightPosition
          : event.rightPosition,
      }}
    >
      <div
        className={classNames(classes.dot, {
          [classes.visible]: shouldAnimateDot,
        })}
      />
      <img
        className={classNames(classes.bucket, classes[bucketClasses[index]], {
          [classes.inView]: shouldAnimateBucket,
        })}
        src={bucket}
        alt="Bucket"
        style={{
          width: isTabletOrMobile ? event.mobileBucketWidth : event.bucketWidth,
          height: isTabletOrMobile
            ? event.mobileBucketHeight
            : event.bucketHeight,
        }}
      />
      <span
        className={classNames(classes.year, {
          [classes.visible]: shouldAnimateYear,
        })}
      >
        {event.year}
      </span>
      {event.text && (
        <div
          className={classNames(classes.textContainer, {
            [classes.visible]: shouldAnimateText,
          })}
        >
          <div className={classes.header}>
            <span className={classes.title}>{event.title}</span>{' '}
            {event.title && '-'}{' '}
            <span className={classes.subTitle}>{event.subTitle}</span>
          </div>
          <p className={classes.text}>{event.text}</p>
        </div>
      )}
    </div>
  );
}
