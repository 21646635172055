import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function DropdownSelector({
  children,
  value,
  currentOption,
  toggleOptions,
  isOptionsListVisible,
  title,
  icon,
}) {
  const dropdownRef = useRef();

  useEffect(() => {
    dropdownRef.current.style.setProperty('--icon', `url(${icon})`);
  }, [icon]);

  return (
    <div
      className={classNames(classes.DropdownSelector, {
        [classes.hasOption]: !!value,
        [classes.open]: isOptionsListVisible,
      })}
      ref={dropdownRef}
    >
      <div className={classes.selector} onClick={toggleOptions}>
        <span className={classes.title}>{title}</span>
        {value && <div className={classes.currentOption}>{currentOption}</div>}
      </div>
      {isOptionsListVisible && children}
    </div>
  );
}
