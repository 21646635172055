import React, { useState, useMemo } from 'react';

import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import NavHeader from '../../components/NavHeader';

import useUiContext from '../../hooks/useUiContext';
import AnonymousService from '../../services/AnonymousService';
import classes from './styles.module.scss';

export default function DropOffPage() {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { showModal, showUnknownErrorModal } = useUiContext();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object({
        leaveReason: yup.string().trim().required(t('common.requiredField')),
        email: yup
          .string()
          .trim()
          .email(t('pages.DropOffPage.emailFormatIncorrect')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      leaveReason: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await AnonymousService.createDropOff({
          reason: values.leaveReason,
          email: values.email,
          jobApplicationId: searchParams.get('jobApplicationId'),
        });
        showModal({
          title: t('pages.DropOffPage.thankYouTitle'),
          text: t('pages.DropOffPage.thankYouText'),
          noCancel: true,
          onConfirm: () => {
            navigate('/', { replace: true });
          },
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    },
  });

  const leaveReasons = [
    {
      value: 'It does not fulfill my expectations',
      label: t('pages.DropOffPage.leaveReasons.itDoesNotFulfillMyExpectations'),
    },
    {
      value: 'Not interested',
      label: t('pages.DropOffPage.leaveReasons.notInterested'),
    },
    {
      value: 'Just browsing',
      label: t('pages.DropOffPage.leaveReasons.justBrowsing'),
    },
  ];

  return (
    <div className={classes.DropOffPage}>
      <div className={classes.container}>
        <NavHeader backIcon />
        <form className={classes.row} onSubmit={formik.handleSubmit}>
          <div className={classes.col}>
            <h2 className={classes.colHeading}>
              {t('pages.DropOffPage.sadToSeeYouGo')}
            </h2>
            <Dropdown
              height={isTabletOrMobile ? 60 : 64}
              label={t('pages.DropOffPage.whyAreYouLeaving')}
              placeholder={t('pages.DropOffPage.pleaseSelectReason')}
              options={leaveReasons}
              name="leaveReason"
              setFieldValue={formik.setFieldValue}
              value={formik.values.leaveReason}
            />
          </div>
          <div className={classes.col}>
            <h2 className={classes.colHeading}>
              {t('pages.DropOffPage.sendFutureJobOpenings')}
            </h2>
            <TextInput
              height={isTabletOrMobile ? 60 : 64}
              label={t('pages.DropOffPage.leaveEmailForJobOpenings')}
              name="email"
              placeholder={t('pages.DropOffPage.emailPlaceholder')}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.email}
              touched={hasTriedToSubmit}
              type="email"
              noShadow
            />
          </div>
        </form>
        <Button
          height={isTabletOrMobile ? 54 : 64}
          width={isTabletOrMobile ? '' : 444}
          style={{ fontSize: 24, fontWeight: 500 }}
          disabled={!formik.values.leaveReason}
          onClick={() => {
            setHasTriedToSubmit(true);
            formik.handleSubmit();
          }}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
