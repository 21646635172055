import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Faces from '../../components/Landing/Faces';
import OpenPositions from '../../components/Landing/OpenPositions';
import AutoPlayVideo from '../../components/Landing/AutoPlayVideo';
import OurPeoplePromise from '../../components/Landing/OurPeoplePromise';
import WordAbout from '../../components/Landing/WordAbout';
import CareerRoadmap from '../../components/Landing/CareerRoadmap';
import Future from '../../components/Landing/Future';
import Videos from '../../components/Landing/Videos';
import TimeLine from '../../components/Landing/TimeLine';
import HowWeLead from '../../components/Landing/HowWeLead';
import AreYouReady from '../../components/Landing/AreYouReady';
import GivingBack from '../../components/Landing/GivingBack';

import useHeaderLinkHighlighting from '../../hooks/useHeaderLinkHighlighting';
import useUiContext from '../../hooks/useUiContext';
import classes from './styles.module.scss';
import isInIframe from '../../helpers/isInIframe';

export default function LandingPage() {
  const { t } = useTranslation();

  const { isPreview } = useUiContext();

  /*   const { data: contentMedias } = useQuery({
    queryKey: ['contentMedias'],
    queryFn: AnonymousService.getContentMedias,
  }); */

  /*  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' }); */

  // Enable scroll when coming from dropoff page
  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = '';
    }, 100);
  }, []);

  const setNode = useHeaderLinkHighlighting('');

  const isDesktopSafari = window.safari !== undefined;

  return (
    <div
      id="landingPage"
      className={classNames(classes.LandingPage, {
        [classes.preview]: isPreview,
        [classes.iframe]: isInIframe(),
      })}
    >
      {isPreview && <div className={classes.overlay} />}
      <div className={classes.controlHeight} />
      <div className={classes.fullScreen} ref={setNode}>
        <div className={classes.container}>
          <p
            className={classNames(classes.intro, {
              [classes.safari]: isDesktopSafari,
            })}
          >
            <span className={classes.animationContainer}>
              <span className={classes.animatedLine}>
                {' '}
                {t('pages.LandingPage.intro.0')}
                {'  '}
                <span className={classes.red}>
                  {t('pages.LandingPage.intro.1')}
                </span>{' '}
              </span>
            </span>
            <br />
            <span className={classes.animationContainer}>
              <span
                className={classNames(classes.animatedLine, classes.second)}
              >
                <span className={classes.red}>
                  {t('pages.LandingPage.intro.2')}
                </span>{' '}
                {t('pages.LandingPage.intro.3')} <br />{' '}
              </span>
            </span>
            <span className={classes.animationContainer}>
              <span className={classNames(classes.animatedLine, classes.third)}>
                {t('pages.LandingPage.intro.4')}
              </span>
            </span>
          </p>
          <Faces />
        </div>
      </div>
      <OpenPositions />
      <AutoPlayVideo />
      <OurPeoplePromise />
      <WordAbout />
      <CareerRoadmap />
      <Future />
      <Videos />
      <TimeLine />
      <HowWeLead />
      <AreYouReady />
      <GivingBack />
    </div>
  );
}
