import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../components/Button';
import classes from './styles.module.scss';

export default function VrQuestionModal({
  show,
  handleClose,
  startVideoRecording,
  time,
  question,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.VrQuestionModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h1>
          {t('modals.VrQuestionModal.title.0')}{' '}
          <span>
            {time} {t('modals.VrQuestionModal.title.1')}
          </span>{' '}
          <br /> {t('modals.VrQuestionModal.title.2')}
        </h1>
        <p>{question}</p>
        <div className={classes.buttons}>
          <Button
            width={128}
            height={37}
            style={{ fontSize: 18, paddingLeft: 16 }}
            onClick={() => {
              startVideoRecording();
              handleClose();
            }}
          >
            {t('modals.VrQuestionModal.startRecordingButton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
