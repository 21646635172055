import React, { useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import CustomEase from 'gsap/CustomEase';

gsap.registerPlugin(MotionPathPlugin, CustomEase);

export default function Arrow({ animationDelay }) {
  const targetRef = useRef();
  const pathRef = useRef();
  const animationRef = useRef();
  const svgAnimationRef1 = useRef();
  const svgAnimationRef2 = useRef();

  const linearGradientId = `left-to-right-${animationDelay}`;

  useEffect(() => {
    // gsap.ticker.fps(144);

    gsap.set(targetRef.current, { visibility: 'hidden' });
    animationRef.current = gsap.to(targetRef.current, {
      duration: 0.48,
      delay: animationDelay,
      motionPath: {
        path: pathRef.current,
        align: pathRef.current,
        alignOrigin: [0.65, 0.5],
        autoRotate: -80,
        end: 0.47,
        // offsetY: -3,
        // offsetX: -8,
      },
      ease: CustomEase.create('customEase', 'M0,0 C0.645,0.045 0.355,1 1,1'),
      // ease: 'none',
      onStart: () => {
        // Start the SVG animation
        svgAnimationRef1.current?.beginElement();
        svgAnimationRef2.current?.beginElement();
        // Set the target element to visible when the animation starts
        gsap.set(targetRef.current, { visibility: 'visible' });
      },
    });
  }, [animationDelay]);

  return (
    <>
      <svg
        width="141"
        height="204"
        viewBox="0 0 141 204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id={linearGradientId}
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            <stop offset="0" stopColor="black">
              <animate
                attributeName="offset"
                values="0;1"
                dur="0.5s"
                fill="freeze"
                begin="indefinite"
                keySplines="0.645, 0.045, 0.355, 1"
                keyTimes="0;1"
                calcMode="spline"
                ref={svgAnimationRef1}
              />
            </stop>
            <stop offset="0" stopColor="var(--secondary-color)">
              <animate
                attributeName="offset"
                values="0;1"
                dur="0.5s"
                fill="freeze"
                keySplines="0.645, 0.045, 0.355, 1"
                keyTimes="0;1"
                calcMode="spline"
                begin="indefinite"
                ref={svgAnimationRef2}
              />
            </stop>
          </linearGradient>
        </defs>

        <path
          ref={pathRef}
          fillRule="evenodd"
          fill={`url(#${linearGradientId})`}
          clipRule="evenodd"
          d="M7.2345 0.5625C81.7796 38.9201 134.231 114.233 140.562 202.411L125.451 203.5C119.511 120.775 70.3026 50.0774 0.3125 14.0636L7.2345 0.5625Z"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
        }}
        ref={targetRef}
        width="53"
        height="50"
        viewBox="0 0 53 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.7183 0.625L27.1605 27.5483L0.5625 2.61373L1.39369 24.6418L27.9917 49.5L52.625 22.5767L51.7183 0.625Z"
          fill="black"
        />
      </svg>
    </>
  );
}
