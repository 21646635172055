import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function UploadInProgress({
  totalMediaSize,
  mediaUploadProgress,
}) {
  const [progress, setProgress] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const uploaded = mediaUploadProgress.reduce(
      (acc, curVal) => acc + curVal,
      0
    );
    const currentProgress = (uploaded / totalMediaSize) * 100;

    setProgress(currentProgress);
  }, [totalMediaSize, mediaUploadProgress]);

  return (
    <div className={classes.UploadInProgress}>
      <div className={classes.progressBar}>
        <div className={classes.progress} style={{ width: `${progress}%` }} />
      </div>
      <div className={classes.content}>
        <h1>
          {t(
            'components.RecordVideo.MobileScreens.UploadInProgress.uploadInProgress'
          )}
        </h1>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.4255 20.6143C38.525 20.5148 38.6872 20.517 38.7825 20.6204C41.9142 24.0152 43.6869 28.4529 43.7486 33.083C43.7505 33.221 43.6384 33.3331 43.5003 33.3331H33.5731C33.435 33.3331 33.3235 33.2211 33.3194 33.0831C33.2632 31.2117 32.5777 29.4185 31.3817 27.9902C31.2968 27.8888 31.301 27.7389 31.3945 27.6453L38.4255 20.6143ZM37.7124 19.5502C37.8158 19.6456 37.8179 19.8078 37.7184 19.9072L30.6874 26.9383C30.5939 27.0318 30.4439 27.036 30.3425 26.9511C29.0382 25.8591 27.4296 25.1929 25.7351 25.0428C25.6034 25.0311 25.5003 24.9221 25.5003 24.7899V14.8465C25.5003 14.7058 25.6165 14.5927 25.757 14.5983C30.203 14.7778 34.4416 16.5334 37.7124 19.5502ZM24.5003 14.8465C24.5003 14.7058 24.3842 14.5927 24.2436 14.5983C19.7976 14.7778 15.559 16.5334 12.2882 19.5503C12.1848 19.6456 12.1827 19.8078 12.2822 19.9073L19.3132 26.9383C19.4067 27.0318 19.5567 27.036 19.6581 26.9511C20.9625 25.8591 22.571 25.1929 24.2655 25.0428C24.3972 25.0311 24.5003 24.9221 24.5003 24.7899V14.8465ZM18.6189 27.9902C18.7038 27.8888 18.6996 27.7389 18.6061 27.6454L11.575 20.6144C11.4756 20.5149 11.3134 20.517 11.218 20.6204C8.08645 24.0152 6.31367 28.4529 6.25198 33.083C6.25014 33.221 6.36224 33.3331 6.50031 33.3331H16.4275C16.5656 33.3331 16.6771 33.2211 16.6813 33.0831C16.7374 31.2117 17.4229 29.4185 18.6189 27.9902Z"
            fill="#242833"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.5 24.7687C25.5 24.901 25.6031 25.0099 25.7348 25.0216C27.4348 25.1718 29.0486 25.8403 30.357 26.9361C30.4584 27.021 30.6083 27.0169 30.7018 26.9233L39.1924 18.4327C39.2917 18.3335 39.2898 18.1717 39.187 18.0761C35.5237 14.6694 30.7553 12.6942 25.7561 12.5129C25.6158 12.5078 25.5 12.6208 25.5 12.7612V24.7687Z"
            fill="#0ACF83"
          />
        </svg>
        <div className={classes.internetSpeed}>
          {t(
            'components.RecordVideo.MobileScreens.UploadInProgress.internetSpeed'
          )}{' '}
          <span>
            {t('components.RecordVideo.MobileScreens.UploadInProgress.strong')}
          </span>
        </div>
        <p className={classes.info}>
          {t(
            'components.RecordVideo.MobileScreens.UploadInProgress.uploadSpeedInfo'
          )}
        </p>
      </div>
    </div>
  );
}
