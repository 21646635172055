import React from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../TextInput';
import PhoneSelector from '../../PhoneSelector';

import classes from './styles.module.scss';

export default function MainInfoForm({ email, formik, hasTriedToSubmit }) {
  const { t } = useTranslation();

  return (
    <div className={classes.MainInfoForm}>
      <div className={classes.row}>
        <div className={classes.col}>
          <TextInput
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={hasTriedToSubmit}
            error={formik.errors.firstName}
            name="firstName"
            label={t('components.EditProfile.MainInfoForm.firstNameLabel')}
            placeholder={t(
              'components.EditProfile.MainInfoForm.firstNamePlaceholder'
            )}
          />
        </div>
        <div className={classes.col}>
          <TextInput
            label={t('components.EditProfile.MainInfoForm.lastNameLabel')}
            placeholder={t(
              'components.EditProfile.MainInfoForm.lastNamePlaceholder'
            )}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={hasTriedToSubmit}
            error={formik.errors.lastName}
            name="lastName"
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <TextInput
            label={t('components.EditProfile.MainInfoForm.emailLabel')}
            placeholder={t(
              'components.EditProfile.MainInfoForm.emailPlaceholder'
            )}
            readOnly
            type="email"
            value={email}
          />
        </div>
        <div className={classes.col}>
          <PhoneSelector
            label={t('components.EditProfile.MainInfoForm.phoneNumberLabel')}
            placeholder={t(
              'components.EditProfile.MainInfoForm.phoneNumberPlaceholder'
            )}
            value={formik.values.phone}
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={hasTriedToSubmit}
            error={formik.errors.phone}
            name="phone"
            type="phone"
          />
        </div>
      </div>
    </div>
  );
}
