/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import useOnClickOutside from '../../hooks/useOnClickOutside';

import classes from './styles.module.scss';
import chevron from '../../assets/images/chevron.svg';

export default function SelectWithSearch({
  value,
  options,
  onChange,
  placeholder,
}) {
  const [search, setSearch] = useState('');
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const selectRef = useRef();
  useOnClickOutside(selectRef, () => setIsOptionsListVisible(false));

  const visibleOptions =
    options?.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    ) || [];

  return (
    <div className={classes.SelectWithSearch} ref={selectRef}>
      <div
        tabIndex={0}
        role="button"
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsOptionsListVisible((prevState) => !prevState);
          }
        }}
      >
        {value} <img src={chevron} alt={value} />
      </div>
      {isOptionsListVisible && (
        <div className={classes.options}>
          <div className={classes.search}>
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={classes.searchInput}
              placeholder={placeholder}
            />
          </div>
          <div className={classes.optionsListContainer}>
            <ul className={classes.optionsList}>
              {visibleOptions?.map((option) => (
                <li
                  tabIndex={0}
                  role="button"
                  key={option.value}
                  className={classes.option}
                  onClick={() => onChange(option)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onChange(option);
                    }
                  }}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
