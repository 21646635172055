import i18n from '../i18n';

const t = i18n.t.bind(i18n);

export default function getJobAppLabelColor(label) {
  switch (true) {
    case label === t('components.Jobs.Job.labels.Not Selected') ||
      label.toLowerCase().includes('decline') ||
      label === t('components.Jobs.Job.labels.Application abandoned') ||
      label === t('components.Jobs.Job.labels.Withdrawn'):
      return { backgroundColor: 'rgb(213, 45, 52)', color: 'white' };
    case label === t('components.Jobs.Job.labels.Job Closed'):
      return { backgroundColor: '#C1C5D6', color: '#242833' };
    case label === t('components.Jobs.Job.labels.New'):
      return { backgroundColor: '#FDF2E4', color: '#000000' };
    case label === t('components.Jobs.Job.labels.Viewed'):
      return { backgroundColor: '#F2F4F8', color: '#000000' };
    default:
      return { backgroundColor: 'rgb(10, 207, 131)', color: 'white' };
  }
}
