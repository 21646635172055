import { useEffect, useState, useCallback } from 'react';

export default function useDraggable(
  draggableRef,
  setIsCursorAnimationDisabled
) {
  const [yClickPosition, setYClickPosition] = useState(0);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const move = useCallback(
    (event) => {
      if (setIsCursorAnimationDisabled) {
        setIsCursorAnimationDisabled(true);
      }

      const xPos = event.clientX || event.touches[0].clientX;
      const yPos = event.clientY || event.touches[0].clientY;

      setXPosition(xPos);
      setYPosition(yPos);
    },
    [setIsCursorAnimationDisabled]
  );

  const onMouseUp = useCallback(() => {
    if (setIsCursorAnimationDisabled) {
      setIsCursorAnimationDisabled(false);
    }
    setIsDragging(false);
    document.body.removeEventListener('mousemove', move);
    document.body.removeEventListener('mouseup', onMouseUp);
  }, [move, setIsCursorAnimationDisabled]);

  const onMouseDown = useCallback(
    (event) => {
      const draggableCoords = draggableRef.current.getBoundingClientRect();
      const yPos = event.clientY || event.touches[0].clientY;
      setYClickPosition(yPos - draggableCoords.top);
      event.preventDefault();
      setIsDragging(true);
      document.body.addEventListener('mousemove', move);
      document.body.addEventListener('touchmove', move);
      document.body.addEventListener('mouseup', onMouseUp);
      document.body.addEventListener('touchend', onMouseUp);
    },
    [draggableRef, move, onMouseUp]
  );

  useEffect(() => {
    const draggableRefCurrent = draggableRef.current;

    if (draggableRefCurrent) {
      draggableRefCurrent.addEventListener('mousedown', onMouseDown);
      draggableRefCurrent.addEventListener('touchstart', onMouseDown);
    }
    return () => {
      if (draggableRefCurrent) {
        document.body.removeEventListener('mousemove', move);
        document.body.removeEventListener('touchmove', move);
        document.body.removeEventListener('mouseup', onMouseUp);
        document.body.removeEventListener('touchend', onMouseUp);
        draggableRefCurrent.removeEventListener('mousedown', onMouseDown);
        draggableRefCurrent.removeEventListener('touchstart', onMouseDown);
      }
    };
  }, [draggableRef, move, onMouseDown, onMouseUp]);

  return {
    xPosition,
    yPosition,
    yClickPosition,
    isDragging,
  };
}
