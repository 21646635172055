import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import JobCard from './JobCard';
import SelectWithSearch from '../../SelectWithSearch';

import AnonymousService from '../../../services/AnonymousService';
import classes from './styles.module.scss';
import chevron from '../../../assets/images/landing/chevron.svg';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
import useOnScreen from '../../../hooks/useOnScreen';

const calculateContainerWidth = () => {
  const JOB_CARD_WIDTH = 289;
  const GAP = 17;
  const PADDING = 116;

  const windowWidth = window.innerWidth;
  const cardsPerPage = Math.floor(
    (windowWidth - PADDING * 2) / (JOB_CARD_WIDTH + GAP)
  );

  return {
    width: cardsPerPage * (JOB_CARD_WIDTH + GAP) - GAP,
    cardsPerPage,
  };
};

export default function OpenPositions({ setNode }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLeftButtonAnimationPlaying, setIsLeftButtonAnimationPlaying] =
    useState(false);
  const [isRightButtonAnimationPlaying, setIsRightButtonAnimationPlaying] =
    useState(false);
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [containerWidth, setContainerWidth] = useState('');
  const [cardsPerPage, setCardsPerPage] = useState(0);

  const titleRef = useRef();
  const isIntersecting = useOnScreen(titleRef);

  useEffect(() => {
    if (isIntersecting) {
      setIsTitleVisible(true);
    }
  }, [isIntersecting]);

  // Change container width on resize so that the job cards are always aligned
  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(calculateContainerWidth()?.width);
      setCardsPerPage(calculateContainerWidth()?.cardsPerPage);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: jobPages, isFetching } = useQuery({
    queryKey: ['last20Jobs'],
    queryFn: () =>
      AnonymousService.getJobs({ PageSize: 20, OrderBy: 'createdAt:desc' }),
  });

  const { data: cities } = useQuery({
    queryKey: ['cities'],
    queryFn: AnonymousService.getCities,
  });

  const cityOptions = cities
    ?.map((ct) => ({
      label: ct.name,
      value: ct.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const jobs = jobPages?.data?.jobPosts;

  const setNodeHeaderLinkHighlighting = useHeaderLinkHighlighting('');

  const noData = !isFetching && (!jobs || !jobs?.length);

  return (
    <div className={classes.OpenPositions} ref={setNode}>
      <div
        className={classes.container}
        ref={setNodeHeaderLinkHighlighting}
        style={{
          maxWidth: containerWidth,
        }}
      >
        <div className={classes.header}>
          <h2 className={classes.title} ref={titleRef}>
            <span
              className={classNames(classes.animated, {
                [classes.inView]: isTitleVisible,
              })}
            >
              {t('pages.LandingPage.OpenPositions.title')}
            </span>
          </h2>
          {!noData && (
            <div className={classes.locationPicker}>
              <SelectWithSearch
                placeholder={t('pages.LandingPage.OpenPositions.placeholder')}
                onChange={(city) =>
                  navigate(`/jobs/city/${city.value}/${city.label}`)
                }
                options={cityOptions}
                value={t('pages.LandingPage.OpenPositions.locationPicker')}
              />
            </div>
          )}
        </div>
        {((!noData && scrollPosition !== 0) ||
          isLeftButtonAnimationPlaying) && (
          <button
            type="button"
            className={classNames(classes.scrollButton, {
              [classes.animating]: isLeftButtonAnimationPlaying,
            })}
            onAnimationEnd={() => setIsLeftButtonAnimationPlaying(false)}
            onClick={() =>
              setScrollPosition((prevPostion) => {
                setIsLeftButtonAnimationPlaying(true);
                if (prevPostion > 0) {
                  return prevPostion - 1;
                }
                return prevPostion;
              })
            }
          >
            <img src={chevron} alt="Left" />
          </button>
        )}
        <div className={classes.jobsContainer}>
          <div className={classes.outerContainer}>
            {noData ? (
              <div className={classes.noData}>
                <p>{t('pages.LandingPage.OpenPositions.noData')}</p>
              </div>
            ) : (
              <div className={classes.overflowWrapper}>
                <div
                  className={classes.innerContainer}
                  style={
                    scrollPosition > 0
                      ? {
                          transform: `translateX(-${
                            scrollPosition * (289 + 17)
                          }px)`,
                        }
                      : null
                  }
                >
                  {jobs?.map((job) => (
                    <JobCard key={job.id} job={job} />
                  ))}
                </div>
              </div>
            )}
            {!noData &&
              (scrollPosition < jobs?.length - cardsPerPage ||
                isRightButtonAnimationPlaying) && (
                <button
                  type="button"
                  className={classNames(classes.scrollButton, classes.right, {
                    [classes.animating]: isRightButtonAnimationPlaying,
                  })}
                  onAnimationEnd={() => setIsRightButtonAnimationPlaying(false)}
                  onClick={() =>
                    setScrollPosition((prevPostion) => {
                      setIsRightButtonAnimationPlaying(true);

                      if (prevPostion < jobs?.length - cardsPerPage) {
                        return prevPostion + 1;
                      }
                      return prevPostion;
                    })
                  }
                >
                  <img src={chevron} alt="Left" />
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
