/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const options = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
];

export default function Dropdown({ value, setValue }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const changeTime = (time) => {
    setValue(time);
    setIsOptionsListVisible(false);
  };

  return (
    <div className={classes.Dropdown} ref={dropdownRef}>
      <div
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {value}
      </div>
      {isOptionsListVisible && (
        <ul className={classes.options}>
          {options.map((option) => {
            return (
              <li
                className={classNames({
                  [classes.active]: value === option,
                })}
                onClick={() => changeTime(option)}
                key={option}
              >
                {option}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
