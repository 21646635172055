import React from 'react';

import { useTranslation } from 'react-i18next';

import FilterButton from '../../FilterButton';

import classes from './styles.module.scss';

export default function Filters({ filters, currentFilter, setCurrentFilter }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Filters}>
      <div className={classes.container}>
        <FilterButton
          key={Math.random()}
          filter={{
            title: t('components.Jobs.Filters.all'),
            count: filters?.reduce(
              (acc, currValue) => acc + currValue.count,
              0
            ),
          }}
          isActive={!currentFilter}
          setCurrentFilter={() => setCurrentFilter(null)}
        />
        {filters?.map((filter) => (
          <FilterButton
            key={filter.title}
            filter={filter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
          />
        ))}
      </div>
    </div>
  );
}
