import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';

export default function PolicyPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={classes.PolicyPage}>
      <header className={classes.header}>
        <i className={classes.backIcon} onClick={() => navigate(-1)}>
          Back
        </i>
        <h1>{t('pages.PolicyPage.titles.onlinePrivacy')}</h1>
      </header>
      <div className={classes.content}>
        <section>
          <h2>1. {t('pages.PolicyPage.titles.scopeTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.0.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.0.paragraphs.1')}</p>
          <p>{t('pages.PolicyPage.sections.0.paragraphs.2')}</p>
        </section>
        <section>
          <h2>2. {t('pages.PolicyPage.titles.legalGroundsTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.1.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.1.paragraphs.1')}</p>
          <p>{t('pages.PolicyPage.sections.1.paragraphs.2')}</p>
          <p>{t('pages.PolicyPage.sections.1.paragraphs.3')}</p>
        </section>
        <section>
          <h2>3. {t('pages.PolicyPage.titles.collectedDataTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.1')}</p>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.2')}</p>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.3')}</p>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.4')}</p>
          <p>{t('pages.PolicyPage.sections.2.paragraphs.5')}</p>
          <ul>
            <li>{t('pages.PolicyPage.sections.2.lists.0.items.0')}</li>
            <li>{t('pages.PolicyPage.sections.2.lists.0.items.1')}</li>
            <li>{t('pages.PolicyPage.sections.2.lists.0.items.2')}</li>
            <li>{t('pages.PolicyPage.sections.2.lists.0.items.3')}</li>
          </ul>
        </section>
        <section>
          <h2>4. {t('pages.PolicyPage.titles.categoriesOfCookiesTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.3.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.3.paragraphs.1')}</p>
          <p>{t('pages.PolicyPage.sections.3.paragraphs.2')}</p>
          <p>{t('pages.PolicyPage.sections.3.paragraphs.3')}</p>
          <p>
            {t('pages.PolicyPage.sections.3.paragraphs.4')}{' '}
            <a href="http://www.allaboutcookies.org">
              http://www.allaboutcookies.org
            </a>{' '}
            or{' '}
            <a href="http://www.aboutcookies.org">
              http://www.aboutcookies.org
            </a>
            .
          </p>
          <p>{t('pages.PolicyPage.sections.3.paragraphs.5')}</p>
          <p>
            {t('pages.PolicyPage.sections.3.paragraphs.6')}{' '}
            <a href="http://www.allaboutdnt.org">http://www.allaboutdnt.org</a>.
          </p>
        </section>
        <section>
          <h2>5. {t('pages.PolicyPage.titles.aboutSpecialConsentTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.4.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.4.paragraphs.1')}</p>
        </section>
        <section>
          <h2>6. {t('pages.PolicyPage.titles.howCompanyUsesDataTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.5.paragraphs.0')}</p>
          <ul>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.0')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.1')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.2')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.3')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.4')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.5')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.6')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.7')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.8')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.9')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.10')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.11')}</li>
            <li>{t('pages.PolicyPage.sections.5.lists.0.items.12')}</li>
          </ul>
        </section>
        <section>
          <h2>7. {t('pages.PolicyPage.titles.sharingYourDataTitle')}</h2>
          <p>
            We may share the Personal Data, we collect from our website, as
            described below
          </p>
          <ul>
            <li>{t('pages.PolicyPage.sections.6.lists.0.items.0')}</li>
            <li>{t('pages.PolicyPage.sections.6.lists.0.items.1')}</li>
            <li>{t('pages.PolicyPage.sections.6.lists.0.items.2')}</li>
            <li>{t('pages.PolicyPage.sections.6.lists.0.items.3')}</li>
            <li>{t('pages.PolicyPage.sections.6.lists.0.items.4')}</li>
          </ul>
        </section>
        <section>
          <h2>8. {t('pages.PolicyPage.titles.yourChoicesTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.7.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.7.paragraphs.1')}</p>
        </section>
        <section>
          <h2>9. {t('pages.PolicyPage.titles.yourRightsTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.8.paragraphs.0')}</p>
          <ol type="a">
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.0')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.1')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.2')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.3')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.4')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.5')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.6')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.7')}</li>
            <li>{t('pages.PolicyPage.sections.8.lists.0.items.8')}</li>
          </ol>
          <p>{t('pages.PolicyPage.sections.8.paragraphs.1')}</p>
        </section>
        <section>
          <h2>10. {t('pages.PolicyPage.titles.howLongWeRetainDataTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.9.paragraphs.0')}</p>
        </section>
        <section>
          <h2>11. {t('pages.PolicyPage.titles.securityTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.10.paragraphs.0')}</p>
          <p>{t('pages.PolicyPage.sections.10.paragraphs.1')}</p>
        </section>
        <section>
          <h2>12. {t('pages.PolicyPage.titles.thirdPartyWebsitesTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.11.paragraphs.0')}</p>
        </section>
        <section>
          <h2>13. {t('pages.PolicyPage.titles.childrensPrivacyTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.12.paragraphs.0')}</p>
        </section>
        <section>
          <h2>
            14. {t('pages.PolicyPage.titles.internationalDataTransfersTitle')}
          </h2>
          <p>{t('pages.PolicyPage.sections.13.paragraphs.0')}</p>
          <p>
            {t('pages.PolicyPage.sections.13.paragraphs.1')}{' '}
            <a href="https://www.privacyshield.gov/list">
              https://www.privacyshield.gov/list
            </a>
            {t('pages.PolicyPage.sections.13.paragraphs.2')}
          </p>
          <p>{t('pages.PolicyPage.sections.13.paragraphs.3')}</p>
          <p>{t('pages.PolicyPage.sections.13.paragraphs.4')}</p>
        </section>
        <section>
          <h2>15. {t('pages.PolicyPage.titles.contactUsTitle')}</h2>
          <p>{t('pages.PolicyPage.sections.14.paragraphs.0')}</p>
          <p>
            {t('pages.PolicyPage.sections.14.paragraphs.1')}{' '}
            <a href="mailto:isgida@hs02.kep.tr">isgida@hs02.kep.tr</a>{' '}
            {t('pages.PolicyPage.sections.14.paragraphs.2')}
          </p>
        </section>
      </div>
    </div>
  );
}
