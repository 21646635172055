import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function RecordVideoProgress({ activeStep, steps }) {
  const { t } = useTranslation();

  return (
    <div className={classes.RecordVideoProgress}>
      {steps.map((step, index) => {
        return (
          <div
            className={classNames(classes.step, {
              [classes.active]: index + 1 === activeStep,
              [classes.completed]: index + 1 < activeStep,
            })}
            key={step.name}
          >
            <div
              className={classNames(classes.number, {
                [classes.lastOne]: index + 1 === steps.length,
              })}
            >
              {index + 1}
            </div>
            <div className={classes.info}>
              <h2>
                {step.duration ? `Video ${index}:` : ''} {step.name}
              </h2>
              {step.duration && (
                <span>
                  {step.duration}{' '}
                  {t('components.RecordVideo.RecordVideoProgress.seconds')}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
