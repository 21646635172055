import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';

export default function AreYouReady() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const setNode = useHeaderLinkHighlighting('');

  return (
    <div className={classes.AreYouReady} ref={setNode}>
      <h2 className={classes.title}>
        {t('pages.LandingPage.AreYouReady.title.0')}
        <br />{' '}
        <span className={classes.red}>
          {t('pages.LandingPage.AreYouReady.title.1')}
        </span>
      </h2>
      <button
        className={classes.button}
        type="button"
        onClick={() => navigate('/jobs')}
      >
        {t('pages.LandingPage.AreYouReady.joinOurTeam')}
      </button>
    </div>
  );
}
