import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function NoFavorites() {
  const { t } = useTranslation();

  return (
    <div className={classes.NoFavorites}>
      <div className={classes.favIcon}>
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H11C13.8284 1 15.2426 1 16.1213 1.87868C17 2.75736 17 4.17157 17 7V13.8276C17 16.5109 17 17.8525 16.1557 18.2629C15.3114 18.6733 14.2565 17.8444 12.1465 16.1866L11.4713 15.656C10.2849 14.7239 9.69173 14.2578 9 14.2578C8.30827 14.2578 7.71509 14.7239 6.52871 15.656L5.85346 16.1866C3.74355 17.8444 2.68859 18.6733 1.84429 18.2629C1 17.8525 1 16.5109 1 13.8276V7Z"
            fill="#D52D34"
            stroke="#D52D34"
            strokeWidth="2"
          />
        </svg>
      </div>
      <p className={classes.text}>{t('pages.MyFavoritesPage.noData')}</p>
    </div>
  );
}
