import React, { useEffect, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti';

import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

import classes from './styles.module.scss';
import useResizeObserver from '../../hooks/useResizeObserver';

let timer;

export default function ApplicationSuccessModal({
  show,
  handleClose,
  userName,
  jobTitle,
}) {
  const modalRef = useRef();
  // For some reason resize observer returns incorrect widht and height
  // It is used here only to force rerender and get correct width and height from ref
  // eslint-disable-next-line no-unused-vars
  const { width: w } = useResizeObserver(modalRef);

  const width = modalRef.current?.offsetWidth;
  const height = modalRef.current?.offsetHeight;

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      timer = setTimeout(() => {
        navigate('/my-dashboard');
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, show]);

  const closeModalAndRedirectToDashboard = () => {
    if (window.location.pathname !== '/my-dashboard') {
      navigate('/my-dashboard');
    }
    clearTimeout(timer);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={closeModalAndRedirectToDashboard}
      centered
      className={classes.ApplicationSuccessModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content} ref={modalRef}>
        <i
          className={classes.closeIcon}
          onClick={closeModalAndRedirectToDashboard}
        >
          Close
        </i>
        <div className={classes.header}>
          <Confetti
            width={isTabletOrMobile ? width : 908}
            height={isTabletOrMobile ? height : 833}
            numberOfPieces={30}
            confettiSource={
              isTabletOrMobile ? { x: width / 2, y: 130 } : { x: 454, y: 240 }
            }
          />
          <h1>{t('modals.ApplicationSuccessModal.title')}</h1>
        </div>
        <div className={classes.text}>
          <p>
            {t('modals.ApplicationSuccessModal.message1', {
              userName,
            })}
          </p>
          <p>
            {t('modals.ApplicationSuccessModal.message2', {
              jobTitle,
            })}
          </p>
          <br />
          <p>
            {t('modals.ApplicationSuccessModal.message3', {
              jobTitle,
            })}
          </p>
        </div>

        <Button
          onClick={closeModalAndRedirectToDashboard}
          width={isTabletOrMobile ? '100%' : 444}
          height={64}
          style={{ fontSize: 24 }}
        >
          {t('common.gotIt')}
        </Button>
      </div>
    </Modal>
  );
}
