import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Title({ interviewStatus }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const defaultTitle = (
    <p className={classes.heading}>
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.defaultTitle.0'
      )}
      <br />{' '}
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.defaultTitle.1'
      )}
    </p>
  );

  const acceptedTitle = (
    <p className={classes.heading}>
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.acceptedTitle'
      )}
    </p>
  );

  const declinedTitle = (
    <p className={classes.heading}>
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.declinedTitle'
      )}
    </p>
  );

  const rescheduleTitle = (
    <p className={classes.heading}>
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.rescheduleTitle'
      )}
    </p>
  );

  const confirmRescheduleTitle = (
    <p
      className={classes.heading}
      style={{ width: isTabletOrMobile ? '' : 560 }}
    >
      {t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.Title.confirmRescheduleTitle'
      )}
    </p>
  );

  return (
    <>
      {interviewStatus === 'unset' && defaultTitle}
      {interviewStatus === 'accepted' && acceptedTitle}
      {interviewStatus === 'declined' && declinedTitle}
      {interviewStatus === 'reschedule' && rescheduleTitle}
      {interviewStatus === 'confirmReschedule' && confirmRescheduleTitle}
    </>
  );
}
