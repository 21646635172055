import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import UserMenu from './UserMenu';
import Header from './Header';
import Footer from './Footer';
import CookiesPolicy from '../../components/CookiesPolicy';
import ApplicationSuccessModal from '../../modals/ApplicationSuccessModal';
import ScrollToAnchor from '../../components/ScrollToAnchor';

import { hasAuthData } from '../../helpers/authStorage';
import { UiContext } from '../../context/UiContext';
import getResizedImageUrl from '../../helpers/getResizedImageUrl';
import PublicService from '../../services/PublicService';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';

const setColors = (colors) => {
  const root = document.documentElement;
  root.style.setProperty('--brand-color', colors[0]);
  root.style.setProperty('--black-color', colors[1]);
  root.style.setProperty('--secondary-color', colors[2]);
  root.style.setProperty('--white-color', colors[3]);
  root.style.setProperty('--gray-color', colors[4]);
};

const setFavicon = (favicon) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const url = getResizedImageUrl({
    url: `brand/${favicon?.split('/')?.pop()}`,
    width: 32,
  });

  link.href = url;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return null;
};

const CheckUserProfile = () => {
  const isLoggedIn = hasAuthData();
  const { checkIfUserProfileFilled } = useContext(UiContext);

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (user && isLoggedIn) {
      checkIfUserProfileFilled(user);
    }
  }, [user, isLoggedIn, checkIfUserProfileFilled]);

  return null;
};

export default function Layout({ children, landing }) {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [logo, setLogo] = useState(null);

  const {
    setIsPreview,
    applicationSuccessModalData,
    isApplicationSuccessModalVisible,
    setIsApplicationSuccessModalVisible,
  } = useContext(UiContext);

  const { data: tenantSettings, refetch } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: PublicService.getTenantSettings,
  });

  useEffect(() => {
    // set title dynamically
    if (tenantSettings?.brand?.name) {
      document.title = tenantSettings.brand?.name;
    }

    // set colors dynamically
    if (tenantSettings?.brandColors.length) {
      const colors = tenantSettings?.brandColors?.map((color) => color.value);
      setColors(colors);
    }

    // set favicon dynamically
    if (tenantSettings?.brand?.favicon) {
      setFavicon(tenantSettings?.brand?.favicon);
    }

    // set logo dynamically
    if (tenantSettings?.brand?.logo) {
      setLogo(tenantSettings?.brand?.logo);
    }
  }, [tenantSettings]);

  // listen for messages from iframe host
  useEffect(() => {
    const readMessage = (event) => {
      if (event.data?.type === 'setPreviewMode') {
        setIsPreview(true);
      } else if (event.data?.type === 'setColors') {
        setColors(event.data.payload);
      } else if (event.data?.type === 'setLogo') {
        setLogo(event.data.payload);
      }
    };

    window.addEventListener('message', readMessage);

    return () => {
      window.removeEventListener('message', readMessage);
    };
  }, [setIsPreview]);

  return (
    <main className={classes.Layout}>
      <Header
        setIsUserMenuVisible={setIsUserMenuVisible}
        logo={logo}
        landing={landing}
      />
      {children}
      <Footer tenantSettings={tenantSettings} refetch={refetch} />
      <UserMenu
        isVisible={isUserMenuVisible}
        setIsUserMenuVisible={setIsUserMenuVisible}
      />
      <ScrollToTop />
      <ScrollToAnchor />
      <CheckUserProfile />
      <CookiesPolicy />
      <ApplicationSuccessModal
        show={isApplicationSuccessModalVisible}
        handleClose={() => {
          setIsApplicationSuccessModalVisible(false);
        }}
        userName={applicationSuccessModalData?.userName}
        jobTitle={applicationSuccessModalData?.jobTitle}
      />
    </main>
  );
}
