import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Employee from './Employee';

import classes from './styles.module.scss';
import teamMember from '../../../assets/images/landing/employees/team-member.svg';
import teamMemberArrow from '../../../assets/images/landing/employees/team-member-arrow.svg';
import shiftSupervisor from '../../../assets/images/landing/employees/shift-supervisor.svg';
import shiftSupervisorArrow from '../../../assets/images/landing/employees/shift-supervisor-arrow.svg';
import manager from '../../../assets/images/landing/employees/manager.svg';
import managerArrow from '../../../assets/images/landing/employees/manager-arrow.svg';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
import useOnScreen from '../../../hooks/useOnScreen';

export default function CareerRoadmap() {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const titleRef = useRef();

  const isIntersecting = useOnScreen(titleRef);

  useEffect(() => {
    if (isIntersecting) {
      setShouldAnimate(true);
    }
  }, [isIntersecting]);

  const { t } = useTranslation();

  const employees = [
    {
      title: t('pages.LandingPage.CareerRoadmap.teamMember'),
      text: t('pages.LandingPage.CareerRoadmap.apply'),
      subText: '',
      image: teamMember,
      arrowImage: teamMemberArrow,
    },
    {
      title: t('pages.LandingPage.CareerRoadmap.shiftSupervisor'),
      text: t('pages.LandingPage.CareerRoadmap.apply'),
      subText: t('pages.LandingPage.CareerRoadmap.6months'),
      image: shiftSupervisor,
      arrowImage: shiftSupervisorArrow,
    },
    {
      title: t('pages.LandingPage.CareerRoadmap.manager'),
      text: t('pages.LandingPage.CareerRoadmap.apply'),
      subText: t('pages.LandingPage.CareerRoadmap.12months'),
      image: manager,
      arrowImage: managerArrow,
    },
  ];

  const setNode = useHeaderLinkHighlighting('');

  return (
    <div className={classes.CareerRoadmap} ref={setNode}>
      <h2 className={classes.title} ref={titleRef}>
        <span
          className={classNames(classes.animated, {
            [classes.inView]: shouldAnimate,
          })}
        >
          {t('pages.LandingPage.CareerRoadmap.title')}
        </span>
      </h2>
      <ul className={classes.employees}>
        {employees.map((employee, index) => (
          <Employee
            index={index}
            animationDelay={index + 1}
            key={employee.title}
            employee={employee}
            shouldAnimate={shouldAnimate}
          />
        ))}
      </ul>
    </div>
  );
}
