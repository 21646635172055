import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import BlackButton from '../../../../BlackButton';
import Button from '../../../../../../Button';

import classes from './styles.module.scss';
import CalendarButton from '../../../../../../CalendarButton';

const buttonStyle = { fontWeight: 500, fontSize: 24 };

export default function Buttons({
  interviewStatus,
  clearDeclineForm,
  declineReason,
  clearRescheduleForm,
  rescheduledDate,
  showRescheduleForm,
  rescheduledTime,
  submitReschedule,
  confirmReschedule,
  acceptInterviewSchedule,
  showDeclineForm,
  declineInterviewSchedule,
  interview,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const buttonWidth = isTabletOrMobile ? '' : 300;
  const buttonHeight = isTabletOrMobile ? 54 : 64;

  const defaultButtons = (
    <>
      <BlackButton
        height={buttonHeight}
        width={buttonWidth}
        onClick={showDeclineForm}
      >
        {t('common.decline')}
      </BlackButton>
      <Button
        style={buttonStyle}
        height={buttonHeight}
        width={buttonWidth}
        onClick={acceptInterviewSchedule}
      >
        {t('common.accept')}
      </Button>
    </>
  );

  const acceptedButtons = (
    <>
      <div className={classes.calendarButton}>
        <CalendarButton schedule={interview} scheduleType="Interview" />
      </div>
      <BlackButton
        height={buttonHeight}
        width={buttonWidth}
        onClick={showDeclineForm}
      >
        {t('common.decline')}
      </BlackButton>
      <Button
        style={buttonStyle}
        height={buttonHeight}
        width={buttonWidth}
        onClick={showRescheduleForm}
      >
        {t('common.reschedule')}
      </Button>
    </>
  );

  const declinedButtons = (
    <>
      <BlackButton
        height={buttonHeight}
        width={buttonWidth}
        onClick={clearDeclineForm}
      >
        {t('common.clear')}
      </BlackButton>
      <Button
        style={buttonStyle}
        height={buttonHeight}
        width={buttonWidth}
        onClick={declineInterviewSchedule}
        disabled={!declineReason}
      >
        {t('common.submit')}
      </Button>
    </>
  );

  const rescheduleButtons = (
    <>
      <BlackButton
        height={buttonHeight}
        width={buttonWidth}
        onClick={clearRescheduleForm}
      >
        {t('common.clear')}
      </BlackButton>
      <Button
        style={buttonStyle}
        height={buttonHeight}
        width={buttonWidth}
        onClick={submitReschedule}
        disabled={!rescheduledDate || !rescheduledTime}
      >
        {t('common.submit')}
      </Button>
    </>
  );

  const confirmRescheduleButtons = (
    <>
      <Button
        style={buttonStyle}
        height={buttonHeight}
        width={buttonWidth}
        onClick={confirmReschedule}
        disabled={!rescheduledDate || !rescheduledTime}
      >
        {t('common.confirm')}
      </Button>
    </>
  );

  return (
    <>
      {interviewStatus === 'unset' && defaultButtons}
      {interviewStatus === 'accepted' && acceptedButtons}
      {interviewStatus === 'declined' && declinedButtons}
      {interviewStatus === 'reschedule' && rescheduleButtons}
      {interviewStatus === 'confirmReschedule' && confirmRescheduleButtons}
    </>
  );
}
