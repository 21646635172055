import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function TextInput({
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  touched,
  error,
  type,
  value,
  height,
  noShadow,
  readOnly,
}) {
  return (
    <div
      className={classNames(classes.TextInput, {
        [classes.withType]: !!type,
        [classes.email]: type === 'email',
        [classes.phone]: type === 'phone',
        [classes.noShadow]: noShadow,
      })}
    >
      <label htmlFor={name}>{label}</label>
      <input
        style={{ height }}
        type="text"
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
}
