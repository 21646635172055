import axios from 'axios';

export default async function getPaginatedData({
  Term,
  PageNumber,
  PageSize = 10,
  OrderBy,
  url,
  ...rest
}) {
  const response = await axios.get(url, {
    params: {
      Term,
      PageNumber,
      PageSize,
      OrderBy,
      ...rest,
    },
  });

  let nextPage;
  let totalCount = response.data.length;

  if (response.headers['x-pagination']) {
    try {
      const xPagination = JSON.parse(response.headers['x-pagination']);
      if (xPagination.HasNext) {
        nextPage = xPagination.CurrentPage + 1;
      }
      totalCount = xPagination.TotalCount;
    } catch (error) {
      console.log(error);
    }
  }
  // return response.data;

  return { nextPage, totalCount, data: response.data };
}
