import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import FromMemberToManager from './FromMemberToManager';
import ApplyForJob from './ApplyForJob';

import classes from './styles.module.scss';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
import useOnScreen from '../../../hooks/useOnScreen';

export default function Future() {
  const [isTriggerVisible, setIsTriggerVisible] = useState(false);

  const triggerRef = useRef();

  const isIntersecting = useOnScreen(triggerRef, 0.4);

  useEffect(() => {
    if (isIntersecting) {
      setIsTriggerVisible(true);
    }
  }, [isIntersecting]);

  const { t } = useTranslation();

  const setNode = useHeaderLinkHighlighting('future-at-kfc');

  return (
    <div className={classes.Future} id="future-at-kfc" ref={setNode}>
      <h2 className={classes.title}>
        <span
          className={classNames(classes.animated, {
            [classes.inView]: isTriggerVisible,
          })}
        >
          {t('pages.LandingPage.Future.title')}
        </span>
      </h2>
      <div className={classes.container}>
        <FromMemberToManager animationDelay={1000} isVisible={isIntersecting} />
        <ApplyForJob animationTriggerRef={triggerRef} />
        <FromMemberToManager
          animationDelay={2000}
          isVisible={isIntersecting}
          variant="second"
        />
      </div>
    </div>
  );
}
