import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Event from './Event';

import classes from './styles.module.scss';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
import useOnScreen from '../../../hooks/useOnScreen';

export default function TimeLine() {
  const [shouldAnimateTimeline, setShouldAnimateTimeline] = useState(false);
  const [shouldAnimateTitle, setShouldAnimateTitle] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const timelineRef = useRef();
  const titleRef = useRef();
  const isIntersecting = useOnScreen(titleRef);

  useEffect(() => {
    if (isIntersecting) {
      setShouldAnimateTitle(true);
      setTimeout(() => {
        setShouldAnimateTimeline(true);
      }, 1300);
    }
  }, [isIntersecting]);

  const setNode = useHeaderLinkHighlighting('who-we-are');

  const events = [
    {
      year: 1952,
      title: t('pages.LandingPage.TimeLine.events.0.title'),
      subTitle: t('pages.LandingPage.TimeLine.events.0.subTitle'),
      text: t('pages.LandingPage.TimeLine.events.0.text'),
      bucketWidth: '',
      bucketHeight: 110,
      mobileBucketWidth: '',
      mobileBucketHeight: 68,
      leftPosition: 0,
      mobilLeftPosition: 0,
    },
    {
      year: 1982,
      title: t('pages.LandingPage.TimeLine.events.1.title'),
      subTitle: t('pages.LandingPage.TimeLine.events.1.subTitle'),
      text: t('pages.LandingPage.TimeLine.events.1.text'),
      bucketWidth: '',
      bucketHeight: 226,
      mobileBucketWidth: '',
      mobileBucketHeight: 121,
      leftPosition: 'calc((100% - 148px) / 2)',
      mobileLeftPosition: 385,
    },
    {
      year: 'TODAY',
      title: '',
      text: t('pages.LandingPage.TimeLine.events.1.text'),
      bucketWidth: '',
      bucketHeight: 361,
      mobileBucketWidth: '',
      mobileBucketHeight: 201,
      rightPosition: 148,
      mobileRightPosition: '',
      mobileLeftPosition: 770,
    },
  ];

  return (
    <div className={classes.TimeLine} id="who-we-are" ref={setNode}>
      <div className={classes.container}>
        <h2 className={classes.title} ref={titleRef}>
          <span
            className={classNames(classes.animation, {
              [classes.inView]: shouldAnimateTitle,
            })}
          >
            {t('pages.LandingPage.TimeLine.title')}
          </span>
        </h2>
        <div
          className={classNames(classes.timeline, {
            [classes.visible]: shouldAnimateTimeline,
          })}
          ref={timelineRef}
        >
          <div
            className={classNames(classes.progress, {
              [classes.visible]: shouldAnimateTimeline,
            })}
          />
          {events.map((event, index) => (
            <Event
              key={event.year}
              event={event}
              inView={isIntersecting}
              index={index}
            />
          ))}
        </div>
        <button
          className={classes.button}
          type="button"
          onClick={() => navigate('/jobs')}
        >
          {t('pages.LandingPage.TimeLine.Join our team')}
        </button>
      </div>
    </div>
  );
}
