import React from 'react';

import { useTranslation } from 'react-i18next';

import TimePeriodSelector from './TimePeriodSelector';
import Dropdown from './Dropdown';

import classes from './styles.module.scss';

export default function TimeDropdown({
  timeValue,
  setTimeValue,
  rescheduledTimePeriod,
  setRescheduledTimePeriod,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.TimeDropdown}>
      <span className={classes.label}>
        {t('components.TimeDropdown.timeLabel')}{' '}
      </span>
      <div className={classes.selectors}>
        <Dropdown value={timeValue} setValue={setTimeValue} />
        <TimePeriodSelector
          value={rescheduledTimePeriod}
          setValue={setRescheduledTimePeriod}
        />
      </div>
    </div>
  );
}
