import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { hasAuthData } from '../../helpers/authStorage';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const isAuthenticated = hasAuthData();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={`/?signin=true&redirectTo=${location.pathname + location.search}`}
    />
  );
}
