import React from 'react';

import classes from './styles.module.scss';
import pdfIcon from '../../../../../assets/images/profile/pdf.png';
import docIcon from '../../../../../assets/images/profile/doc.svg';

export default function File({ name }) {
  const extension = name.split('.').pop();

  const isDoc =
    extension?.toLowerCase() === 'doc' || extension?.toLowerCase() === 'docx';

  return (
    <div className={classes.File}>
      <img src={isDoc ? docIcon : pdfIcon} alt="file icon" />
      <h4 className={classes.name}>{name}</h4>
    </div>
  );
}
