/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Dropdown({
  value,
  placeholder,
  label,
  options,
  setFieldValue,
  name,
  height,
  withShadow,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div
      className={classNames(classes.Dropdown, {
        [classes.withShadow]: withShadow,
      })}
      ref={dropdownRef}
    >
      <h2
        className={classes.label}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {label}
      </h2>
      <div
        className={classNames(classes.selector, {
          [classes.open]: isOptionsListVisible,
        })}
        style={{ height }}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {value ? (
          <span className={classes.value}>
            {options.find((option) => option.value === value)?.label}
          </span>
        ) : (
          <span className={classes.placeholder}>{placeholder}</span>
        )}
      </div>
      {isOptionsListVisible && (
        <ul className={classes.options}>
          {options?.map((option) => (
            <li
              className={classNames({
                [classes.active]: value === option.value,
              })}
              key={option.value}
              onClick={() => {
                setFieldValue(name, option.value);
                setIsOptionsListVisible(false);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
