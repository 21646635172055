import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Button from '../../../../../../Button';
import BlackButton from '../../../../BlackButton';
import Dropdown from '../../../../../../Dropdown';

import PublicService from '../../../../../../../services/PublicService';
import classes from './styles.module.scss';

export default function DeclineOffer({
  showOffer,
  showCounterOfferForm,
  refetch,
  offer,
  jobId,
}) {
  const [declineReason, setDeclineReason] = useState('');

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const options = [
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.options.counterOffer'
      ),
      value: 'CounterOffer',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.options.differentExpectations'
      ),
      value: 'DifferentExpectations ',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.options.notInterested'
      ),
      value: 'NotInterested ',
    },
  ];

  const submitForm = async () => {
    if (declineReason === 'CounterOffer') {
      showCounterOfferForm();
    } else {
      try {
        await PublicService.declineOffer({
          jobApplicationId: jobId,
          offerId: offer.id,
          declineReason,
        });
        refetch();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className={classes.DeclineOffer}>
      <i className={classes.backButton} onClick={showOffer}>
        Back
      </i>
      <h3 className={classes.heading}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.heading'
        )}
      </h3>
      <div className={classes.dropdownContainer}>
        <p className={classes.subtitle}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.subtitle'
          )}
        </p>
        <Dropdown
          label={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.dropdownLabel'
          )}
          placeholder={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.DeclineOffer.dropdownPlaceholder'
          )}
          options={options}
          withShadow
          value={declineReason}
          name="declineReason"
          setFieldValue={(_name, value) => setDeclineReason(value)}
        />
      </div>
      <div className={classes.buttons}>
        <BlackButton
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={() => setDeclineReason('')}
        >
          {t('common.clear')}
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          jobApplicationInfo
          disabled={!declineReason}
          onClick={submitForm}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
