import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import DocModal from '../../../../modals/DocModal';
import ResumeHeader from '../ResumeHeader';
import File from './File';

import { UiContext } from '../../../../context/UiContext';
import classes from './styles.module.scss';

export default function WordResume({
  file,
  setFile,
  resume,
  isResumeDeleted,
  setIsResumeDeleted,
}) {
  const [isDocModalVisible, setIsDocModalVisible] = useState(false);

  const fileInputRef = useRef();

  const { showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const deleteResume = (event) => {
    event.stopPropagation();

    showModal({
      title: t('components.EditProfile.Resume.WordResume.deleteResumeTitle'),
      text: t(
        'components.EditProfile.Resume.WordResume.deleteResumeConfirmation'
      ),
      onConfirm: () => {
        setFile(null);
        setIsResumeDeleted(true);
        fileInputRef.current.value = '';
      },
    });
  };

  const handleFileDrop = (event) => {
    event.preventDefault();

    if (file || (resume && !isResumeDeleted)) {
      return;
    }

    if (event.dataTransfer.items) {
      const droppedEntity = event.dataTransfer?.items[0];
      if (
        droppedEntity.kind === 'file' &&
        (droppedEntity.type === 'application/msword' ||
          droppedEntity.type === 'application/pdf')
      ) {
        const droppedFile = droppedEntity.getAsFile();
        setFile(droppedFile);
        setIsResumeDeleted(false);
      }
    }
  };

  return (
    <>
      <div
        className={classes.WordResume}
        onClick={() => {
          if (resume && !file && !isResumeDeleted) {
            setIsDocModalVisible(true);
            return;
          }

          if (file || (resume && !isResumeDeleted)) {
            return;
          }
          fileInputRef.current.click();
        }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleFileDrop}
      >
        <ResumeHeader
          title={t('components.EditProfile.Resume.WordResume.resumeName')}
        />
        <div className={classes.uploader}>
          {(resume || file) && !isResumeDeleted ? (
            <File name={file?.name || resume?.fileName} />
          ) : (
            <p>
              <span className={classes.gray}>
                {t('components.EditProfile.Resume.WordResume.dragAndDrop')}
              </span>{' '}
              <span className={classes.red}>
                {t('components.EditProfile.Resume.WordResume.browse')}
              </span>{' '}
              ({t('components.EditProfile.Resume.WordResume.wordOrPDFOnly')})
            </p>
          )}
          {(!resume && !file) || isResumeDeleted ? (
            <button className={classes.addButton} type="button">
              +
            </button>
          ) : (
            <button
              className={classes.deleteButton}
              type="button"
              onClick={deleteResume}
            >
              -
            </button>
          )}
        </div>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          accept="application/msword, application/pdf"
          onChange={(event) => {
            setFile(event.target.files[0]);
            setIsResumeDeleted(false);
          }}
        />
      </div>
      <DocModal
        show={isDocModalVisible}
        handleClose={() => setIsDocModalVisible(false)}
        jobTitle={t(
          'components.EditProfile.Resume.WordResume.docModalJobTitle'
        )}
        doc={resume?.url}
      />
    </>
  );
}
