/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classes from './styles.module.scss';

export default function Checkbox({ label, value, onClick }) {
  return (
    <label className={classes.Checkbox}>
      {label}
      <input
        type="checkbox"
        checked={value}
        onChange={(event) => onClick(event.target.checked)}
      />
      <span className={classes.checkmark} />
    </label>
  );
}
