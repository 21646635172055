import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Tabs({ activeTab, setActiveTab, shouldAnimate }) {
  const [left, setLeft] = useState(0);
  const [animationHasPlayed, setAnimationHasPlayed] = useState(false);

  const containerRef = useRef(null);
  const tabRefs = useRef([]);

  const { t } = useTranslation();

  // When tab becomes active, we need to change left position of the tabs so active tab is centered
  useEffect(() => {
    if (containerRef.current && tabRefs.current[activeTab]) {
      const viewportWidth = window.innerWidth;
      const activeTabElement = tabRefs.current[activeTab];
      const activeTabRect = activeTabElement.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const activeTabCenter = activeTabRect.left + activeTabRect.width / 2;
      const containerLeft = containerRect.left;

      const newLeft = viewportWidth / 2 - activeTabCenter + containerLeft;
      setLeft(newLeft);
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldAnimate && !animationHasPlayed) {
      setTimeout(() => {
        setAnimationHasPlayed(true);
      }, 1200);
    }
  }, [animationHasPlayed, shouldAnimate]);

  const tabs = [
    t('pages.LandingPage.GivingBack.autism'),
    t('pages.LandingPage.GivingBack.volleyball'),
  ];

  return (
    <div
      className={classNames(classes.Tabs, {
        [classes.visible]: shouldAnimate,
        [classes.animationHasPlayed]: animationHasPlayed,
      })}
      style={{
        left,
      }}
      ref={containerRef}
    >
      {tabs.map((tab, index) => (
        <button
          ref={(el) => {
            tabRefs.current[index] = el;
          }}
          type="button"
          key={tab}
          onClick={() => setActiveTab(index)}
          className={classNames(classes.tabButton, {
            [classes.active]: activeTab === index,
          })}
        >
          {tab}
        </button>
      ))}
    </div>
  );
}
