import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import RescheduleWithButtons from '../../../RescheduleWithButtons';
import DeclineForm from '../../../DeclineForm';
import OnboardInfo from '../../../OnboardInfo';

import { UiContext } from '../../../../../../context/UiContext';
import {
  setTimezoneDate,
  setTimezoneTime,
} from '../../../../../../helpers/timezones';
import classes from './styles.module.scss';
import ConfirmRescheduleWithButtons from '../../../ConfirmRescheduleWithButtons';
import PublicService from '../../../../../../services/PublicService';

function twelveToTwentyFourHour(time, timePeriod) {
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (timePeriod === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export default function StartDateOrScheduled({
  schedule,
  startDateSet,
  refetch,
  jobApplicationId,
  accepted,
}) {
  const [mode, setMode] = useState('info');
  const [rescheduleArgs, setRescheduleArgs] = useState({});

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const cancelReasons = [
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelReasons.reschedule'
      ),
      value: 'Reschedule',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelReasons.notInterested'
      ),
      value: 'NotIntereseted',
    },
    {
      label: t(
        'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelReasons.anotherJob'
      ),
      value: 'AnotherJob',
    },
  ];

  const showCancelOnboardingForm = () => {
    setMode('cancel');
  };

  const showRescheduleForm = () => {
    setMode('reschedule');
  };

  const showConfirmRescheduleForm = (
    rescheduledDate,
    rescheduledTime,
    rescheduledTimePeriod
  ) => {
    setMode('confirmReschedule');
    setRescheduleArgs({
      rescheduledDate,
      rescheduledTime,
      rescheduledTimePeriod,
    });
  };

  const declineJoining = async (reason) => {
    try {
      setIsFetching(true);
      await PublicService.declineJoining({
        declineReason: reason,
        jobApplicationId,
        joiningId: schedule.id,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const declineOnboarding = async (reason) => {
    try {
      setIsFetching(true);
      await PublicService.declineOnboarding({
        declineReason: reason,
        jobApplicationId,
        onboardingId: schedule.id,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const rescheduledTime = rescheduleArgs.rescheduledTime
    ? twelveToTwentyFourHour(
        rescheduleArgs.rescheduledTime,
        rescheduleArgs.rescheduledTimePeriod
      )
    : null;

  const reschedule = async () => {
    try {
      setIsFetching(true);
      if (startDateSet) {
        await PublicService.declineJoining({
          status: 'ChangeRequest',
          jobApplicationId,
          joiningId: schedule.id,
          declineReason: 'Reschedule',
          suggestedJoiningDate: setTimezoneDate(
            rescheduleArgs.rescheduledDate,
            setTimezoneTime(rescheduledTime)
          ),
          suggestedStartTime: setTimezoneTime(rescheduledTime),
        });
      } else {
        await PublicService.declineOnboarding({
          status: 'ChangeRequest',
          jobApplicationId,
          onboardingId: schedule.id,
          declineReason: 'Reschedule',
          suggestedOnboardingDate: setTimezoneDate(
            rescheduleArgs.rescheduledDate,
            setTimezoneTime(rescheduledTime)
          ),
          suggestedStartTime: setTimezoneTime(rescheduledTime),
        });
      }

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.StartDateOrScheduled}>
      {mode === 'info' && (
        <OnboardInfo
          accepted={accepted}
          title={
            startDateSet ? (
              <>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.congratulationsJoiningTitlePart1'
                )}
                <br />
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.congratulationsJoiningTitlePart2'
                )}
              </>
            ) : (
              <>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.congratulationsOnboardingTitlePart1'
                )}
                <br />{' '}
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.congratulationsOnboardingTitlePart2'
                )}
              </>
            )
          }
          startDateSet={startDateSet}
          refetch={refetch}
          schedule={schedule}
          jobApplicationId={jobApplicationId}
          label={
            startDateSet
              ? t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.yourJoiningDateLabel'
                )
              : t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.weWillSeeYouOnLabel'
                )
          }
          showCancelForm={showCancelOnboardingForm}
        />
      )}
      {mode === 'cancel' && (
        <DeclineForm
          optionActions={[
            {
              value: 'Reschedule',
              action: showRescheduleForm,
            },
          ]}
          options={cancelReasons}
          placeholder={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelOnboardingPlaceholder'
          )}
          title={
            startDateSet
              ? t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelJoiningTitle'
                )
              : t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelOnboardingTitle'
                )
          }
          name="cancelOnboarding"
          subtitle={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelOnboardingSubtitle'
          )}
          label={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.cancelOnboardingLabel'
          )}
          onBackButtonClick={() => setMode('info')}
          startDateSet={startDateSet}
          onSubmit={startDateSet ? declineJoining : declineOnboarding}
        />
      )}
      {mode === 'reschedule' && (
        <RescheduleWithButtons
          title={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Onboard.StartDateOrScheduled.rescheduleTitle'
          )}
          onSubmit={showConfirmRescheduleForm}
          onBackButtonClick={showCancelOnboardingForm}
          refetch={refetch}
        />
      )}
      {mode === 'confirmReschedule' && (
        <ConfirmRescheduleWithButtons
          onBackButtonClick={showRescheduleForm}
          onConfirm={reschedule}
          date={schedule.joiningDate || schedule.onboardingDate}
          startTime={schedule.startTime}
          rescheduledDate={rescheduleArgs.rescheduledDate}
          rescheduledTime={rescheduledTime}
        />
      )}
    </div>
  );
}
