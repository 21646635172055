import React, { useState, useContext } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import BlackButton from '../../BlackButton';
import Button from '../../../../Button';
import Switch from './Switch';
import Rating from './Rating';

import { UiContext } from '../../../../../context/UiContext';
import PublicService from '../../../../../services/PublicService';
import classes from './styles.module.scss';
import Textarea from '../../../../Textarea';

export default function FeedbackForm({
  jobApplicationId,
  hideJobsApplicationInfo,
}) {
  const [rating, setRating] = useState(null);
  const [isRecommended, setIsRecommended] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const resetForm = () => {
    setRating(null);
    setIsRecommended(null);
    setFeedback('');
  };

  const sendFeedback = async () => {
    try {
      setIsFetching(true);
      await PublicService.createJobApplicationFeedback({
        feedbackNote: feedback,
        feedbackRating: rating !== null ? rating + 1 : null,
        isRecommended,
        jobApplicationId,
      });
      setIsFeedbackSent(true);
      hideJobsApplicationInfo();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div
      className={classNames(classes.FeedbackForm, {
        [classes.feedbackSent]: isFeedbackSent,
      })}
    >
      {!isFeedbackSent ? (
        <>
          <div className={classes.container}>
            <Rating
              label={t(
                'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.ratingLabel'
              )}
              value={rating}
              setValue={setRating}
            />
            <Switch
              label={t(
                'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.recommendLabel'
              )}
              value={isRecommended}
              setValue={setIsRecommended}
            />
            <Textarea
              info={t(
                'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.feedbackInfo'
              )}
              label={t(
                'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.feedbackLabel'
              )}
              placeholder={t(
                'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.feedbackPlaceholder'
              )}
              height={isTabletOrMobile ? 241 : 105}
              maxLength={2000}
              value={feedback}
              setValue={setFeedback}
            />
          </div>
          <div className={classes.buttons}>
            <BlackButton
              onClick={resetForm}
              width={isTabletOrMobile ? '' : 300}
              height={isTabletOrMobile ? 54 : 64}
            >
              {t('common.clear')}
            </BlackButton>
            <Button
              width={isTabletOrMobile ? '' : 300}
              height={isTabletOrMobile ? 54 : 64}
              style={{ fontWeight: 500, fontSize: 24 }}
              onClick={sendFeedback}
              disabled={isRecommended === null}
            >
              {t('common.submit')}
            </Button>
          </div>
        </>
      ) : (
        <div className={classes.success}>
          <p>
            {t(
              'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.thankYou'
            )}
          </p>
          <p>
            {t(
              'components.MyDashboard.JobApplicationInfo.Feedback.FeedbackForm.receivedFeedback'
            )}
          </p>
        </div>
      )}
    </div>
  );
}
