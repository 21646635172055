import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { QRCodeSVG as QRCode } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../components/Button';

import getResizedImageUrl from '../../helpers/getResizedImageUrl';
import PublicService from '../../services/PublicService';
import kfcQrLogo from '../../assets/images/qr-logo.svg';
import classes from './styles.module.scss';

export default function QrCodeModal({ show, handleClose, url, onSkip }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: PublicService.getTenantSettings,
  });

  const hasBrandLogo = !!data?.brand?.logo;

  const logoUrl = hasBrandLogo
    ? getResizedImageUrl({
        url: `brand/${data.brand.logo.split('/').pop()}`,
        width: 200,
        quality: 100,
      })
    : kfcQrLogo;

  const closeModalAndRedirectToVideoPage = () => {
    handleClose();

    if (!pathname.includes('video')) {
      navigate(`${pathname}/video`);
    }
  };

  let qrCodeLink = url;

  if (!qrCodeLink) {
    qrCodeLink = window.location.href.includes('video')
      ? window.location.href
      : `${window.location.href}/video`;
  }

  return (
    <Modal
      show={show}
      onHide={closeModalAndRedirectToVideoPage}
      centered
      className={classes.QrCodeModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <i
          className={classes.closeIcon}
          onClick={onSkip || closeModalAndRedirectToVideoPage}
        >
          Close
        </i>

        <h1>{t('modals.QrCodeModal.createWithYourPhone')}</h1>
        <p>
          {t('modals.QrCodeModal.ifYouPreferYouCanCreateTheVideo')}
          <br /> {t('modals.QrCodeModal.withYourMobileDevice')}
          <br /> {t('modals.QrCodeModal.justScanQRCodeToSwitchDevices')}
        </p>
        <div className={classes.qrCodeContainer}>
          <QRCode
            value={qrCodeLink}
            level="H"
            imageSettings={{
              src: logoUrl,
              width: 86,
              height: 39,
              excavate: hasBrandLogo,
            }}
            // logoWidth={86}
            // logoHeight={39}
            size={155}
          />
        </div>
        <Button
          onClick={onSkip || closeModalAndRedirectToVideoPage}
          width={444}
          height={64}
          style={{ fontSize: 24 }}
        >
          {t('modals.QrCodeModal.skip')}
        </Button>
      </div>
    </Modal>
  );
}
