import React, { useState, useEffect, useRef, useMemo } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import CustomEase from 'gsap/CustomEase';

import classes from './styles.module.scss';

gsap.registerPlugin(MotionPathPlugin, CustomEase);

export default function Arrow({ animationDelay, index }) {
  const [isArrowVisible, setIsArrowVisible] = useState(false);

  const animationRef = useRef();
  const targetRef = useRef();
  const target2Ref = useRef();
  const pathRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const animations = useMemo(
    () => [
      {
        arrowHeadEnd: 0.15,
        arrowHeadDuration: 0.96,
        fillEnd: isTabletOrMobile ? 220 : 223,
        fillDuration: isTabletOrMobile ? 1.3 : 1.35,
        fillDelayCorrection: 0.288,
        fillEase: 'circ.out',
        arrowAlignOrigin: isTabletOrMobile ? [0.4, 1] : [0.3, 1],
      },
      {
        arrowHeadEnd: 0.29,
        arrowHeadDuration: 0.96,
        fillEnd: 146,
        fillDuration: 1.015,
        fillDelayCorrection: 0.13,
        fillEase: CustomEase.create('customEase', 'M0,0 C0.25,0.1 0.25,1 1,1'),
        arrowAlignOrigin: isTabletOrMobile ? [0.5, 1] : [0.3, 1],
      },
      {
        arrowHeadEnd: 0.43,
        arrowHeadDuration: 0.46,
        fillEnd: 69,
        fillDuration: 2,
        fillDelayCorrection: 1.62,
        fillEase: 'circ.in',
        arrowAlignOrigin: [0.3, 1],
      },
    ],
    [isTabletOrMobile]
  );

  // Show arrow after animationDelay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsArrowVisible(true);
    }, animationDelay * 1000 + 800);

    return () => {
      clearTimeout(timeout);
    };
  }, [animationDelay]);

  useEffect(() => {
    gsap.set(targetRef.current, { visibility: 'hidden' });

    gsap.to(target2Ref.current, {
      duration: animations[index].fillDuration,
      delay: animationDelay - animations[index].fillDelayCorrection + 1.5,
      strokeDashoffset: animations[index].fillEnd,
      ease: animations[index].fillEase,
    });

    animationRef.current = gsap.to(targetRef.current, {
      duration: animations[index].arrowHeadDuration,
      delay: animationDelay + 0.1 + 1.5,
      motionPath: {
        path: pathRef.current,
        align: pathRef.current,
        alignOrigin: animations[index].arrowAlignOrigin,
        autoRotate: 95,
        start: 0.02,
        end: animations[index].arrowHeadEnd,
        // offsetY: -20,
        // offsetX: 10,
      },
      ease: CustomEase.create('customEase', 'M0,0 C0.25,0.1 0.25,1 1,1'),
      // ease: 'none',
      onStart: () => {
        gsap.set(targetRef.current, { visibility: 'visible' });
      },
    });
  }, [animationDelay, animations, index]);

  return (
    <div className={classes.Arrow}>
      <svg
        className={classes.arrowFill}
        width="284"
        height="181"
        viewBox="0 0 284 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            id="arc"
            fill="none"
            strokeWidth="1"
            opacity="1"
            d="M15.5246 0.246295C20.9677 1.46498 24.3919 6.86334 23.1726 12.3039C21.2381 20.9359 20.1998 29.9923 20.1998 39.261C20.1998 106.295 74.6315 160.701 141.698 160.701C208.768 160.701 263.196 106.367 263.196 39.261C263.196 29.9923 262.158 20.9359 260.223 12.3039C259.004 6.86334 262.428 1.46498 267.871 0.246295C273.315 -0.972386 278.716 2.45011 279.935 7.89065C282.19 17.9532 283.396 28.4892 283.396 39.261C283.396 117.525 219.917 180.891 141.698 180.891C63.4755 180.891 0 117.446 0 39.261C0 28.4892 1.20616 17.9532 3.46124 7.89065C4.68051 2.45011 10.0815 -0.972386 15.5246 0.246295Z"
          />
          <clipPath id="arrow-clip" clipPathUnits="userSpaceOnUse">
            <use xlinkHref="#arc" />
          </clipPath>
        </defs>

        <g clipPath="url(#arrow-clip)">
          <circle
            ref={target2Ref}
            cx="141"
            cy="105"
            r="70"
            // transform="rotate(-164,141,90.5)"
            transform="rotate(-164,141,70.5)"
            fill="none"
            stroke="white"
            strokeWidth="175"
            strokeDasharray="500 500"
            strokeDashoffset="500"
          >
            {/*  <animate
              attributeName="stroke-dashoffset"
              from="500"
              to={animationEnds[index].svg}
              //  begin={`${animationDelay - 0.49 + 1.5}s`}
              begin="indefinite"
              dur={animationEnds[index].svgDur}
              fill="freeze"
              repeatCount="1"
              keySplines="0.645, 0.045, 0.355, 1"
              keyTimes="0;1"
              calcMode="spline"
              ref={svgAnimationRef}
            /> */}
          </circle>
        </g>
        <use xlinkHref="#arrow" />
      </svg>

      <svg
        width="284"
        height="181"
        viewBox="0 0 284 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(classes.arrow, {
          [classes.visible]: isArrowVisible,
        })}
      >
        <path
          ref={pathRef}
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5246 0.246295C20.9677 1.46498 24.3919 6.86334 23.1726 12.3039C21.2381 20.9359 20.1998 29.9923 20.1998 39.261C20.1998 106.295 74.6315 160.701 141.698 160.701C208.768 160.701 263.196 106.367 263.196 39.261C263.196 29.9923 262.158 20.9359 260.223 12.3039C259.004 6.86334 262.428 1.46498 267.871 0.246295C273.315 -0.972386 278.716 2.45011 279.935 7.89065C282.19 17.9532 283.396 28.4892 283.396 39.261C283.396 117.525 219.917 180.891 141.698 180.891C63.4755 180.891 0 117.446 0 39.261C0 28.4892 1.20616 17.9532 3.46124 7.89065C4.68051 2.45011 10.0815 -0.972386 15.5246 0.246295Z"
          fill="white"
        />
      </svg>
      <svg
        className={classes.arrowHead}
        ref={targetRef}
        width="47"
        height="37"
        viewBox="0 0 47 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1006 1.31767C28.2682 3.92401 29.603 9.52962 27.0819 13.8381L16.5844 31.7785C14.0634 36.087 8.64119 37.4669 4.47364 34.8605C0.306082 32.2542 -1.02867 26.6486 1.49239 22.3401L11.9898 4.39976C14.5109 0.0912326 19.9331 -1.28867 24.1006 1.31767Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7191 4.29044C14.4564 -0.0388419 20.1418 -1.30166 24.4178 1.46986L42.208 13.0009C46.4839 15.7724 47.7312 21.5288 44.9938 25.8581C42.2564 30.1874 36.5711 31.4502 32.2951 28.6787L14.5049 17.1476C10.229 14.3761 8.98172 8.61973 11.7191 4.29044Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
