import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../../assets/images/logo.svg';
import { clearAuthData } from '../../../helpers/authStorage';
import defaultUserIcon from '../../../assets/images/default-user.jpg';
import classes from './styles.module.scss';

export default function UserMenu({ isVisible, setIsUserMenuVisible }) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const logout = () => {
    clearAuthData();
    queryClient.invalidateQueries();
    navigate('/', { replace: true });
  };

  const hideMenu = () => setIsUserMenuVisible(false);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isVisible]);

  return (
    <>
      <div
        className={classNames(classes.backdrop, {
          [classes.visible]: isVisible,
        })}
      />
      <div
        className={classNames(classes.UserMenu, {
          [classes.visible]: isVisible,
        })}
      >
        <header className={classes.header}>
          <img src={logo} alt="Logo" className={classes.logo} />
          <i className={classes.closeIcon} onClick={hideMenu}>
            Close
          </i>
          <img src={defaultUserIcon} className={classes.userPhoto} alt="User" />
        </header>
        <div className={classes.content}>
          <nav className={classes.navigation}>
            <ul className={classes.navList}>
              <li>
                <Link to="/dashboard" onClick={hideMenu}>
                  My Dashboard
                </Link>
              </li>
              <li>
                <Link to="/" onClick={hideMenu}>
                  My Resume
                </Link>
              </li>
              <li>
                <Link to="/edit-profile" onClick={hideMenu}>
                  Edit your profile
                </Link>
              </li>
              <li>
                <Link to="/" onClick={hideMenu}>
                  Settings and notifications
                </Link>
              </li>
              <li>
                <Link to="/" onClick={hideMenu}>
                  Contact and FaQs
                </Link>
              </li>
            </ul>
          </nav>
          <button
            type="button"
            onClick={logout}
            className={classes.logoutButton}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
}
