import { useState, useEffect } from 'react';

import { unstable_useBlocker as useUnstableBlocker } from 'react-router-dom';

export default function useBlocker(onBlock) {
  const [isBlocking, setIsBlocking] = useState(true);

  useUnstableBlocker((params) => {
    if (!isBlocking) {
      return false;
    }

    onBlock?.(params);

    return true;
  });

  useEffect(() => {
    return () => {
      setIsBlocking(false);
    };
  }, []);

  return setIsBlocking;
}
