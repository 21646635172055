import React from 'react';

import { useNavigate } from 'react-router-dom';
import ClampLines from 'react-clamp-lines';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function JobCard({ job }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={classes.JobCard}>
      <div className={classes.header}>
        <p className={classes.text}>
          <span className={classes.title}>{job.jobTitle}</span>
          <span className={classes.city}>{job.locationCity?.name}</span>
        </p>
      </div>
      <div className={classes.description}>
        <ClampLines
          text={job.jobDescription}
          lines={5}
          ellipsis="..."
          innerElement="p"
          buttons={false}
        />
      </div>
      <div className={classes.buttonContainer}>
        <button
          type="button"
          className={classes.button}
          onClick={() => navigate(`/jobs/${job.id}`)}
        >
          {t('common.apply')}
        </button>
      </div>
    </div>
  );
}
