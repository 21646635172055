/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';

import PlayButton from '../../PlayButton';

import classes from './styles.module.scss';

export default function Video({ video }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const [isImageVisible, setIsImageVisible] = useState(true);

  const videoRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const playVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
    setIsImageVisible(false);
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  return (
    <div draggable={false} className={classes.Video}>
      {(!isTabletOrMobile || !isPlaying) && (
        <div className={classes.playButton}>
          <PlayButton
            onPauseClick={pauseVideo}
            onClick={playVideo}
            isPlaying={isPlaying}
          />
        </div>
      )}
      {isImageVisible && (
        <>
          <img
            draggable={false}
            src={video.imageSrc}
            alt="Video preview"
            className={classes.image}
          />
          <div className={classes.text}>
            <h4 className={classes.title}>{video.title}</h4>
            <span className={classes.subTitle}>{video.subTitle}</span>
          </div>
        </>
      )}

      <video
        draggable={false}
        playsInline
        ref={videoRef}
        src={video.src}
        loop
        onClick={() => {
          if (isTabletOrMobile) {
            pauseVideo();
          }
        }}
      />
    </div>
  );
}
