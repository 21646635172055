import axios from 'axios';

import { AUTH_API_URL } from '../constants/main';
import { hasAuthData } from '../helpers/authStorage';

const UserService = {
  async requestOtp({ email }) {
    const response = await axios.post(
      `${AUTH_API_URL}/candidates/emailsignin`,
      {
        email,
      }
    );
    return response.data;
  },

  async loginByOtp({ email, code }) {
    const response = await axios.post(`${AUTH_API_URL}/otplogin`, {
      userName: email,
      code,
    });
    return response.data;
  },

  async getMe() {
    const isAuth = hasAuthData();

    if (!isAuth) {
      return null;
    }

    const response = await axios.get(`${AUTH_API_URL}/userinfo`);
    return response.data;
  },

  async updateMe({
    firstName,
    lastName,
    phone,
    email,
    address,
    city,
    postalCode,
    countryCode,
    birthDate,
    avatar,
    languageCode,
    timezone,
    jobTitle,
    userProfileGraduation,
    userProfileExperiences,
    isPrivate,
    userProfileFileResume,
    userProfileVideoResume,
    isFresher,
  }) {
    const response = await axios.put(`${AUTH_API_URL}/profile`, {
      firstName,
      lastName,
      phone,
      email,
      address,
      city,
      postalCode,
      countryCode,
      birthDate,
      avatar,
      languageCode,
      timezone,
      jobTitle,
      userProfileGraduation,
      userProfileExperiences,
      isPrivate,
      userProfileFileResume,
      userProfileVideoResume,
      isFresher,
    });
    return response.data;
  },

  async cancelAccount({ userId }) {
    const response = await axios.post(`${AUTH_API_URL}/cancelaccount`, {
      id: userId,
    });
    return response.data;
  },
};

export default UserService;
