/* eslint-disable no-unreachable */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import NavHeader from '../../components/NavHeader';
import AdditionalInfoForm from '../../components/RecordVideo/AdditionalInfoForm';
import DropOff from '../../components/DropOff';

import useBlocker from '../../hooks/useBlocker';
import { getStaticDate } from '../../helpers/timezones';
import useLogoutBlockedUser from '../../hooks/useLogoutBlockedUser';
import { UiContext } from '../../context/UiContext';
import PublicService from '../../services/PublicService';
import classes from './styles.module.scss';
import AnonymousService from '../../services/AnonymousService';
import useUpdateProfileFromApplication from '../../hooks/useUpdateProfileFromApplication';

export default function CompleteApplicationPage() {
  const [birthDate, setBirthDate] = useState(null);
  const [city, setCity] = useState(null);
  const [education, setEducation] = useState(null);
  const [hasWorkExperience, setHasWorkExperience] = useState();
  const [isFresher, setIsFresher] = useState(false);
  const [jobs, setJobs] = useState([
    {
      id: Math.random(),
      jobTitle: '',
      companyName: '',
      startDate: '',
      endDate: '',
    },
  ]);
  const [attachment, setAttachment] = useState(null);
  const [isDropOffVisible, setIsDropOffVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const draftJobApplicationId = useRef();

  const {
    shouldUsePreviousResume,
    previousResume,
    isUserProfileFilled,
    setIsFetching,
  } = useContext(UiContext);

  const navigate = useNavigate();
  const { jobId } = useParams();

  const [search] = useSearchParams();
  const invitedJobApplicationId = search.get('jobApplicationId');

  const { checkAndLogout } = useLogoutBlockedUser();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: job } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => AnonymousService.getJobById({ jobId }),
    enabled: !!jobId,
  });

  const updateUserProfileFromJobApplication = useUpdateProfileFromApplication(
    attachment,
    getStaticDate(birthDate),
    city,
    education,
    jobs,
    isFresher,
    job
  );

  const setIsBlocking = useBlocker(() => {
    setIsDropOffVisible(true);
    setIsBlocking(false);
  });

  // Hide drop off form on page unload
  useEffect(() => {
    return () => {
      setIsDropOffVisible(false);
    };
  }, []);

  // Disable blocker after has submitted application
  useEffect(() => {
    if (isSubmitted) {
      setIsBlocking(false);
    }
  }, [isSubmitted, setIsBlocking]);

  // Create draft job application on page load if not invited
  useEffect(() => {
    const createDraftJobApplication = async () => {
      try {
        return await PublicService.createJobApplication({
          jobPostId: jobId,
          status: 'Draft',
        });
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    if (jobId && !invitedJobApplicationId) {
      createDraftJobApplication().then((jobApp) => {
        draftJobApplicationId.current = jobApp?.id;
      });
    }
  }, [jobId, invitedJobApplicationId]);

  // Record job application start to the backend
  useEffect(() => {
    const startApplication = async () => {
      try {
        await PublicService.startJobApplication({
          jobId,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (jobId) {
      startApplication();
    }
  }, [jobId]);

  useEffect(() => {
    if (shouldUsePreviousResume && previousResume?.jobId === jobId) {
      if (previousResume.type === 'doc') {
        setAttachment(previousResume.doc);
      }
    }
  }, [jobId, previousResume, shouldUsePreviousResume]);

  useEffect(() => {
    if (isUserProfileFilled === false) {
      navigate('/', { replace: true });
    }
  }, [isUserProfileFilled, navigate]);

  const submitAdditionalForm = async () => {
    try {
      setIsFetching(true);

      // Check if user is blocked, if so, logout
      await checkAndLogout();

      const jobApplicationId =
        invitedJobApplicationId || draftJobApplicationId.current;

      if (hasWorkExperience) {
        // eslint-disable-next-line no-shadow
        const promises = jobs.map((job) => {
          return PublicService.createJobApplicationExp({
            jobApplicationId,
            jobTitle: job.jobTitle,
            company: job.companyName,
            startDate: job.startDate,
            endDate: job.endDate,
            isCurrent: job.isCurrent,
          });
        });
        await Promise.all(promises);
      }

      if (attachment) {
        await PublicService.uploadJobApplicationAttach({
          file: attachment,
          jobApplicationId,
        });
      }

      await PublicService.updateJobApplication({
        jobApplicationId,
        graduationId: education.value,
        applicantLocation: city,
        birthDate: getStaticDate(birthDate),
        jobPostId: jobId,
        status: 'New',
      });

      if (invitedJobApplicationId) {
        await PublicService.acceptJobInvite({
          jobApplicationId,
        });
      }

      setIsSubmitted(true);

      if (!invitedJobApplicationId) {
        await PublicService.finishedJobApplication({
          jobId,
        });
      }

      updateUserProfileFromJobApplication();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.CompleteApplicationPage}>
      <div className={classes.header}>
        <NavHeader top={isTabletOrMobile ? 19 : 34} />
      </div>
      <div className={classes.container}>
        <AdditionalInfoForm
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          city={city}
          setCity={setCity}
          education={education}
          setEducation={setEducation}
          hasWorkExperience={hasWorkExperience}
          setHasWorkExperience={setHasWorkExperience}
          isFresher={isFresher}
          setIsFresher={setIsFresher}
          jobs={jobs}
          setJobs={setJobs}
          submitAdditionalForm={submitAdditionalForm}
          file={attachment}
          setFile={setAttachment}
          isFileRequired={
            job?.applicationType === 'DocumentOnly' ||
            job?.applicationType === 'VideoAndDocument' ||
            job?.applicationType === 'VideoOrDocument'
          }
        />
      </div>
      {isDropOffVisible && (
        <DropOff
          show={isDropOffVisible}
          handleClose={() => {
            setIsDropOffVisible(false);
          }}
          jobApplicationId={draftJobApplicationId.current}
          jobId={jobId}
          hasApplied={isSubmitted}
        />
      )}
    </div>
  );
}
