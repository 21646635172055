/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import educationIcon from '../../../../assets/images/education.svg';
import DropdownSelector from '../DropdownSelector';
import classes from './styles.module.scss';
import AnonymousService from '../../../../services/AnonymousService';

export default function EducationDropdown({ education, setEducation }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const optionsRef = useRef();

  useOnClickOutside(optionsRef, () => setIsOptionsListVisible(false));

  const { t } = useTranslation();

  const { data: references } = useQuery({
    queryKey: ['references'],
    queryFn: AnonymousService.getReferences,
  });

  const currentOption = (
    <div className={classes.currentOption}>{education?.label}</div>
  );

  const changeOption = (option) => {
    setEducation(option);
    setIsOptionsListVisible(false);
  };

  return (
    <DropdownSelector
      title={t(
        'components.RecordVideo.AdditionalInfoForm.EducationDropdown.education'
      )}
      icon={educationIcon}
      isOptionsListVisible={isOptionsListVisible}
      toggleOptions={() => setIsOptionsListVisible((prevState) => !prevState)}
      currentOption={currentOption}
      value={education?.label}
    >
      <div
        className={classNames(classes.options, {
          [classes.hasOption]: !!education,
        })}
        ref={optionsRef}
      >
        <header
          className={classes.header}
          onClick={() => setIsOptionsListVisible(false)}
        >
          <span className={classes.title}>
            {t(
              'components.RecordVideo.AdditionalInfoForm.EducationDropdown.education'
            )}
          </span>
        </header>
        <ul>
          {references?.graduations?.map((grad) => {
            return (
              <li
                key={grad.id}
                className={classNames({
                  [classes.active]: education?.value === grad.id,
                })}
                onClick={() => {
                  changeOption({ label: grad.name, value: grad.id });
                }}
              >
                {grad.name}
              </li>
            );
          })}
        </ul>
      </div>
    </DropdownSelector>
  );
}
