import React from 'react';

import { useTranslation } from 'react-i18next';

import tooShortIcon from '../../../../assets/images/too-short.svg';
import classes from './styles.module.scss';

export default function VideoShortWindow({ handleClose, retakeVideo }) {
  const { t } = useTranslation();

  return (
    <div className={classes.VideoShortWindow}>
      <div className={classes.content}>
        <img src={tooShortIcon} alt="Icon" />
        <h1>{t('modals.FinishRecordModal.tooShort')}</h1>

        <p className={classes.mainText}>
          {t('modals.FinishRecordModal.recordingTooShort')}
        </p>

        <div className={classes.buttons}>
          <button
            className={classes.reviewButton}
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            {t('modals.FinishRecordModal.review')}
          </button>

          <button
            className={classes.nextButton}
            type="button"
            onClick={() => {
              retakeVideo();
              handleClose();
            }}
          >
            {t('modals.FinishRecordModal.retake')}
          </button>
        </div>
      </div>
    </div>
  );
}
