import React from 'react';

import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { MAP_KEY } from '../../../../constants/main';
import classes from './styles.module.scss';

const libraries = [];

export default function Map({ coords, locationName, cityName }) {
  const { t } = useTranslation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });

  return (
    <div className={classes.Map}>
      <div className={classes.city}>
        {coords
          ? cityName
          : t('components.MyDashboard.JobApplicationInfo.Map.city')}
      </div>
      {isLoaded && (
        <GoogleMap
          zoom={16}
          libraries
          center={coords || { lat: 41.0108, lng: 28.968 }}
          onClick={() => {}}
          mapContainerClassName={classes.map}
          options={{
            draggable: false,
            scrollwheel: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: {
              position: -1,
            },
          }}
        >
          {coords && (
            <Marker
              key={locationName || 'Location'}
              position={coords}
              // label={{
              //   text: `${marker.jobCount}`,
              //   color: 'white',
              //   fontFamily: 'Gilroy-Bold',
              //   fontSize: '18px',
              // }}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
}
