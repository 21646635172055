import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';

import classes from './styles.module.scss';

export default function ReadyToGo({ questions, onClose }) {
  const { t } = useTranslation();

  return (
    <div className={classes.ReadyToGo}>
      <div className={classes.content}>
        <h1>{t('components.RecordVideo.MobileScreens.ReadyToGo.readyToGo')}</h1>
        <p className={classes.info}>
          {t('components.RecordVideo.MobileScreens.ReadyToGo.info.0')}
          <br /> {t(
            'components.RecordVideo.MobileScreens.ReadyToGo.info.1'
          )}{' '}
          <span className={classes.red}>
            {t('components.RecordVideo.MobileScreens.ReadyToGo.info.2')}{' '}
            {questions.length}{' '}
            {t('components.RecordVideo.MobileScreens.ReadyToGo.info.3')}
          </span>{' '}
          {t('components.RecordVideo.MobileScreens.ReadyToGo.info.4')}
        </p>
        <ul className={classes.videoSectionsList}>
          {questions.map((question, index) => {
            return (
              <li key={question.id}>
                <span>
                  {index + 1}. {question.title}
                </span>
                <span className={classes.duration}>
                  {question.duration}{' '}
                  {t('components.RecordVideo.MobileScreens.ReadyToGo.sec')}
                </span>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          style={{ fontSize: 22, borderRadius: 10 }}
          onClick={onClose}
          height={58}
        >
          {t('components.RecordVideo.MobileScreens.ReadyToGo.letsGetStarted')}
        </Button>
      </div>
    </div>
  );
}
