import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import getResizedImageUrl from '../../../helpers/getResizedImageUrl';
import UserService from '../../../services/UserService';
import classes from './styles.module.scss';

export default function UserBlock() {
  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { t } = useTranslation();

  return (
    <div className={classes.UserBlock}>
      {user?.userProfile.avatar && (
        <img
          src={getResizedImageUrl({
            url: `avatar/${user.userProfile.avatar}`,
            width: 180,
          })}
          alt="User"
          className={classes.userPhoto}
        />
      )}
      <h2 className={classes.userName}>
        {t('components.MyDashboard.JobApplicationInfo.greeting')}{' '}
        {user?.userProfile.firstName}!
      </h2>
    </div>
  );
}
