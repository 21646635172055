import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Feedback from '../../../Feedback';
import MessageWithButton from '../../../MessageWithButton';
import ConfirmReschedule from '../../../ConfirmReschedule';
import Title from './Title';
import RescheduleInterview from '../../../Reschedule';
import DeclineInterview from './DeclineInterview';
import Buttons from './Buttons';
import InterviewDetails from '../../../ScheduleDetails';

import {
  setTimezoneTime,
  setTimezoneDate,
} from '../../../../../../helpers/timezones';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import ContactUs from '../../../ContactUs';
import PublicService from '../../../../../../services/PublicService';

function twelveToTwentyFourHour(time, timePeriod) {
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (timePeriod === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export default function SetInterview({ interview, jobApplicationId, refetch }) {
  const [interviewStatus, setInterviewStatus] = useState('unset');
  const [declineReason, setDeclineReason] = useState('');
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const [rescheduledTime, setRescheduledTime] = useState(null);
  const [rescheduledTimePeriod, setRescheduledTimePeriod] = useState('PM');

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const goBack = () => {
    setInterviewStatus('unset');
  };

  const showRescheduleForm = () => {
    setInterviewStatus('reschedule');
  };

  const clearDeclineForm = () => {
    setDeclineReason('');
  };

  const clearRescheduleForm = () => {
    setRescheduledDate(null);
    setRescheduledTime(null);
    setRescheduledTimePeriod('PM');
  };

  const submitReschedule = () => {
    setInterviewStatus('confirmReschedule');
  };

  const confirmReschedule = async () => {
    try {
      setIsFetching(true);
      await PublicService.declineInterviewSchedule({
        jobApplicationId,
        interviewId: interview.id,
        declineReason: 'Reschedule',
        status: 'ChangeRequest',
        suggestedInterviewDate: setTimezoneDate(
          rescheduledDate,
          twelveToTwentyFourHour(rescheduledTime, rescheduledTimePeriod)
        ),
        suggestedStartTime: setTimezoneTime(
          twelveToTwentyFourHour(rescheduledTime, rescheduledTimePeriod)
        ),
      });

      if (refetch) {
        await refetch();
      }

      setInterviewStatus('rescheduled');
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const showFeedbackForm = () => {
    setInterviewStatus('feedback');
  };

  const showContactForm = () => {
    setInterviewStatus('contact');
  };

  const showDeclineForm = () => {
    setInterviewStatus('declined');
  };

  const acceptInterviewSchedule = async () => {
    try {
      setIsFetching(true);

      await PublicService.acceptInterviewSchedule({
        jobApplicationId,
        interviewId: interview.id,
      });
      if (refetch) {
        await refetch();
      }
      setInterviewStatus('accepted');
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const declineInterviewSchedule = async () => {
    try {
      if (declineReason === 'Reschedule') {
        showRescheduleForm();
        return;
      }

      setIsFetching(true);

      await PublicService.declineInterviewSchedule({
        jobApplicationId,
        interviewId: interview.id,
        declineReason,
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (interview?.status === 'Accepted') {
      setInterviewStatus('accepted');
    }
  }, [interview]);

  return (
    <div
      className={classNames(classes.SetInterview, {
        [classes.decline]: interviewStatus === 'declined',
      })}
    >
      {interviewStatus !== 'unset' &&
        interviewStatus !== 'accepted' &&
        interviewStatus !== 'reschedule' && (
          <i className={classes.backButton} onClick={goBack}>
            Back
          </i>
        )}

      <Title interviewStatus={interviewStatus} />

      {(interviewStatus === 'unset' || interviewStatus === 'accepted') && (
        <InterviewDetails
          schedule={interview}
          label={t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.interviewDetails'
          )}
        />
      )}
      {interviewStatus === 'declined' && (
        <DeclineInterview
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
        />
      )}
      {interviewStatus === 'reschedule' && (
        <RescheduleInterview
          rescheduledDate={rescheduledDate}
          setRescheduledDate={setRescheduledDate}
          rescheduledTime={rescheduledTime}
          setRescheduledTime={setRescheduledTime}
          rescheduledTimePeriod={rescheduledTimePeriod}
          setRescheduledTimePeriod={setRescheduledTimePeriod}
          onBackButtonClick={() => setInterviewStatus('declined')}
        />
      )}
      {interviewStatus === 'confirmReschedule' && (
        <ConfirmReschedule
          date={interview?.interviewDate}
          startTime={interview?.startTime}
          rescheduledDate={rescheduledDate}
          rescheduledTime={twelveToTwentyFourHour(
            rescheduledTime,
            rescheduledTimePeriod
          )}
        />
      )}
      {interviewStatus === 'rescheduled' && (
        <MessageWithButton
          message={
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.message.0'
              )}
              <br /> <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Interview.SetInterview.message.1'
              )}
            </p>
          }
          buttonLabel="Share Feedback"
          onButtonClick={showFeedbackForm}
          secondaryButtonLabel="Contact Us"
          onSecondaryButtonClick={showContactForm}
        />
      )}
      {interviewStatus === 'feedback' && (
        <Feedback jobApplicationId={jobApplicationId} />
      )}
      {interviewStatus === 'contact' && (
        <ContactUs jobApplicationId={jobApplicationId} />
      )}

      <div className={classes.buttons}>
        <Buttons
          interview={interview}
          acceptInterviewSchedule={acceptInterviewSchedule}
          interviewStatus={interviewStatus}
          clearDeclineForm={clearDeclineForm}
          declineReason={declineReason}
          clearRescheduleForm={clearRescheduleForm}
          rescheduledDate={rescheduledDate}
          showRescheduleForm={showRescheduleForm}
          rescheduledTime={rescheduledTime}
          submitReschedule={submitReschedule}
          confirmReschedule={confirmReschedule}
          showDeclineForm={showDeclineForm}
          declineInterviewSchedule={declineInterviewSchedule}
        />
      </div>
    </div>
  );
}
