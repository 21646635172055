import React from 'react';

import Title from './Title';
import Subtitle from './Subtitle';
import MainContent from './MainContent';

export default function Text({
  currentStep,
  steps,
  isVideoRecording,
  isVideoRecordingPaused,
  hasVideo,
}) {
  return (
    <div>
      <Title step={steps[currentStep - 1]} />
      <Subtitle step={steps[currentStep - 1]} />
      <MainContent
        step={steps[currentStep - 1]}
        isVideoRecording={isVideoRecording}
        isVideoRecordingPaused={isVideoRecordingPaused}
        hasVideo={hasVideo}
      />
    </div>
  );
}
