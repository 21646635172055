import React, { useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import anonOne from '../../../../assets/images/landing/employees/anon-1.png';
import anonTwo from '../../../../assets/images/landing/employees/anon-2.png';

export default function ApplyForJob({ animationTriggerRef }) {
  const [isEmplyoeeOneHovered, setIsEmplyoeeOneHovered] = useState(true);
  const [isEmplyoeeTwoHovered, setIsEmplyoeeTwoHovered] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={classes.ApplyForJob}>
      <div className={classes.images}>
        <img
          ref={animationTriggerRef}
          onMouseEnter={() => {
            setIsEmplyoeeOneHovered(true);
            setIsEmplyoeeTwoHovered(false);
          }}
          className={classNames(classes.employeeOne, {
            [classes.hovered]: isEmplyoeeOneHovered,
          })}
          src={anonOne}
          alt="Employee"
        />
        <img
          onMouseEnter={() => {
            setIsEmplyoeeOneHovered(false);
            setIsEmplyoeeTwoHovered(true);
          }}
          className={classNames(classes.employeeTwo, {
            [classes.hovered]: isEmplyoeeTwoHovered,
          })}
          src={anonTwo}
          alt="Employee"
        />
      </div>
      <button
        className={classes.button}
        onClick={() => navigate('/jobs')}
        type="button"
      >
        {t('pages.LandingPage.Future.ApplyForJob.text')}
      </button>
    </div>
  );
}
