import React from 'react';

import { useTranslation } from 'react-i18next';

import Action from './Action';

import classes from './styles.module.scss';

/* const actions = [
  {
    title: 'Offer',
    date: '13th Nov 2022 08:30 AM till 09:00 AM',
    text: 'Interview with Stuart',
  },
  {
    title: 'Interview',
    date: 'Today 08:30 AM till 09:00 AM',
    text: 'Interview for Team Member',
  },
]; */

const actions = [];

export default function Actions() {
  const { t } = useTranslation();

  return (
    <div className={classes.Actions}>
      <header className={classes.header}>
        <h2>{t('components.MyDashboard.Actions.title')}</h2>
        <div className={classes.count}>{actions.length}</div>
      </header>
      <div className={classes.actions}>
        {actions.length === 0 ? (
          <div className={classes.noActions}>
            <span>{t('components.MyDashboard.Actions.noData')}</span>
          </div>
        ) : (
          actions.map((action) => {
            return (
              <Action
                key={action.date}
                title={action.title}
                date={action.date}
                text={action.text}
              />
            );
          })
        )}
      </div>
    </div>
  );
}
