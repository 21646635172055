import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function TimePeriodSelector({ value, setValue }) {
  return (
    <div className={classes.TimePeriodSelector}>
      <button
        onClick={() => setValue('AM')}
        type="button"
        className={classNames({
          [classes.active]: value === 'AM',
        })}
      >
        AM
      </button>
      <button
        onClick={() => setValue('PM')}
        type="button"
        className={classNames({
          [classes.active]: value === 'PM',
        })}
      >
        PM
      </button>
    </div>
  );
}
