import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function AssessmentCard({
  assessment,
  selected,
  setSelectedAssessment,
  result,
}) {
  const { t } = useTranslation();

  const flowQDetails = assessment?.flowQDetails;

  let statusText = t(
    'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.Status.notStarted'
  );
  let assessmentIsExpired = false;
  let assessmentIsCompleted = flowQDetails?.performedAt;
  const assessmentExpiresAt = flowQDetails?.expiresAt || assessment.expiresAt;

  let date;

  if (flowQDetails) {
    date = assessmentIsCompleted
      ? moment(flowQDetails.performedAt)
      : moment(flowQDetails?.createdAt);
  } else {
    date = moment(assessment.createdAt);
  }

  const daysLeft = moment(assessmentExpiresAt).diff(moment(), 'days', true);

  if (daysLeft <= 0) {
    statusText = t(
      'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.Status.expired'
    );
    assessmentIsExpired = true;
  }

  if (
    (result && (result.status === 'Passed' || result.status === 'Completed')) ||
    flowQDetails?.performedAt ||
    assessment?.performedAt
  ) {
    statusText = t(
      'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.Status.completed'
    );
    assessmentIsCompleted = true;
  }

  const handleOnClick = () => {
    if (assessmentIsExpired || assessmentIsCompleted) {
      return;
    }

    if (selected) {
      setSelectedAssessment(null);

      return;
    }
    setSelectedAssessment(assessment);
  };

  let name = assessment?.name;

  if (!name && assessment.assessmentProviderKey === 'zaphire') {
    name = 'Zaphire Assessment';
  }

  return (
    <div
      className={classNames(classes.AssessmentCard, {
        [classes.selected]: selected,
      })}
      onClick={handleOnClick}
    >
      <div className={classes.col}>
        <div className={classes.label}>
          {name || assessment.assessment?.name}
        </div>
        <div className={classes.value}>
          {assessmentIsCompleted
            ? t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.completed'
              )
            : t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.assigned'
              )}{' '}
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.on'
          )}{' '}
          {date.format('DD-MMM-YYYY')}
        </div>
      </div>

      <div
        className={classes.col}
        style={{
          visibility:
            assessmentIsExpired || assessmentIsCompleted ? 'hidden' : 'visible',
        }}
      >
        <div className={classes.label}>
          {daysLeft > 0 ? Math.min(Math.ceil(daysLeft), assessment.limit) : 0}
        </div>
        <div className={classes.value}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.AssessmentCard.daysLeft'
          )}
        </div>
      </div>

      <div className={classes.col}>
        <div
          className={classNames(classes.status, {
            [classes.completed]: assessmentIsCompleted,
            [classes.expired]: assessmentIsExpired,
            [classes.resume]: false,
          })}
        >
          {statusText}
        </div>
      </div>
    </div>
  );
}
