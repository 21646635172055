import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import smile from '../../../../../../assets/images/smile.svg';

export default function Selfie() {
  const { t } = useTranslation();

  return (
    <div className={classes.Selfie}>
      <p>{t('components.RecordVideo.RecordVideoControls.selfie.0')}</p>
      <img src={smile} alt="Smile" />
      <p>{t('components.RecordVideo.RecordVideoControls.selfie.1')}</p>
      <p>{t('components.RecordVideo.RecordVideoControls.selfie.2')}</p>
    </div>
  );
}
