import React, { useState } from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DocModal from '../../DocModal';

import classes from './styles.module.scss';

export default function DocResume({ doc, onSelect, createdAt }) {
  const [isDocModalVisible, setIsDocModalVisible] = useState(false);

  const { t } = useTranslation();

  const isPdf = doc?.mimeType === 'application/pdf';

  return (
    <div className={classes.DocResume}>
      {doc && (
        <span className={classes.createdAt}>
          {t('modals.PreviousJobApplicationModal.DocResume.created')}{' '}
          {moment(createdAt).format('Do MMM YYYY')}
        </span>
      )}
      <h2>
        {t('modals.PreviousJobApplicationModal.DocResume.wordResumeBased')}
      </h2>
      <div className={classes.docContainer}>
        {doc ? (
          <div
            className={classNames(classes.doc, {
              [classes.pdf]: isPdf,
            })}
            onClick={onSelect}
          >
            <span className={classes.fileName}>{doc.fileName}</span>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setIsDocModalVisible(true);
              }}
            >
              {t('modals.PreviousJobApplicationModal.DocResume.view')}
            </button>
          </div>
        ) : (
          <div className={classes.noResume}>
            <p>
              {t(
                'modals.PreviousJobApplicationModal.DocResume.noApplicationPresent'
              )}
            </p>
          </div>
        )}
      </div>
      <DocModal
        show={isDocModalVisible}
        handleClose={() => setIsDocModalVisible(false)}
        doc={doc?.url}
      />
    </div>
  );
}
