import { useEffect } from 'react';

import useUiContext from './useUiContext';

import useIntersectionObserver from './useIntersectionObserver';

export default function useHeaderLinkHighlighting(sectionName) {
  const { setCurrentLandingSection } = useUiContext();

  const [setNode, entry] = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (entry.isIntersecting) {
      setCurrentLandingSection(sectionName);
    }
  }, [entry.isIntersecting, sectionName, setCurrentLandingSection]);

  return setNode;
}
