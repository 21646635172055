import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Star({
  currentValue,
  setCurrentValue,
  index,
  setValue,
  setIsHovered,
}) {
  const changeCurrentValue = () => {
    setIsHovered(true);
    setCurrentValue(index);
  };

  const showValue = () => {
    setIsHovered(false);
  };

  const changeValue = () => {
    setValue(index);
  };

  return (
    <div
      className={classNames(classes.Star, {
        [classes.active]: currentValue !== null && index <= currentValue,
        [classes.red]: index < 2 && currentValue < 2,
      })}
      onMouseEnter={changeCurrentValue}
      onMouseLeave={showValue}
      onClick={changeValue}
    />
  );
}
