import React from 'react';

import { useTranslation } from 'react-i18next';

import FilterButton from '../../FilterButton';
import JobsSearch from '../../JobsSearch';

import classes from './styles.module.scss';

export default function JobsControls({
  sortOrder,
  setSortOrder,
  setSearchTerm,
  jobApplicationCount,
  currentJobApplicationsFilter,
  setCurrentJobApplicationsFilter,
  invitedJobApplicationCount,
  actionsJobApplicationCount,
  isFetched,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.JobsControls}>
      <div className={classes.filters}>
        <div className={classes.filterButtonContainer}>
          <FilterButton
            setCurrentFilter={() =>
              setCurrentJobApplicationsFilter(t('common.myJobsFilters.Applied'))
            }
            filter={{
              title: t('common.myJobsFilters.Applied'),
              count: jobApplicationCount || 0,
            }}
            isActive={
              currentJobApplicationsFilter === t('common.myJobsFilters.Applied')
            }
          />
        </div>
        {(invitedJobApplicationCount || !isFetched) && (
          <div className={classes.filterButtonContainer}>
            <FilterButton
              setCurrentFilter={() =>
                setCurrentJobApplicationsFilter(
                  t('common.myJobsFilters.Invited')
                )
              }
              filter={{
                title: t('common.myJobsFilters.Invited'),
                count: invitedJobApplicationCount || 0,
              }}
              isActive={
                currentJobApplicationsFilter ===
                t('common.myJobsFilters.Invited')
              }
            />
          </div>
        )}
        {(actionsJobApplicationCount || !isFetched) && (
          <div className={classes.filterButtonContainer}>
            <FilterButton
              setCurrentFilter={() =>
                setCurrentJobApplicationsFilter(
                  t('common.myJobsFilters.Actions')
                )
              }
              filter={{
                title: t('common.myJobsFilters.Actions'),
                count: actionsJobApplicationCount || 0,
              }}
              isActive={
                currentJobApplicationsFilter ===
                t('common.myJobsFilters.Actions')
              }
            />
          </div>
        )}
      </div>
      <div className={classes.searchContainer}>
        <JobsSearch
          style={{ paddingLeft: 0, paddingRight: 0 }}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSearchTerm={setSearchTerm}
          noLocation
        />
      </div>
    </div>
  );
}
