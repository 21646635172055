import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Switch({ label, setValue, value }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Switch}>
      <h3 className={classes.label}>{label}</h3>
      <div className={classes.buttons}>
        <button
          type="button"
          onClick={() => setValue(true)}
          className={classNames({
            [classes.active]: value === true,
          })}
        >
          <span>{t('common.yes')}</span>
        </button>
        <button
          type="button"
          className={classNames(classes.no, {
            [classes.active]: value === false,
          })}
          onClick={() => setValue(false)}
        >
          <span>{t('common.no')}</span>
        </button>
      </div>
    </div>
  );
}
