import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import cup from '../../../../../assets/images/cup.svg';

export default function Subtitle({ step }) {
  const { t } = useTranslation();

  let subtitle = '';

  if (step?.name === 'Picture') {
    subtitle = t('components.RecordVideo.RecordVideoControls.startWithASmile');
  } else if (step?.name === 'Review & Upload') {
    subtitle = <img src={cup} alt="Cup" />;
  } else {
    subtitle = t('components.RecordVideo.RecordVideoControls.lookAtCamera');
  }

  return <p className={classes.Subtitle}>{subtitle}</p>;
}
