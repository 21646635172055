import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Arrow from './Arrow';

import useOnScreen from '../../../hooks/useOnScreen';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';

import classes from './styles.module.scss';
import logo from '../../../assets/images/landing/logo.svg';
import kendinin from '../../../assets/images/landing/promises/kendinin.svg';
import fark from '../../../assets/images/landing/promises/fark.svg';
import tadini from '../../../assets/images/landing/promises/tadini.svg';
import bizBoyleyiz from '../../../assets/images/landing/promises/biz-boyleyiz.svg';

export default function OurPeoplePromise() {
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isKendininVisible, setIsKendininVisible] = useState(false);

  const titleRef = useRef();
  const isIntersecting = useOnScreen(titleRef);
  useEffect(() => {
    if (isIntersecting) {
      setIsTitleVisible(true);
    }
  }, [isIntersecting]);

  const kendininRef = useRef();
  const isKendininIntersecting = useOnScreen(kendininRef);
  useEffect(() => {
    if (isKendininIntersecting) {
      setIsKendininVisible(true);
    }
  }, [isKendininIntersecting]);

  const { t } = useTranslation();

  const setNode = useHeaderLinkHighlighting('');

  return (
    <div
      className={classNames(classes.OurPeoplePromise, {
        [classes.inView]: isKendininVisible,
      })}
      ref={setNode}
    >
      <h2 className={classNames(classes.title)} ref={titleRef}>
        <span
          className={classNames(classes.animated, {
            [classes.inView]: isTitleVisible,
          })}
        >
          {t('pages.LandingPage.OurPeoplePromise.title')}
        </span>
      </h2>
      <img src={logo} alt="Logo" className={classes.logo} />
      <div className={classes.promises}>
        <p
          className={classNames(classes.text, classes.first, classes.opacityIn)}
        >
          {t('pages.LandingPage.OurPeoplePromise.text.0')}
        </p>
        <div className={classes.kendinin}>
          <img
            ref={kendininRef}
            src={kendinin}
            alt="Kendinin en iyisi ol"
            className={classes.zoomIn}
          />
        </div>
        <img
          src={fark}
          alt="Fark yarat"
          className={classNames(classes.fark, classes.zoomIn)}
        />
        <img
          src={tadini}
          alt="Tadini cikar"
          className={classNames(classes.tadini, classes.zoomIn)}
        />
        <img
          src={bizBoyleyiz}
          alt="Biz boyleyiz"
          className={classes.bizBoyleyiz}
        />
        {isKendininVisible && (
          <>
            <div className={classes.firstArrow}>
              <Arrow animationDelay={1} />
            </div>
            <div className={classes.secondArrow}>
              <Arrow animationDelay={2} />
            </div>
            <div className={classes.thirdArrow}>
              <Arrow animationDelay={3} />
            </div>
          </>
        )}
      </div>
      <div className={classes.textCols}>
        <p
          className={classNames(classes.text, classes.third, classes.opacityIn)}
        >
          {t('pages.LandingPage.OurPeoplePromise.text.1')}
        </p>
        <p
          className={classNames(
            classes.text,
            classes.second,
            classes.opacityIn
          )}
        >
          {t('pages.LandingPage.OurPeoplePromise.text.2')}
        </p>
      </div>
    </div>
  );
}
