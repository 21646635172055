import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import TextInput from '../../TextInput';
import Button from '../../Button';

import classes from './styles.module.scss';

export default function EmailForm({
  formik,
  hasTriedToSubmit,
  onSubmit,
  title,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <div className={classes.EmailForm}>
      <header className={classes.header}>
        <h1>{title || t('components.SignIn.EmailForm.defaultTitle')}</h1>
      </header>
      <form onSubmit={onSubmit}>
        <TextInput
          //  width={444}
          label={t('components.SignIn.EmailForm.emailLabel')}
          name="email"
          placeholder={t('components.SignIn.EmailForm.emailPlaceholder')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          touched={hasTriedToSubmit}
          type="email"
        />
      </form>
      <p className={classes.info}>
        {t('components.SignIn.EmailForm.confirmationText')}
      </p>
      <div className={classes.continueButtonContainer}>
        <Button
          style={{ fontSize: 24, lineHeight: '30px' }}
          height={isTabletOrMobile ? 54 : 64}
          onClick={onSubmit}
          disabled={!formik.values.email || formik.isSubmitting}
        >
          {t('common.continue')}
        </Button>
      </div>
    </div>
  );
}
