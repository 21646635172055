/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';
import { languages } from '../../../../constants/main';

import classes from './styles.module.scss';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

export default function Languages({ selectedLanguage, setSelectedLanguage }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setIsDropdownVisible(false));

  return (
    <div
      ref={menuRef}
      className={classes.Languages}
      onClick={() => setIsDropdownVisible(!isDropdownVisible)}
    >
      <span className={classes.activeLanguage}>{selectedLanguage.name}</span>

      {isDropdownVisible && (
        <ul className={classes.list}>
          {languages.map((lang) => (
            <li
              className={classNames(classes.listItem, {
                [classes.selected]: selectedLanguage?.name === lang?.name,
              })}
              onClick={() => setSelectedLanguage(lang)}
            >
              <img
                src={`https://flagcdn.com/w40/${lang?.iso.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w80/${lang?.iso.toLowerCase()}.png 2x`}
                width="30"
                alt="Country"
                className={classes.languageFlag}
              />
              <span className={classes.name}>{lang.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
