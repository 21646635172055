import { useNavigate } from 'react-router-dom';

import useUiContext from './useUiContext';

import PublicService from '../services/PublicService';

export default function useJobInviteHandlers({
  user,
  job,
  jobApplicationId,
  refetch,
  hasAssessment,
}) {
  const { setIsFetching } = useUiContext();

  const navigate = useNavigate();

  return {
    acceptInvite: async () => {
      try {
        setIsFetching(true);

        const hasNoVideoResume = !user?.userProfile?.userProfileVideoResume;
        const hasNoFileResume = !user?.userProfile?.userProfileFileResume;

        if (job?.applicationType === 'VideoOnly' && hasNoVideoResume) {
          navigate(
            `/jobs/${job.id}/apply/video?jobApplicationId=${jobApplicationId}`
          );
          return;
        }

        if (job?.applicationType === 'DocumentOnly' && hasNoFileResume) {
          navigate(
            `/jobs/${job.id}/apply/info?jobApplicationId=${jobApplicationId}`
          );
        }

        if (
          job?.applicationType === 'VideoAndDocument' &&
          (hasNoFileResume || hasNoVideoResume)
        ) {
          navigate(
            `/jobs/${job.id}/apply/video?jobApplicationId=${jobApplicationId}`
          );
          return;
        }

        if (
          job?.applicationType === 'VideoOrDocument' &&
          hasNoFileResume &&
          hasNoVideoResume
        ) {
          navigate(
            `/jobs/${job.id}/apply?jobApplicationId=${jobApplicationId}`
          );
          return;
        }

        if (hasAssessment) {
          await PublicService.acceptJobWithAssessmentInvite({
            jobApplicationId,
          });
        } else {
          await PublicService.acceptJobInvite({ jobApplicationId });
        }

        if (refetch) {
          await refetch();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },

    declineInvite: async () => {
      try {
        setIsFetching(true);
        await PublicService.declineJobInvite({ jobApplicationId });
        if (refetch) {
          await refetch();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
  };
}
