import React from 'react';

import classNames from 'classnames';
import classes from './styles.module.scss';

export default function SimpleStat({ value, title }) {
  return (
    <div className={classes.SimpleStat}>
      <span
        className={classNames(classes.value, {
          [classes.noValue]: value === 0,
        })}
      >
        {value}
      </span>
      <h2 className={classes.title}>{title}</h2>
    </div>
  );
}
