import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import VideoPlayer from '../../components/VideoPlayer';

import classes from './styles.module.scss';

export default function VideoPlayerModal({ show, handleClose, videoUrl }) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.VideoPlayerModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h1>{t('modals.VideoPlayerModal.title')}</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <VideoPlayer centerButton url={videoUrl} modal />
      </div>
    </Modal>
  );
}
