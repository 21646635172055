export default function formatVideoDuration(time) {
  const roundedTime = Math.round(time);
  if (roundedTime < 60) {
    return `0.${
      roundedTime.toString().length === 1 ? `0${roundedTime}` : roundedTime
    }`;
  }
  const minutes = Math.trunc(roundedTime / 60);
  const seconds = roundedTime - minutes * 60;

  return `${minutes}.${
    seconds.toString().length === 1 ? `0${seconds}` : seconds
  }`;
}
