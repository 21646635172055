import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';

export default function ApplyButton() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div
      role="button"
      tabIndex={0}
      className={classes.ApplyButton}
      onClick={() => navigate('/jobs')}
    >
      <h4 className={classes.title}>
        {t('pages.LandingPage.Videos.ApplyButton.This could be your spot!')}
      </h4>
      <p className={classes.text}>
        {t('pages.LandingPage.Videos.ApplyButton.Apply now and join our team!')}
      </p>
    </div>
  );
}
