/* eslint-disable no-unreachable */
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Button from '../../../../../Button';
import BlackButton from '../../../BlackButton';

import UserService from '../../../../../../services/UserService';
import useJobInviteHandlers from '../../../../../../hooks/useJobInviteHandlers';
import classes from './styles.module.scss';
import locationIcon from '../../../../../../assets/images/location2.svg';

export default function Invited({
  jobApplicationId,
  hasAssessment,
  refetch,
  job,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { acceptInvite, declineInvite } = useJobInviteHandlers({
    user,
    job,
    jobApplicationId,
    refetch,
    hasAssessment,
  });

  console.log('Invited', job);

  return (
    <div className={classes.Invited}>
      <div className={classes.mainContent}>
        <p className={classes.info}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Invited.info'
          )}
        </p>
        <div className={classes.jobInfo}>
          <p className={classes.jobTitle}>{job.jobTitle}</p>
          <p className={classes.jobLocation}>
            <img src={locationIcon} alt="Location" />
            {job.locationCity?.name}
          </p>
          <p className={classes.text}>
            <Trans
              i18nKey="components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Invited.text"
              components={{ bold: <strong /> }}
            />
          </p>
        </div>
      </div>

      <div className={classes.buttons}>
        <BlackButton
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={declineInvite}
        >
          {t('common.decline')}
        </BlackButton>
        <Button
          jobApplicationInfo
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={acceptInvite}
        >
          {t('common.accept')}
        </Button>
      </div>
    </div>
  );
}
