import React, { useState, useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import BlackButton from '../../../../BlackButton';
import Button from '../../../../../../Button';
import OfferForm from '../OfferForm';

import { UiContext } from '../../../../../../../context/UiContext';
import PublicService from '../../../../../../../services/PublicService';
import classes from './styles.module.scss';

export default function CounterOffer({
  offer,
  jobId,
  refetch,
  showDeclineOfferForm,
}) {
  const [counterOfferAmount, setCounterOfferAmount] = useState(0);
  const [message, setMessage] = useState('');

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const resetForm = () => {
    setCounterOfferAmount(0);
    setMessage('');
  };

  const makeCounterOffer = async () => {
    try {
      setIsFetching(true);

      await PublicService.declineOffer({
        jobApplicationId: jobId,
        offerId: offer.id,
        declineReason: 'CounterOffer',
        amount: parseFloat(counterOfferAmount?.slice?.(1)?.replace?.(/,/g, '')),
        declineMessage: message,
        status: 'Countered',
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.CounterOffer}>
      <i className={classes.backButton} onClick={showDeclineOfferForm}>
        Back
      </i>
      <OfferForm
        offer={offer}
        jobId={jobId}
        counterOffer
        counterOfferAmount={counterOfferAmount}
        setCounterOfferAmount={setCounterOfferAmount}
        message={message}
        setMessage={setMessage}
      />
      <div className={classes.buttons}>
        <BlackButton
          onClick={resetForm}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
        >
          {t('common.clear')}
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          jobApplicationInfo
          onClick={makeCounterOffer}
          disabled={
            !message ||
            !counterOfferAmount ||
            counterOfferAmount?.slice(1) === '0.00'
          }
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
