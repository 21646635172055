import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import Arrow from './Arrow';

import classes from './styles.module.scss';
import arrow from '../../../../assets/images/landing/career/arrow.svg';

export default function Employee({
  employee,
  shouldAnimate,
  animationDelay,
  index,
}) {
  const [shouldAnimateTitle, setShouldAnimateTitle] = useState(false);
  const [shouldAnimateEmployee, setShouldAnimateEmployee] = useState(false);

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setShouldAnimateTitle(true);
      }, animationDelay * 1000);

      setTimeout(() => {
        setShouldAnimateEmployee(true);
      }, animationDelay * 1000 + 500);
    }
  }, [animationDelay, shouldAnimate]);

  return (
    <li className={classes.Employee}>
      <h3
        className={classNames(classes.title, {
          [classes.visible]: shouldAnimateTitle,
        })}
      >
        {employee.title}
      </h3>
      <img className={classes.resizer} src={arrow} alt="Arrow" />
      {shouldAnimate && <Arrow animationDelay={animationDelay} index={index} />}
      <div className={classes.imageBlock}>
        <img
          src={employee.image}
          className={classNames(classes.image, {
            [classes.visible]: shouldAnimateEmployee,
          })}
          alt="Employee"
        />
      </div>
      <div className={classes.textContainer}>
        <span className={classes.text}>{employee.text}</span>
        <span className={classes.subText}>{employee.subText}</span>
      </div>
    </li>
  );
}
