/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import useUiContext from '../../hooks/useUiContext';
import videoPlayerButtonYellow from '../../assets/images/video-player-button-yellow.svg';
import videoPlayerButtonRed from '../../assets/images/video-player-button-red.svg';
import videoPlayerPauseButtonRed from '../../assets/images/video-player-pause-button-red.svg';
// import useOnScreen from '../../hooks/useOnScreen';
import useDraggable from '../../hooks/useDraggable';
import classes from './styles.module.scss';
import formatVideoDuration from '../../helpers/formatVideoDuration';

export default function VideoPlayer({
  leftButton,
  text,
  url,
  centerButton,
  jobPage,
  modal,
  width,
  height,
}) {
  const [progress, setProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState('0.00');
  const [currentTime, setCurrentTime] = useState('0.00');
  const [rawVideoDuration, setRawVideoDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef();
  const timeLineRef = useRef();
  const handleRef = useRef();
  const videoPlayerRef = useRef();

  const { isPreview } = useUiContext();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  // const isIntersecting = useOnScreen(videoPlayerRef);

  const { xPosition, isDragging } = useDraggable(handleRef);

  const rewindVideo = (xPos) => {
    const { left } = timeLineRef.current.getBoundingClientRect();
    const relativeClickPosition = Math.max(0, xPos - left);

    const percentage = relativeClickPosition / timeLineRef.current.clientWidth;

    videoRef.current.currentTime = videoRef.current.duration * percentage;
  };

  const rewindOnClick = (event) => {
    rewindVideo(event.clientX);
  };

  const pauseVideo = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };

  const unPauseVideo = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  useEffect(() => {
    if (isDragging) {
      rewindVideo(xPosition);
    }
  }, [isDragging, xPosition]);

  useEffect(() => {
    let animationId;

    const animateProgressBar = () => {
      setProgress(
        (videoRef.current?.currentTime / videoRef.current?.duration) * 100
      );
      animationId = requestAnimationFrame(animateProgressBar);
    };

    animationId = requestAnimationFrame(animateProgressBar);

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, []);

  useEffect(() => {
    const updateDuration = () => {
      setVideoDuration(formatVideoDuration(rawVideoDuration));
    };

    const updateCurrentTime = () => {
      if (!videoRef.current) {
        return;
      }

      setCurrentTime(formatVideoDuration(videoRef.current.currentTime));
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', updateDuration);
      videoRef.current.addEventListener('timeupdate', updateCurrentTime);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', updateDuration);
        videoRef.current.removeEventListener('timeupdate', updateCurrentTime);
      }
    };
  }, [rawVideoDuration]);

  useEffect(() => {
    const updateVideoDuration = () => {
      if (videoRef.current.duration === Infinity) {
        videoRef.current.currentTime = 1e101;
        videoRef.current.ontimeupdate = () => {
          if (videoRef?.current?.duration) {
            setRawVideoDuration(videoRef?.current?.duration);
          }
        };
      } else {
        setRawVideoDuration(videoRef.current.duration);
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', updateVideoDuration);
    }

    return () => {
      videoRef.current?.removeEventListener(
        'loadedmetadata',
        updateVideoDuration
      );
    };
  }, []);

  /*  useEffect(() => {
    if (isIntersecting) {
      videoRef.current?.play?.();
    } else {
      videoRef.current?.stop?.();
    }
  }, [isIntersecting, rawVideoDuration]); */

  // Set isPlaying to false when video ends
  useEffect(() => {
    const handleVideoEnd = () => {
      setIsPlaying(false);
      videoRef.current.currentTime = 0;
    };

    videoRef.current.addEventListener('ended', handleVideoEnd);

    return () => {
      videoRef.current?.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  const videoButtonImg = isPlaying
    ? videoPlayerPauseButtonRed
    : videoPlayerButtonRed;

  return (
    <div
      className={classNames(classes.VideoPlayer, {
        [classes.jobPage]: jobPage,
        [classes.modal]: modal,
        [classes.preview]: isPreview,
        [classes.playing]: isPlaying,
      })}
      ref={videoPlayerRef}
    >
      <video
        playsInline
        src={url}
        ref={videoRef}
        style={{
          width,
          height,
        }}
        onClick={() => {
          if (isTabletOrMobile) {
            pauseVideo();
          }
        }}
      />
      <div
        className={classNames(classes.playButtonContainer, {
          [classes.leftButton]: leftButton,
          [classes.centerButton]: centerButton,
        })}
      >
        <button
          type="button"
          className={classes.playButton}
          onClick={() => {
            if (isPlaying) {
              pauseVideo();
            } else {
              unPauseVideo();
            }
          }}
        >
          <img
            src={modal ? videoPlayerButtonYellow : videoButtonImg}
            alt="Button"
          />
        </button>
      </div>
      <div className={classes.text}>
        <span>{text}</span>
      </div>
      <div className={classes.controls}>
        <span className={classes.time}>{currentTime}</span>
        <div
          className={classes.timeline}
          onClick={rewindOnClick}
          ref={timeLineRef}
        >
          <div
            className={classes.handle}
            style={{ left: `calc(${progress}%)` }}
            ref={handleRef}
          />
          <div className={classes.progressBar}>
            <div
              className={classes.progress}
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
        <span className={classes.time}>{videoDuration}</span>
      </div>
    </div>
  );
}
