/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Tabs from './Tabs';

import classes from './styles.module.scss';
import useHeaderLinkHighlighting from '../../../hooks/useHeaderLinkHighlighting';
import useOnScreen from '../../../hooks/useOnScreen';

function spliTextIntoSpans(text) {
  return text.split(' ').map((word, index) => (
    <>
      <span key={word + index} className={classes.word}>
        {word}
      </span>
      {'  '}
    </>
  ));
}

function restartAnimations(element) {
  for (const animation of document.getAnimations()) {
    if (
      animation.effect instanceof KeyframeEffect &&
      element.contains(animation.effect.target)
    ) {
      animation.cancel();
      animation.play();
    }
  }
}

export default function GivingBack() {
  const [activeTab, setActiveTab] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [shouldAnimateHeart, setShouldAnimateHeart] = useState(false);
  const [leftCoumnWords, setLeftCoumnWords] = useState([]);
  const [rightColumnWords, setRightColumnWords] = useState([]);
  const [columnWidth, setColumnWidth] = useState(0);
  const [shouldAnimateText, setShouldAnimateText] = useState(false);
  const [shouldAnimateTitle, setShouldAnimateTitle] = useState(false);

  const containerRef = useRef();
  const titleRef = useRef();
  const pathOneRef = useRef();
  const pathTwoRef = useRef();
  const textRef = useRef();
  const columnOneRef = useRef();
  const columnTwoRef = useRef();

  const isIntersecting = useOnScreen(titleRef);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (isIntersecting) {
      setShouldAnimateTitle(true);
      setTimeout(() => {
        setShouldAnimateHeart(true);
        setShouldAnimateText(true);
      }, 600);
    }
  }, [isIntersecting]);

  const { t } = useTranslation();

  const setNode = useHeaderLinkHighlighting('giving-back');

  useEffect(() => {
    if (containerRef.current) {
      setMinHeight(containerRef.current.clientHeight);
    }
  }, []);

  // Replay text animations when the active tab changes
  useEffect(() => {
    restartAnimations(columnOneRef.current);
    restartAnimations(columnTwoRef.current);
  }, [activeTab]);

  // This is used to get the content of each text columns and then to recreate columns as separate <p> elements so that we can animate them separately
  const getColumnContent = useCallback(() => {
    const p = textRef.current;

    if (!p) {
      return;
    }

    const words = p.querySelectorAll('span');

    const pRect = p.getBoundingClientRect();
    const pWidth = pRect.width;
    // eslint-disable-next-line no-shadow
    const columnWidth = (pWidth - (isTabletOrMobile ? 21 : 71)) / 2;
    const pX = pRect.x;

    // eslint-disable-next-line no-shadow
    const leftCoumnWords = [];
    // eslint-disable-next-line no-shadow
    const rightColumnWords = [];

    words.forEach((word) => {
      const wordRect = word.getBoundingClientRect();
      const wordX = wordRect.x;

      if (wordX - pX < columnWidth) {
        leftCoumnWords.push(word.textContent);
      } else {
        rightColumnWords.push(word.textContent);
      }
    });

    setLeftCoumnWords(leftCoumnWords);
    setRightColumnWords(rightColumnWords);
    setColumnWidth(columnWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getColumnContent);

    getColumnContent();

    return () => {
      window.removeEventListener('resize', getColumnContent);
    };
  }, [getColumnContent, activeTab]);

  return (
    <div className={classes.GivingBack} id="giving-back" ref={setNode}>
      <div
        ref={containerRef}
        className={classNames(classes.container, {
          [classes.volleyball]: activeTab === 1,
        })}
        style={{ minHeight }}
      >
        <div className={classes.header}>
          <div className={classes.heart}>
            <svg
              className={classes.svg}
              width="93"
              height="106"
              viewBox="0 0 91 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={classNames(classes.pathOne, {
                  [classes.playing]: shouldAnimateHeart,
                })}
                ref={pathOneRef}
                d="M63.6989 37.9928C63.2041 32.9421 59.1746 29.1006 55.2865 26.3973C50.9743 23.3384 46.1673 21.4886 41.1481 19.9947C34.9272 18.1452 28.2821 16.9366 21.7785 17.2211C16.2645 17.4345 10.3264 18.8567 5.73136 21.9156C3.82267 23.1961 2.12605 24.9041 1.13636 27.0382C-0.0654068 29.7414 -0.206772 32.6577 0.217381 35.5744C0.641534 38.7756 1.84329 41.9057 3.68128 44.5378C5.51928 47.1699 7.64001 49.5176 9.83147 51.7228C18.4559 60.6151 29.1304 67.3022 40.4412 72.2818C43.3396 73.5623 46.2379 74.7716 49.207 75.8387C51.4692 76.6924 53.7313 77.1191 56.1349 77.5459C61.0833 78.3996 66.5266 78.8258 70.9802 81.3156C71.8992 81.8136 72.7475 82.4539 73.5251 83.2365C74.5148 84.2324 75.9994 82.7388 75.0097"
              />
              <path
                className={classNames(classes.pathTwo, {
                  [classes.playing]: shouldAnimateHeart,
                })}
                ref={pathTwoRef}
                d="M56.8602 35.1931C56.8602 28.3221 56.0899 21.4509 57.0703 14.6508C57.5605 11.3924 58.3308 7.92185 60.3617 5.30096C62.3926 2.68007 65.5439 1.61782 68.6953 2.04283C72.8971 2.53868 76.3986 5.72604 78.9897 8.91361C82.0011 12.5262 84.312 16.8471 85.8527 21.3097C86.483 23.1514 86.9732 25.0643 87.1833 26.9768C87.5334 30.3769 88.0236 33.7766 88.3738 37.1767C88.7239 40.7892 88.7941 44.3311 88.7941 47.9437C88.7941 51.5563 88.7941 55.1691 88.7941 58.7816C88.654 66.1485 87.9537 73.3024 86.6931 80.5276C86.0629 84.0693 85.3625 87.611 84.4521 91.0819C84.0319 92.6403 83.6118 94.1988 82.9815 95.6863C82.4213 97.0322 81.791 98.4487 81.3008 99.8653C80.8106 101.282 80.6004 102.769 80.7405 104.186C80.8806 105.532 82.9815 105.532 82.8414 104.186C82.4213"
              />
            </svg>
          </div>
          <h2 className={classes.title} ref={titleRef}>
            <span
              className={classNames(classes.animation, {
                [classes.visible]: shouldAnimateTitle,
              })}
            >
              {t('pages.LandingPage.GivingBack.title')}
            </span>
          </h2>
        </div>
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          shouldAnimate={shouldAnimateTitle}
        />
        <div className={classes.textContainer}>
          <div
            className={classNames(classes.columns, {
              [classes.visible]: shouldAnimateText,
            })}
          >
            <p ref={columnOneRef} style={{ width: columnWidth }}>
              {leftCoumnWords.join(' ')}
            </p>
            <p ref={columnTwoRef} style={{ width: columnWidth }}>
              {rightColumnWords.join(' ')}
            </p>
          </div>
          <p ref={textRef} className={classes.textSource}>
            {spliTextIntoSpans(
              activeTab === 0
                ? t('pages.LandingPage.GivingBack.text')
                : t('pages.LandingPage.GivingBack.text2')
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
