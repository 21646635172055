import React from 'react';

import { useTranslation } from 'react-i18next';

import underMaintenanceIcon from '../../assets/images/underMaintenance.svg';
import classes from './styles.module.scss';

export default function UnderMaintenance() {
  const { t } = useTranslation();

  return (
    <div className={classes.UnderMaintenance}>
      <div className={classes.container}>
        <div className={classes.message}>
          <span className={classes.message}>
            {t('components.UnderMaintenance.message.0')}
          </span>
        </div>
        <div className={classes.illustration}>
          <img src={underMaintenanceIcon} alt="Under maintenance" />
        </div>
        <div className={classes.message}>
          <h1>
            {t('components.UnderMaintenance.message.1')} <br />
            {t('components.UnderMaintenance.message.2')}
          </h1>
        </div>
      </div>
    </div>
  );
}
