import React from 'react';
import { useTranslation } from 'react-i18next';

import WorkExperienceDropdown from '../../RecordVideo/AdditionalInfoForm/WorkExperienceDropdown';
import EducationDropdown from '../../RecordVideo/AdditionalInfoForm/EducationDropdown';
import CalendarDropdown from '../../RecordVideo/AdditionalInfoForm/CalendarDropdown';
import LocationDropdown from '../../RecordVideo/AdditionalInfoForm/LocationDropdown';

import classes from './styles.module.scss';

export default function AdditionalInfoForm({
  location,
  setLocation,
  date,
  setDate,
  education,
  setEducation,
  hasWorkExperience,
  setHasWorkExperience,
  isFresher,
  setIsFresher,
  jobs,
  setJobs,
  hasTriedToSubmit,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.AdditionalInfoForm}>
      <h2>
        {t('components.RecordVideo.AdditionalInfoForm.additionalInfoFormTitle')}
      </h2>
      <div className={classes.dropdowns}>
        <div className={classes.row}>
          <div className={classes.col}>
            <CalendarDropdown date={date} setDate={setDate} />
          </div>
          <div className={classes.col}>
            <LocationDropdown location={location} setLocation={setLocation} />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <EducationDropdown
              education={education}
              setEducation={setEducation}
            />
          </div>
          <div className={classes.col}>
            <WorkExperienceDropdown
              hasWorkExperience={hasWorkExperience}
              setHasWorkExperience={setHasWorkExperience}
              isFresher={isFresher}
              setIsFresher={setIsFresher}
              jobs={jobs}
              setJobs={setJobs}
              hasTriedToSubmit={hasTriedToSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
