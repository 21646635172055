import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MessageWithButton from '../MessageWithButton';

import classes from './styles.module.scss';

export default function RejectionMessage({ status, subStatus }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  let message;

  if (
    status === 'New' ||
    status === 'Screen' ||
    status === 'Offer' ||
    status === 'Onboard' ||
    status === 'Interview'
  ) {
    if (status === 'Screen' && subStatus === 'Incomplete') {
      message = (
        <p>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessageExpired.0'
          )}
          <br />
          <br />{' '}
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessageExpired.1'
          )}
        </p>
      );
    } else {
      message = (
        <p>
          {t(
            'components.MyDashboard.JobApplicationInfo.RejectionMessage.message1'
          )}{' '}
          <br />
          <br />{' '}
          {t(
            'components.MyDashboard.JobApplicationInfo.RejectionMessage.message2'
          )}
        </p>
      );
    }
  }

  return (
    <div className={classes.RejectionMessage}>
      <MessageWithButton
        message={message}
        buttonLabel={t(
          'components.MyDashboard.JobApplicationInfo.RejectionMessage.browseJobs'
        )}
        onButtonClick={() => navigate('/jobs')}
      />
    </div>
  );
}
