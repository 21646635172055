import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');
  const history = useRef([]);

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    history.current.push(location.pathname);

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // save hash for further use after navigation
    }

    console.log(location.pathname, history.current);

    const scrollPage = () => {
      const element = document.getElementById(lastHash.current);

      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      lastHash.current = '';
    };

    const isTheSamePage =
      history.current[history.current.length - 2] === location.pathname;

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(scrollPage, isTheSamePage ? 0 : 350);
    }
  }, [location]);

  return null;
}

export default ScrollToAnchor;
