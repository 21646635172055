/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function ProfileDropdown({
  options,
  setCurrentOption,
  currentOption,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div className={classes.ProfileDropdown} ref={dropdownRef}>
      <div
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {currentOption}
      </div>
      {isOptionsListVisible && (
        <ul className={classes.options}>
          {options?.map((option) => (
            <li
              className={classNames({
                [classes.active]: option === currentOption,
              })}
              key={option}
              onClick={() => {
                setCurrentOption(option);
                setIsOptionsListVisible(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
