/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DropdownSelector from '../DropdownSelector';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { TURKISH_CITIES } from '../../../../constants/main';
import locationIcon from '../../../../assets/images/location.svg';
import classes from './styles.module.scss';

export default function LocationDropdown({ location, setLocation }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const optionsRef = useRef();

  useOnClickOutside(optionsRef, () => setIsOptionsListVisible(false));

  const { t } = useTranslation();

  useEffect(() => {
    if (isOptionsListVisible) {
      setSearchTerm('');
    }
  }, [isOptionsListVisible]);

  const currentOption = <div className={classes.currentOption}>{location}</div>;

  return (
    <DropdownSelector
      title={t(
        'components.RecordVideo.AdditionalInfoForm.LocationDropdown.location'
      )}
      toggleOptions={() => setIsOptionsListVisible((prevState) => !prevState)}
      isOptionsListVisible={isOptionsListVisible}
      currentOption={currentOption}
      value={location}
      icon={locationIcon}
    >
      <div
        className={classNames(classes.options, {
          [classes.hasOption]: !!location,
        })}
        ref={optionsRef}
      >
        <header
          className={classes.header}
          onClick={() => setIsOptionsListVisible(false)}
        >
          <span className={classes.title}>
            {t(
              'components.RecordVideo.AdditionalInfoForm.LocationDropdown.location'
            )}
          </span>
        </header>
        <div className={classes.searchInputContainer}>
          <input
            className={classes.searchInput}
            type="text"
            placeholder={t(
              'components.RecordVideo.AdditionalInfoForm.LocationDropdown.location'
            )}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </div>
        <ul>
          {TURKISH_CITIES.filter((city) =>
            city.toLowerCase().includes(searchTerm.toLowerCase())
          ).map((city) => {
            return (
              <li
                key={city}
                className={classNames({
                  [classes.active]: city === location,
                })}
                onClick={() => {
                  setLocation(city);
                  setIsOptionsListVisible(false);
                }}
              >
                {city}
              </li>
            );
          })}
        </ul>
      </div>
    </DropdownSelector>
  );
}
