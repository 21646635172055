/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Dropdown({ options, value, setValue }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div className={classes.Dropdown} ref={dropdownRef}>
      <div
        className={classNames(classes.selector, {
          [classes.open]: isOptionsListVisible,
        })}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {value?.label}
      </div>
      {isOptionsListVisible && (
        <div className={classes.optionsListContainer}>
          <ul className={classes.options}>
            {options.map((option) => {
              return (
                <li
                  className={classNames({
                    // eslint-disable-next-line eqeqeq
                    [classes.active]:
                      option.value.toString() === value?.value?.toString(),
                  })}
                  key={option.value}
                  onClick={() => {
                    setValue?.(option);
                    setIsOptionsListVisible(false);
                  }}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
