/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { /* useEffect, useCallback, */ useCallback, memo } from 'react';

const VideoPreview = ({
  //  setVideoDuration,
  file,
  videoPreviewRef,

  isSafari,
}) => {
  // There is a bug in Chrome that causes the browser to return Infinity instead of the correct video.duration. This 'fix' should bypass the bug and save correct video duration in state for future use.
  /*  const loadVideo = useCallback(() => {
    if (videoPreviewRef.current.duration === Infinity) {
      videoPreviewRef.current.currentTime = 1e101;
      videoPreviewRef.current.ontimeupdate = () => {
        if (videoPreviewRef?.current?.duration) {
          setVideoDuration(videoPreviewRef?.current?.duration);
          videoPreviewRef.current.currentTime = 0;
          videoPreviewRef.current.ontimeupdate = null;
        }
      };
    } else {
      setVideoDuration(videoPreviewRef?.current?.duration);
    }
  }, [setVideoDuration, videoPreviewRef]);
 */
  /*   useEffect(() => {
    if (isSafari) {
      videoPreviewRef.current.addEventListener('progress', loadVideo, {
        once: true,
      });
    }
  }, [isSafari, loadVideo, videoPreviewRef]);
 */

  // Without autoplay, video will not be rendered on iPhone before user clicks play button. This function will pause video shortly after it starts. This way the video will be rendered and ready to play when user clicks play button.
  const stopVideo = useCallback(() => {
    setTimeout(() => {
      if (!videoPreviewRef.current.paused) {
        videoPreviewRef.current.pause();
      }
    }, 10);
  }, [videoPreviewRef]);

  return (
    <>
      <video
        id="preview"
        playsInline
        key={1}
        ref={videoPreviewRef}
        src={URL.createObjectURL(file)}
        autoPlay={isSafari}
        onLoadedData={stopVideo}
      />
    </>
  );
};

export default memo(VideoPreview);
