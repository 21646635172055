import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';
import employeeOne from '../../../../assets/images/landing/employees/RGM-01-1.png';
import employeeTwo from '../../../../assets/images/landing/employees/RGM-01-2.png';
import employeeThree from '../../../../assets/images/landing/employees/RGM-02-2.png';
import employeeFour from '../../../../assets/images/landing/employees/RGM-02-1.png';

export default function FromMemberToManager({
  variant,
  isVisible,
  animationDelay,
}) {
  const [shouldEmployeeOneAnimate, setShouldEmployeeOneAnimate] =
    useState(false);
  const [shouldEmployeeTwoAnimate, setShouldEmployeeTwoAnimate] =
    useState(false);
  const [shouldInitialPositionAnimate, setShouldInitialPositionAnimate] =
    useState(false);
  const [shouldArrowAnimate, setShouldArrowAnimate] = useState(false);
  const [shouldFinalPositionAnimate, setShouldFinalPositionAnimate] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    let timeoutZero;
    let timeoutOne;
    let timeoutTwo;
    let timeOutThree;
    let timeoutFour;

    if (isVisible) {
      timeoutZero = setTimeout(() => {
        setShouldInitialPositionAnimate(isVisible);
      }, animationDelay);

      timeoutOne = setTimeout(() => {
        setShouldEmployeeTwoAnimate(isVisible);
      }, animationDelay);

      timeoutTwo = setTimeout(() => {
        setShouldEmployeeOneAnimate(isVisible);
      }, animationDelay + 100);

      timeOutThree = setTimeout(() => {
        setShouldArrowAnimate(isVisible);
      }, animationDelay + 400);

      timeoutFour = setTimeout(() => {
        setShouldFinalPositionAnimate(isVisible);
      }, animationDelay + 700);
    }

    return () => {
      clearTimeout(timeoutOne);
      clearTimeout(timeoutTwo);
      clearTimeout(timeOutThree);
      clearTimeout(timeoutFour);
      clearTimeout(timeoutZero);
    };
  }, [isVisible, animationDelay]);

  const isSecondVariant = variant === 'second';

  return (
    <div
      className={classNames(classes.FromMemberToManager, {
        [classes.variantTwo]: isSecondVariant,
      })}
    >
      <div className={classes.progress}>
        <div className={classes.images}>
          <img
            className={classNames(classes.employeeOne, {
              [classes.visible]: shouldEmployeeOneAnimate,
            })}
            src={isSecondVariant ? employeeThree : employeeOne}
            alt="Employee"
          />
          <img
            className={classNames(classes.employeeTwo, {
              [classes.visible]: shouldEmployeeTwoAnimate,
            })}
            src={isSecondVariant ? employeeFour : employeeTwo}
            alt="Employee"
          />
        </div>
        <span className={classes.jobPosition}>
          <span
            className={classNames(classes.animation, {
              [classes.visible]: shouldInitialPositionAnimate,
            })}
          >
            {t('pages.LandingPage.Future.FromMemberToManager.text.0')}
          </span>
        </span>
        <svg
          className={classNames(classes.arrow, {
            [classes.visible]: shouldArrowAnimate,
          })}
          width="76"
          height="35"
          viewBox="0 0 76 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 17.2868C0 15.0659 1.80032 13.2656 4.02113 13.2656H71.9782C74.199 13.2656 75.9994 15.0659 75.9994 17.2868C75.9994 19.5076 74.199 21.3079 71.9782 21.3079H4.02113C1.80032 21.3079 0 19.5076 0 17.2868Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.4215 1.22053C56.9683 -0.373088 59.514 -0.411097 61.1076 1.13563L74.7795 14.4052C76.3731 15.952 76.4111 18.4977 74.8644 20.0913C73.3176 21.6849 70.7719 21.723 69.1783 20.1762L55.5064 6.90663C53.9128 5.3599 53.8748 2.81415 55.4215 1.22053Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.8644 14.4862C76.4111 16.0798 76.3731 18.6255 74.7795 20.1723L61.1076 33.4418C59.514 34.9886 56.9683 34.9506 55.4215 33.357C53.8748 31.7633 53.9128 29.2176 55.5064 27.6709L69.1783 14.4013C70.7719 12.8545 73.3176 12.8925 74.8644 14.4862Z"
          />
        </svg>
        <span className={classes.jobPosition}>
          <span
            className={classNames(classes.animation, {
              [classes.visible]: shouldFinalPositionAnimate,
            })}
          >
            {t('pages.LandingPage.Future.FromMemberToManager.text.1')}
          </span>
        </span>
      </div>
    </div>
  );
}
