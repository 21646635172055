import { IMAGES_URL } from '../constants/main';

export default function getResizedImageUrl({
  quality = 70,
  width = 800,
  url,
  imagesSource = IMAGES_URL,
}) {
  if (!url) {
    return '';
  }

  let imageFormat = url.split('.').pop();

  if (imageFormat === 'jpeg') {
    imageFormat = 'jpg';
  }

  if (imageFormat !== 'jpg' && imageFormat !== 'png') {
    imageFormat = 'jpg';
  }

  return `${imagesSource}/${url}?width=${width}&format=${imageFormat}&quality=${quality}`;
}
