import React from 'react';

import classes from './styles.module.scss';

export default function ResumeHeader({ title }) {
  return (
    <header className={classes.ResumeHeader}>
      <h3>{title}</h3>
      <i className={classes.infoIcon}>Info</i>
    </header>
  );
}
