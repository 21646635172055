import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';
import ConfirmReschedule from '../ConfirmReschedule';

import classes from './styles.module.scss';

export default function ConfirmRescheduleWithButtons({
  onBackButtonClick,
  date,
  startTime,
  rescheduledDate,
  rescheduledTime,
  onConfirm,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.ConfirmRescheduleWithButtons}>
      <i className={classes.backButton} onClick={onBackButtonClick}>
        Back
      </i>
      <div className={classes.container}>
        <p className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.ConfirmRescheduleWithButtons.heading.0'
          )}
          <br />
          {t(
            'components.MyDashboard.JobApplicationInfo.ConfirmRescheduleWithButtons.heading.1'
          )}
        </p>
        <ConfirmReschedule
          date={date}
          startTime={startTime}
          rescheduledDate={rescheduledDate}
          rescheduledTime={rescheduledTime}
        />
      </div>
      <div className={classes.buttons}>
        <Button width={300} jobApplicationInfo onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
      </div>
    </div>
  );
}
