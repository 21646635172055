import React from 'react';

import {
  // BrowserRouter,
  Route,
  // Routes,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import MyNotificationsPage from '../pages/MyNotificationsPage';
import PolicyPage from '../pages/PolicyPage';

import CompleteApplicationPage from '../pages/CompleteApplicationPage';
import MyFavoritesPage from '../pages/MyFavoritesPage';
import MyDashboardPage from '../pages/MyDashboardPage';
import SignInPage from '../pages/SignInPage';
import DropOffPage from '../pages/DropOffPage';
import EditProfilePage from '../pages/EditProfilePage';
import RecordVideoPage from '../pages/RecordVideoPage';
import ApplyJobPage from '../pages/ApplyJobPage';
import JobPage from '../pages/JobPage';
import JobsPage from '../pages/JobsPage';
import LandingPage from '../pages/LandingPage';
import Layout from '../containers/Layout';
import PrivateRoute from '../containers/PrivateRoute';
import NotFound from '../components/NotFound';
import UnderMaintenance from '../components/UnderMaintenance';

import UiContextProvider from '../context/UiContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/"
        element={
          <Layout landing={false}>
            <LandingPage />
          </Layout>
        }
      />
      <Route
        path="/jobs"
        element={
          <Layout>
            <JobsPage />
          </Layout>
        }
      />
      <Route
        path="/jobs/city/:cityId/:cityName"
        element={
          <Layout>
            <JobsPage />
          </Layout>
        }
      />
      <Route
        path="/jobs/:jobId"
        element={
          <Layout>
            <JobPage />
          </Layout>
        }
      />
      <Route
        path="/search-results"
        element={
          <Layout>
            <JobsPage />
          </Layout>
        }
      />
      <Route
        path="/jobs/:jobId/apply"
        element={
          <PrivateRoute>
            <Layout>
              <ApplyJobPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/:jobId/apply/video"
        element={
          <PrivateRoute>
            <Layout>
              <RecordVideoPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/:jobId/apply/info"
        element={
          <PrivateRoute>
            <Layout>
              <CompleteApplicationPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Layout>
              <EditProfilePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/drop-off"
        element={
          <Layout>
            <DropOffPage />
          </Layout>
        }
      />
      <Route
        path="/sign-in"
        element={
          <Layout>
            <SignInPage />
          </Layout>
        }
      />
      <Route
        path="/my-dashboard"
        element={
          <PrivateRoute>
            <Layout>
              <MyDashboardPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/favorites"
        element={
          <PrivateRoute>
            <Layout>
              <MyFavoritesPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/policy"
        element={
          <Layout>
            <PolicyPage />
          </Layout>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <Layout>
              <MyNotificationsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/502"
        element={
          <Layout>
            <UnderMaintenance />
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
    </Route>
  )
);

const RoutesComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UiContextProvider>
        <RouterProvider router={router} />
      </UiContextProvider>
    </QueryClientProvider>
  );
};

export default RoutesComponent;
