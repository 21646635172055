import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import CountryCitySelector from './CountryCitySelector';
import SortOrderDropdown from './SortOrderDropdown';
import SearchBar from '../SearchBar';
import classes from './styles.module.scss';

export default function JobsSearch({
  sortOrder,
  setSortOrder,
  setSearchTerm,
  cityOptions,
  city,
  setCity,
  setAppliedCity,
  noLocation,
  style,
}) {
  const [isCountryCitySelectorVisible, setIsCountryCitySelectorVisible] =
    useState(false);

  const countryCitySelectorInnerContainerRef = useRef();

  const { t } = useTranslation();

  const { cityId, cityName } = useParams();

  useEffect(() => {
    if (!isCountryCitySelectorVisible) {
      setCity?.({
        label: cityName,
        value: cityId,
      });
    }
  }, [cityId, cityName, isCountryCitySelectorVisible, setCity]);

  return (
    <div
      className={classNames(classes.JobsSearch, {
        [classes.noLocation]: noLocation,
      })}
      style={style}
    >
      <div className={classes.container}>
        <div className={classes.searchContainer}>
          <SearchBar
            alwaysWhite
            setSearchTerm={setSearchTerm}
            searchName="jobSearch"
          />
        </div>
        {!noLocation && (
          <div className={classes.citySelectorContainer}>
            <div
              ref={countryCitySelectorInnerContainerRef}
              className={classes.citySelector}
              onClick={() =>
                setIsCountryCitySelectorVisible((prevState) => !prevState)
              }
            >
              {t('common.Turkey')}
            </div>
            {isCountryCitySelectorVisible && (
              <CountryCitySelector
                city={city}
                setCity={setCity}
                cityOptions={cityOptions}
                close={() => setIsCountryCitySelectorVisible(false)}
                countryCitySelectorContainerRef={
                  countryCitySelectorInnerContainerRef
                }
                setAppliedCity={setAppliedCity}
              />
            )}
          </div>
        )}
        <div className={classes.sortOrderDropdownContainer}>
          <SortOrderDropdown
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </div>
      </div>
    </div>
  );
}
