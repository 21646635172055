import React from 'react';

import Selfie from './Selfie';
import VideoQuestion from './VideoQuestion';

import classes from './styles.module.scss';

export default function MainContent({
  step,
  isVideoRecording,
  isVideoRecordingPaused,
  hasVideo,
}) {
  let content = null;

  if (step?.name === 'Picture') {
    content = <Selfie />;
  } else {
    content = (
      <VideoQuestion
        step={step}
        isVideoRecording={isVideoRecording}
        isVideoRecordingPaused={isVideoRecordingPaused}
        hasVideo={hasVideo}
      />
    );
  }

  return <div className={classes.MainContent}>{content}</div>;
}
