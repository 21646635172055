import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import Text from './Text';

import classes from './styles.module.scss';

export default function RecordVideoControls({
  currentStep,
  hasImage,
  takePhoto,
  retakePhoto,
  usePhoto,
  steps,
  startVideoRecording,
  isVideoRecording,
  stopVideoRecording,
  hasVideo,
  retakeVideo,
  useVideo,
  uploadMedia,
  isVideoRecordingPaused,
  restartRecording,
  resumeVideoRecording,
}) {
  const { t } = useTranslation();

  let topButton = (
    <button
      type="button"
      className={classes.whiteButton}
      style={{ visibility: 'hidden' }}
    />
  );
  let bottomButton = null;

  if (currentStep === 1 && !hasImage) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={takePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.takePicture')}
      </Button>
    );
  } else if (currentStep === 1 && hasImage) {
    topButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={retakePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.retakePicture')}
      </button>
    );

    bottomButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={usePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.nextStep')}
      </Button>
    );
  } else if (
    steps[currentStep - 1]?.duration &&
    !isVideoRecording &&
    !hasVideo
  ) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={startVideoRecording}
      >
        {t('components.RecordVideo.RecordVideoControls.start')}
      </Button>
    );
  } else if (
    steps[currentStep - 1]?.duration &&
    isVideoRecording &&
    !hasVideo &&
    !isVideoRecordingPaused
  ) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={stopVideoRecording}
      >
        {t('components.RecordVideo.RecordVideoControls.stop')}
      </Button>
    );
  } else if (isVideoRecordingPaused) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={resumeVideoRecording}
      >
        {t('components.RecordVideo.RecordVideoControls.start')}
      </Button>
    );
  } else if (hasVideo) {
    topButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={retakeVideo}
      >
        {t('components.RecordVideo.RecordVideoControls.retakeVideo')}
      </button>
    );
    bottomButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={useVideo}
      >
        {steps[currentStep].name === 'Review & Upload'
          ? t('components.RecordVideo.RecordVideoControls.reviewAll')
          : t('components.RecordVideo.RecordVideoControls.recordNext')}
      </Button>
    );
  } else {
    topButton = (
      <>
        <button
          type="button"
          className={classes.whiteButton}
          onClick={retakeVideo}
        >
          {t('components.RecordVideo.RecordVideoControls.retakeVideo')}
        </button>
        <button
          type="button"
          className={classes.whiteButton}
          onClick={restartRecording}
        >
          {t('components.RecordVideo.RecordVideoControls.restart')}
        </button>
      </>
    );
    bottomButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={uploadMedia}
      >
        {t('components.RecordVideo.RecordVideoControls.upload')}
      </Button>
    );
  }

  return (
    <div className={classes.RecordVideoControls}>
      <div className={classes.textContainer}>
        <Text
          steps={steps}
          currentStep={currentStep}
          isVideoRecording={isVideoRecording}
          isVideoRecordingPaused={isVideoRecordingPaused}
          hasVideo={hasVideo}
        />
      </div>
      <div className={classes.buttons}>
        {topButton}
        {bottomButton}
      </div>
    </div>
  );
}
