import React from 'react';

import classNames from 'classnames';
import { Trans } from 'react-i18next';

import classes from './styles.module.scss';
import pause from '../../../../../../assets/images/pause.svg';
import resume from '../../../../../../assets/images/resumeIcon.svg';

const PauseIcon = () => {
  return <img src={pause} alt="Pause" />;
};

const ResumeIcon = () => {
  return <img src={resume} alt="Resume" />;
};

export default function VideoQuestion({
  step,
  isVideoRecording,
  isVideoRecordingPaused,
  hasVideo,
}) {
  let textKey = 'beforeRecord';

  if (isVideoRecording) {
    textKey = 'duringRecord';
  }
  if (isVideoRecordingPaused) {
    textKey = 'paused';
  }
  if (hasVideo) {
    textKey = 'afterRecord';
  }
  if (step?.name === 'Review & Upload') {
    textKey = 'review';
  }

  return (
    <div className={classes.VideoQuestion}>
      {step?.question && <p className={classes.question}>{step.question}</p>}
      <div className={classes.instructions}>
        <p>
          <Trans
            i18nKey={`components.RecordVideo.RecordVideoControls.vrQuestion.${textKey}.0`}
            values={{ seconds: step?.duration }}
            components={{
              strong: <span className={classes.red} />,
              pauseIcon: <PauseIcon />,
              resumeIcon: <ResumeIcon />,
            }}
          />
        </p>
        <p
          className={classNames({
            [classes.recorded]: hasVideo,
          })}
        >
          <Trans
            i18nKey={`components.RecordVideo.RecordVideoControls.vrQuestion.${textKey}.1`}
            components={{
              strong: <span className={classes.red} />,
              colEnd: <span className={classes.columnEnd} />,
            }}
          />
        </p>
        {step?.name === 'Review & Upload' && (
          <p className={classes.finish}>
            <Trans
              i18nKey={`components.RecordVideo.RecordVideoControls.vrQuestion.${textKey}.2`}
              components={{
                strong: <span className={classes.red} />,
                colEnd: <span className={classes.columnEnd} />,
              }}
            />
          </p>
        )}
      </div>
    </div>
  );
}
