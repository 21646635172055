import React from 'react';

import { DonutChart } from 'react-circle-chart';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function JobsAppliedStatus({
  unsuitablePercentage,
  suitablePercentage,
  focusOnJobsApplied,
}) {
  const { t } = useTranslation();

  const stats = [
    {
      value: suitablePercentage,
      label: t('components.MyDashboard.JobsAppliedStatus.suitableLabel'),
      color: '#D52D34',
    },
    {
      value: unsuitablePercentage,
      label: t('components.MyDashboard.JobsAppliedStatus.unsuitableLabel'),
      color: '#FFE7E8',
    },
  ];

  return (
    <div className={classes.JobsAppliedStatus}>
      <h2>
        {t('components.MyDashboard.JobsAppliedStatus.jobsAppliedStatusHeading')}
      </h2>
      <DonutChart
        roundedCaps={false}
        showTotal={false}
        size={143}
        tooltipSx={{ display: 'none' }}
        trackWidth="lg"
        items={stats}
      />
      <div className={classes.legend}>
        {stats.map((stat) => {
          return (
            <div className={classes.legendElement}>
              <div
                className={classes.color}
                style={{ backgroundColor: stat.color }}
              />
              <div className={classes.value}>{stat.value}%</div>
              <div className={classes.label}>{stat.label}</div>
            </div>
          );
        })}
      </div>
      <div
        className={classes.viewAllButton}
        type="button"
        onClick={focusOnJobsApplied}
      >
        {t('components.MyDashboard.JobsAppliedStatus.viewAllJobsButton')}
      </div>
    </div>
  );
}
