import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Feedback from '../Feedback';
import ContactUs from '../ContactUs';
import MessageWithButton from '../MessageWithButton';

import classes from './styles.module.scss';

export default function ContactOrShareFeedback({
  message,
  jobApplicationId,
  offer,
  jobId,
  withCalendarButton,
  schedule,
  scheduleType,
  hideJobsApplicationInfo,
  style,
  messagePaddingTop,
}) {
  const [mode, setMode] = useState('message');

  const { t } = useTranslation();

  return (
    <div className={classes.ContactOrShareFeedback} style={style}>
      {mode !== 'message' && (
        <i className={classes.backButton} onClick={() => setMode('message')}>
          Back
        </i>
      )}
      {mode === 'message' && (
        <MessageWithButton
          paddingTop={messagePaddingTop}
          message={message}
          buttonLabel={t(
            'components.MyDashboard.JobApplicationInfo.ContactOrShareFeedback.shareFeedback'
          )}
          onButtonClick={() => setMode('feedback')}
          secondaryButtonLabel={t(
            'components.MyDashboard.JobApplicationInfo.ContactOrShareFeedback.contactUs'
          )}
          onSecondaryButtonClick={() => setMode('contact')}
          offer={offer}
          jobId={jobId}
          withCalendarButton={withCalendarButton}
          schedule={schedule}
          scheduleType={scheduleType}
        />
      )}
      {mode === 'contact' && (
        <div className={classes.contactUsContainer}>
          <ContactUs
            jobApplicationId={jobApplicationId}
            hideJobsApplicationInfo={hideJobsApplicationInfo}
          />
        </div>
      )}
      {mode === 'feedback' && (
        <div className={classes.contactUsContainer}>
          <Feedback
            jobApplicationId={jobApplicationId}
            hideJobsApplicationInfo={hideJobsApplicationInfo}
          />
        </div>
      )}
    </div>
  );
}
