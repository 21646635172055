import React, { useState, useEffect, useRef, useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useQuery, useIsFetching } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import JobApplicationInfo from '../../components/MyDashboard/JobApplicationInfo';
import Job from '../../components/Jobs/Job';
import Actions from '../../components/MyDashboard/Actions';
import Carousel from '../../components/MyDashboard/Carousel';
import JobsAppliedStatus from '../../components/MyDashboard/JobsAppliedStatus';
import SimpleStat from '../../components/MyDashboard/SimpleStat';
import UserBlock from '../../components/MyDashboard/UserBlock';

import useChatConnection from '../../hooks/useChatConnection';
import { UiContext } from '../../context/UiContext';
import PublicService from '../../services/PublicService';
import classes from './styles.module.scss';
import JobsControls from '../../components/MyDashboard/JobsControls';

export default function MyDashboardPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isJobApplicationInfoVisible, setIsJobApplicationInfoVisible] =
    useState(false);
  const [isJobApplicationInfoMounted, setIsJobApplicationInfoMounted] =
    useState(false);
  const [currentJobApplicationId, setCurrentJobApplicationId] = useState(null);
  const [jobApplicationInfoInitialMode, setJobApplicationInfoInitialMode] =
    useState('jobApplicationProgress');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const jobsControlsRef = useRef();

  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState(t('pages.MyDashboardPage.oldest'));

  const { dashboardJobApplicationsFilter, setDashboardJobApplicationsFilter } =
    useContext(UiContext);

  const isSomethingFetching = useIsFetching();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobApplicationId = searchParams.get('jobApplicationId');
  const navigate = useNavigate();

  const {
    data: jobApplications,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: PublicService.getJobApplications,
    keepPreviousData: true,
  });

  const filteredJobApplications = jobApplications?.filter(
    (jobApplication) => jobApplication?.status !== 'Draft'
  );

  const totalJobsAppliedCount =
    filteredJobApplications?.filter(
      (jobApplication) =>
        jobApplication.status !== 'Invited' && jobApplication.status !== 'Draft'
    ).length || 0;

  const showJobApplicationInfo = (jobAppId) => {
    setCurrentJobApplicationId(jobAppId);
    setIsJobApplicationInfoMounted(true);
    setJobApplicationInfoInitialMode('jobApplicationProgress');

    setTimeout(() => {
      setIsJobApplicationInfoVisible(true);
    }, 0);
  };

  const { connection } = useChatConnection();

  // If jobApplicationId is in the URL, show the job application info but wait for the jobApplications to be fetched
  useEffect(() => {
    if (jobApplicationId && isFetched) {
      showJobApplicationInfo(+jobApplicationId);

      // After showing the job application info, remove the jobApplicationId from the URL
      setTimeout(() => {
        navigate(location.pathname, { replace: true });
      }, 100);
    }
  }, [jobApplicationId, isFetched, navigate, location.pathname]);

  useEffect(() => {
    if (connection) {
      connection.on('NewMessage', () => {
        refetch();
      });
    }

    return () => {
      connection?.off?.('NewMessage');
    };
  }, [connection, refetch]);

  const showMessages = (jobAppId) => {
    setCurrentJobApplicationId(jobAppId);
    setIsJobApplicationInfoMounted(true);
    setJobApplicationInfoInitialMode('messages');

    setTimeout(() => {
      setIsJobApplicationInfoVisible(true);
    }, 0);
  };

  const hideJobsApplicationInfo = () => {
    setIsJobApplicationInfoVisible(false);
  };

  const focusOnJobsApplied = () => {
    setDashboardJobApplicationsFilter(t('common.myJobsFilters.Applied'));
    jobsControlsRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (!isJobApplicationInfoVisible && !jobApplicationId) {
      setTimeout(() => {
        setIsJobApplicationInfoMounted(false);
      }, 500);
    }
  }, [isJobApplicationInfoVisible, jobApplicationId]);

  useEffect(() => {
    if (dashboardJobApplicationsFilter === t('common.myJobsFilters.Actions')) {
      setSortOrder(t('pages.MyDashboardPage.latest'));

      return;
    }

    setSortOrder(t('pages.MyDashboardPage.oldest'));
  }, [dashboardJobApplicationsFilter, t]);

  const offerCount = filteredJobApplications?.reduce((acc, jobApp) => {
    if (jobApp.status === 'Offer') {
      return acc + 1;
    }
    return acc;
  }, 0);

  const suitableCount = filteredJobApplications?.reduce((acc, jobApp) => {
    if (jobApp.subStatus !== 'Rejected') {
      return acc + 1;
    }
    return acc;
  }, 0);

  const suitablePercentage = Math.round(
    (suitableCount / filteredJobApplications?.length) * 100
  );
  const unsuitablePercentage = 100 - suitablePercentage;

  const appliedJobApplications = filteredJobApplications?.filter(
    (jobApp) => jobApp.status !== 'Invited'
  );

  const invitedJobApplications = filteredJobApplications?.filter(
    (jobApp) => jobApp.status === 'Invited' && jobApp.jobPostId
  );

  const actionSubStatuses = [
    'Invited',
    'Scheduled',
    'Rescheduled',
    'Offered',
    'StartConfirmed',
    'Requested',
    'StartDateSet',
  ];

  const actionsJobApplications = filteredJobApplications?.filter(
    (jobApp) =>
      actionSubStatuses.includes(jobApp.subStatus) ||
      (jobApp.status === 'Invited' && jobApp.jobPostId)
  );

  let jobApplicationsToShow = appliedJobApplications;

  if (dashboardJobApplicationsFilter === t('common.myJobsFilters.Invited')) {
    jobApplicationsToShow = invitedJobApplications;
  }
  if (dashboardJobApplicationsFilter === t('common.myJobsFilters.Actions')) {
    jobApplicationsToShow = actionsJobApplications;
  }

  if (searchTerm) {
    jobApplicationsToShow = jobApplicationsToShow?.filter(
      (jobApp) =>
        jobApp.jobPost.jobTitle
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        jobApp.jobPost.locationCity?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        jobApp.jobPost.locationCityDistrict?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        jobApp.jobPost.jobTypes
          ?.map((type) => type.name)
          .join('')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
  }
  if (sortOrder === t('pages.MyDashboardPage.latest')) {
    jobApplicationsToShow = jobApplicationsToShow?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  } else {
    jobApplicationsToShow = jobApplicationsToShow?.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }

  const noDataMessage = searchTerm
    ? t('pages.MyDashboardPage.noResults')
    : t('pages.MyDashboardPage.youWillSeeAllTheJobsYouAppliedHere');

  return (
    <div className={classes.MyDashboardPage}>
      {isSomethingFetching ? <Loader /> : null}
      <div className={classes.row}>
        <div className={classes.col}>
          <div className={classes.userBlockContainer}>
            <UserBlock />
          </div>
          <Carousel />
        </div>
        <div className={classNames(classes.col, classes.statsContainer)}>
          <SimpleStat
            value={totalJobsAppliedCount}
            title={t('pages.MyDashboardPage.totalJobsApplied')}
          />
          <SimpleStat
            value={offerCount || 0}
            title={t('pages.MyDashboardPage.offers')}
          />
        </div>
        {isTabletOrMobile ? (
          <div className={classes.actionsAndJobAppliedStatusContainer}>
            <JobsAppliedStatus
              suitablePercentage={suitablePercentage}
              unsuitablePercentage={unsuitablePercentage}
              focusOnJobsApplied={focusOnJobsApplied}
            />
            <Actions />
          </div>
        ) : (
          <>
            <div className={classes.col}>
              <JobsAppliedStatus
                suitablePercentage={suitablePercentage}
                unsuitablePercentage={unsuitablePercentage}
                focusOnJobsApplied={focusOnJobsApplied}
              />
            </div>
            <div className={classNames(classes.col, classes.actionsContainer)}>
              <Actions />
            </div>
          </>
        )}
      </div>
      <div className={classes.row}>
        <div className={classNames(classes.col, classes.jobsContainer)}>
          <h2>{t('pages.MyDashboardPage.myJobs')}</h2>
          <div className={classes.jobsControlsContainer} ref={jobsControlsRef}>
            <JobsControls
              jobApplicationCount={appliedJobApplications?.length}
              invitedJobApplicationCount={invitedJobApplications?.length}
              actionsJobApplicationCount={actionsJobApplications?.length}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSearchTerm={setSearchTerm}
              currentJobApplicationsFilter={dashboardJobApplicationsFilter}
              setCurrentJobApplicationsFilter={
                setDashboardJobApplicationsFilter
              }
              isFetched={isFetched}
            />
          </div>

          {jobApplicationsToShow?.length === 0 ? (
            <span className={classes.noJobs}>{noDataMessage}</span>
          ) : (
            <div className={classes.jobs}>
              {jobApplicationsToShow?.map((jobApp) => {
                return (
                  <Job
                    applied
                    showJobApplicationInfo={showJobApplicationInfo}
                    stretch
                    refetch={refetch}
                    key={jobApp.id}
                    job={jobApp.jobPost}
                    createdAt={jobApp.createdAt}
                    jobApplication={jobApp}
                    showMessages={showMessages}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {isJobApplicationInfoMounted && (
        <JobApplicationInfo
          isVisible={isJobApplicationInfoVisible}
          currentJobApplication={jobApplications?.find(
            (jobApp) => jobApp.id === currentJobApplicationId
          )}
          hideJobsApplicationInfo={hideJobsApplicationInfo}
          refetch={refetch}
          initialMode={jobApplicationInfoInitialMode}
        />
      )}
    </div>
  );
}
