import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Review({ isInvited }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Review}>
      <p>
        {isInvited
          ? t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Review.invitationThanks'
            )
          : t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Review.underReview'
            )}
        <br />
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Review.newsShortly'
        )}
      </p>
    </div>
  );
}
