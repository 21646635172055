import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  TelegramShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';

import PublicService from '../../services/PublicService';
import telegramIcon from '../../assets/telegram-icon.svg';
import whatsupIcon from '../../assets/whatsup-icon.svg';
import linkedinIcon from '../../assets/linkedin-icon.svg';
import facebookIcon from '../../assets/facebook-icon.svg';
import twitterIcon from '../../assets/twitter-icon.svg';
import linkIcon from '../../assets/link-icon.svg';

export default function ShareIcons({ classes, jobTitle }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: tenantSettings } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: PublicService.getTenantSettings,
  });

  const sortableLinks = {
    Facebook: (
      <li className={classes['JobPage__share-item']} key="Facebook">
        <FacebookShareButton
          className={classes['JobPage__share-btn']}
          quote={jobTitle}
          url={window.location.href}
        >
          <img
            width={isTabletOrMobile ? 24 : 30}
            height={isTabletOrMobile ? 24 : 30}
            className={classes.icon}
            src={facebookIcon}
            alt="Icon"
          />
        </FacebookShareButton>
      </li>
    ),
    Twitter: (
      <li className={classes['JobPage__share-item']} key="Twitter">
        <TwitterShareButton
          className={classes['JobPage__share-btn']}
          title={jobTitle}
          url={window.location.href}
        >
          <img
            width={isTabletOrMobile ? 24 : 30}
            height={isTabletOrMobile ? 24 : 30}
            className={classes.icon}
            src={twitterIcon}
            alt="Icon"
          />
        </TwitterShareButton>
      </li>
    ),
    LinkedIn: (
      <li className={classes['JobPage__share-item']} key="LinkedIn">
        <LinkedinShareButton
          className={classes['JobPage__share-btn']}
          title={jobTitle}
          url={window.location.href}
        >
          <img
            width={isTabletOrMobile ? 24 : 30}
            height={isTabletOrMobile ? 24 : 30}
            className={classes.icon}
            src={linkedinIcon}
            alt="Icon"
          />
        </LinkedinShareButton>
      </li>
    ),
  };

  const sortedLinks = tenantSettings?.sodialMediaLinks
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((lnk) => {
      if (sortableLinks[lnk.type] && !lnk.isHidden) {
        return sortableLinks[lnk.type];
      }
      return null;
    });

  const copyLinkToClipboard = async () => {
    try {
      const link = window.location.href;
      await navigator.clipboard.writeText(link);
      toast('Copied to clipboard');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ul className={classes['JobPage__share-list']}>
        {sortedLinks}
        <li className={classes['JobPage__share-item']}>
          <TelegramShareButton
            className={classes['JobPage__share-btn']}
            title={jobTitle}
            url={window.location.href}
          >
            <img
              width={isTabletOrMobile ? 24 : 30}
              height={isTabletOrMobile ? 24 : 30}
              className={classes.icon}
              src={telegramIcon}
              alt="Icon"
            />
          </TelegramShareButton>
        </li>
        <li className={classes['JobPage__share-item']}>
          <WhatsappShareButton
            className={classes['JobPage__share-btn']}
            title={jobTitle}
            url={window.location.href}
          >
            <img
              width={isTabletOrMobile ? 24 : 30}
              height={isTabletOrMobile ? 24 : 30}
              className={classes.icon}
              src={whatsupIcon}
              alt="Icon"
            />
          </WhatsappShareButton>
        </li>
        <li className={classes['JobPage__share-item']}>
          <button
            className={classes['JobPage__share-btn']}
            type="button"
            onClick={copyLinkToClipboard}
          >
            <img
              width={isTabletOrMobile ? 24 : 30}
              height={isTabletOrMobile ? 24 : 30}
              className={classes.icon}
              src={linkIcon}
              alt="Icon"
            />
          </button>
        </li>
      </ul>
      <ToastContainer />
    </>
  );
}
