import React from 'react';

// eslint-disable-next-line import/no-named-default
import { default as CalendarLib } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

import './calendar.scss';

const tileDisabled = ({ date }) => {
  return date < new Date();
};

export default function Calendar({
  calendarRef,
  value,
  setValue,
  selectRange,
}) {
  const { t } = useTranslation();

  return (
    <div ref={calendarRef}>
      <CalendarLib
        className="calendarComponent"
        selectRange={selectRange}
        formatShortWeekday={(_locale, date) =>
          [
            t('common.shortDays.Su'),
            t('common.shortDays.Mo'),
            t('common.shortDays.Tu'),
            t('common.shortDays.We'),
            t('common.shortDays.Th'),
            t('common.shortDays.Fr'),
            t('common.shortDays.Sa'),
          ][date.getDay()]
        }
        value={value}
        onChange={setValue}
        tileDisabled={tileDisabled}
      />
    </div>
  );
}
