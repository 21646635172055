import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FilterButton({
  filter,
  currentFilter,
  setCurrentFilter,
  isActive,
}) {
  return (
    <div
      className={classNames(classes.FilterButton, {
        [classes.active]:
          isActive !== undefined
            ? isActive
            : currentFilter?.title === filter.title,
      })}
      onClick={() => setCurrentFilter(filter)}
    >
      {filter.title} <div className={classes.count}>{filter.count}</div>
    </div>
  );
}
