import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function MainButton({
  isVideoRecording,
  isVideoRecordingPaused,
  isVideoPreviewPlaying,
  file,
  startVideoRecording,
  stopVideoRecording,
  resumeVideoRecording,
  playPreviewVideo,
  pausePreviewVideo,
  mode,
  takePhoto,
}) {
  let jsx;

  const photoButton = (
    <button
      type="button"
      className={classNames(classes.recordButton, classes.button)}
      onClick={takePhoto}
    >
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#D52D34" />
        <path
          d="M42 35.5556C42 36.2039 41.7414 36.8256 41.2811 37.284C40.8208 37.7425 40.1964 38 39.5455 38H17.4545C16.8036 38 16.1792 37.7425 15.7189 37.284C15.2586 36.8256 15 36.2039 15 35.5556V22.1111C15 21.4628 15.2586 20.841 15.7189 20.3826C16.1792 19.9242 16.8036 19.6667 17.4545 19.6667H22.3636L24.8182 16H32.1818L34.6364 19.6667H39.5455C40.1964 19.6667 40.8208 19.9242 41.2811 20.3826C41.7414 20.841 42 21.4628 42 22.1111V35.5556Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.5 31.5508C30.9853 31.5508 33 29.7599 33 27.5508C33 25.3416 30.9853 23.5508 28.5 23.5508C26.0147 23.5508 24 25.3416 24 27.5508C24 29.7599 26.0147 31.5508 28.5 31.5508Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );

  const recordButton = (
    <button
      type="button"
      className={classNames(classes.recordButton, classes.button)}
      onClick={startVideoRecording}
    >
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#D52D34" />
      </svg>
    </button>
  );

  const stopButton = (
    <button
      type="button"
      className={classNames(classes.stopButton, classes.button)}
      onClick={stopVideoRecording}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" rx="8" fill="#D52D34" />
      </svg>
    </button>
  );

  const resumeButton = (
    <button
      type="button"
      className={classNames(classes.resumeButton, classes.button)}
      onClick={resumeVideoRecording}
    >
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35" cy="35" r="33.5" stroke="white" strokeWidth="3" />
        <path
          d="M26 45L26 26"
          stroke="#00E4AC"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M51.1398 34.1888L33.2285 24.238C32.2287 23.6826 31 24.4055 31 25.5493V45.4507C31 46.5945 32.2287 47.3174 33.2285 46.762L51.1398 36.8112C52.1685 36.2397 52.1685 34.7603 51.1398 34.1888Z"
          fill="#00E4AC"
        />
      </svg>
    </button>
  );

  const playPreviewButton = (
    <button
      type="button"
      className={classNames(classes.playPreviewButton, classes.button)}
      onClick={playPreviewVideo}
    >
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35" cy="35" r="35" fill="#D52D34" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 57.5C47.4264 57.5 57.5 47.4264 57.5 35C57.5 22.5736 47.4264 12.5 35 12.5C22.5736 12.5 12.5 22.5736 12.5 35C12.5 47.4264 22.5736 57.5 35 57.5ZM29.2828 23.4904L48.4265 34.1258C49.1123 34.5068 49.1123 35.4932 48.4265 35.8742L29.2828 46.5096C28.4829 46.9539 27.5 46.3756 27.5 45.4606V24.5394C27.5 23.6244 28.4829 23.0461 29.2828 23.4904Z"
          fill="white"
        />
      </svg>
    </button>
  );

  const pausePreviewButton = (
    <button
      type="button"
      className={classNames(classes.pausePreviewButton, classes.button)}
      onClick={pausePreviewVideo}
    >
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35" cy="35" r="35" fill="#D52D34" />
        <rect x="20" y="17.5" width="10" height="35" rx="1" fill="white" />
        <rect x="40" y="17.5" width="10" height="35" rx="1" fill="white" />
      </svg>
    </button>
  );

  if (isVideoRecording && !isVideoRecordingPaused) {
    jsx = stopButton;
  } else if (isVideoRecording && isVideoRecordingPaused) {
    jsx = resumeButton;
  } else if (!isVideoRecording && file && !isVideoPreviewPlaying) {
    jsx = playPreviewButton;
  } else if (!isVideoRecording && file && isVideoPreviewPlaying) {
    jsx = pausePreviewButton;
  } else if (mode === 'prePhoto') {
    jsx = photoButton;
  } else {
    jsx = recordButton;
  }

  return jsx;
}
