/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-bitwise */
import Cookies from 'js-cookie';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // prettier-ignore
    const r = Math.random() * 16 | 0;
    // prettier-ignore
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

let sessionId = Cookies.get('sessionId');

if (!sessionId) {
  sessionId = uuidv4();
  Cookies.set('sessionId', sessionId);
}

export default sessionId;
