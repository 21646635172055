import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../Button';

import classes from './styles.module.scss';

export default function Congratulations({ showOffer }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { t } = useTranslation();

  return (
    <div className={classes.Congratulations}>
      <p className={classes.heading}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.Congratulations.heading.0'
        )}
        <br />
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.Congratulations.heading.1'
        )}
      </p>
      <Button
        jobApplicationInfo
        width={isTabletOrMobile ? '' : 444}
        height={isTabletOrMobile ? 54 : 64}
        onClick={showOffer}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 16L11.7929 16.7071L12.5 17.4142L13.2071 16.7071L12.5 16ZM13.5 4C13.5 3.44772 13.0523 3 12.5 3C11.9477 3 11.5 3.44772 11.5 4L13.5 4ZM5.79289 10.7071L11.7929 16.7071L13.2071 15.2929L7.20711 9.29289L5.79289 10.7071ZM13.2071 16.7071L19.2071 10.7071L17.7929 9.29289L11.7929 15.2929L13.2071 16.7071ZM13.5 16L13.5 4L11.5 4L11.5 16L13.5 16Z"
            fill="#F2F4F8"
          />
          <path d="M5.5 21L19.5 21" stroke="#F2F4F8" strokeWidth="2" />
        </svg>
        <span style={{ marginLeft: 10 }}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.Congratulations.viewOfferButton'
          )}
        </span>
      </Button>
    </div>
  );
}
