/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useContext, useEffect, useMemo, memo } from 'react';

import { useTranslation } from 'react-i18next';

import { UiContext } from '../../../context/UiContext';
import classes from './styles.module.scss';

function ReviewVideos({ recordedMedia, restartRecording, uploadMedia }) {
  const componentRef = useRef();

  const { isFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const replay = () => {
    componentRef.current.querySelectorAll('video')?.forEach((video, index) => {
      video.style.display = 'block';
      video.pause();
      video.currentTime = 0;
      if (index === 0) {
        video.play();
      }
    });
  };

  useEffect(() => {
    if (isFetching) {
      componentRef.current.querySelectorAll('video')?.forEach((video) => {
        video.pause();
      });
    }
  }, [isFetching]);

  const videos = useMemo(
    () =>
      recordedMedia.map((video, index) => {
        return (
          <video
            id="preview"
            playsInline
            style={{
              zIndex: recordedMedia.length - index,
            }}
            src={URL.createObjectURL(video)}
            autoPlay={index === 0}
            onEnded={(event) => {
              event.target.nextSibling?.play?.();
              if (index !== recordedMedia.length - 1) {
                // eslint-disable-next-line no-param-reassign
                event.target.style.display = 'none';
              }
            }}
          />
        );
      }),

    [recordedMedia]
  );

  return (
    <div className={classes.ReviewVideos} ref={componentRef}>
      {videos}

      <div className={classes.buttons}>
        <button type="button" className={classes.replayButton} onClick={replay}>
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="35" cy="35" r="35" fill="#D52D34" />
            <g clipPath="url(#clip0_1019_34026)">
              <path
                d="M37.2729 14C37.7383 14.1775 38.2361 14.1526 38.7177 14.224C42.327 14.7565 45.5597 16.1667 48.4677 18.3593C48.6972 18.5325 48.6452 18.6104 48.4785 18.7738C47.7643 19.474 47.0511 20.1753 46.3627 20.8994C46.1333 21.1407 45.9926 21.1742 45.7069 20.9654C43.9006 19.6504 41.9136 18.6981 39.7307 18.2154C33.5521 16.8496 28.1474 18.3636 23.6614 22.8247C20.1268 26.3398 18.484 30.6883 18.7556 35.6667C19.05 41.0617 21.4807 45.3723 25.8075 48.5758C29.0251 50.9578 32.6874 52.0779 36.6679 51.7738C43.4298 51.2554 48.2989 47.8496 51.2729 41.7359C51.3378 41.6028 51.3887 41.4632 51.4515 41.329C51.8995 40.3647 52.9482 39.9372 53.8801 40.3377C54.8249 40.7435 55.2578 41.8247 54.8454 42.8139C53.7459 45.4535 52.1712 47.7749 50.1084 49.7511C47.0013 52.7273 43.3151 54.5606 39.0781 55.2781C38.5738 55.3636 38.0608 55.4004 37.5521 55.4621C37.4796 55.4708 37.3844 55.4286 37.3541 55.5433C36.2469 55.5433 35.1409 55.5433 34.0337 55.5433C33.9894 55.4156 33.8768 55.4697 33.7924 55.4578C33.1398 55.3593 32.4818 55.29 31.8346 55.1667C28.1549 54.4621 24.8898 52.8788 22.0943 50.3853C17.6679 46.4372 15.287 41.4784 15.0218 35.5422C14.8552 31.8236 15.6409 28.2835 17.445 25.0249C20.7848 18.9935 25.8627 15.3766 32.6701 14.2273C33.1246 14.1504 33.5987 14.1883 34.0348 14C35.1138 14 36.1939 14 37.2729 14Z"
                fill="white"
              />
              <path
                d="M29.6225 35.3408C29.6225 33.2997 29.6453 31.2575 29.6139 29.2164C29.5955 28.0475 30.0046 27.1547 31.0641 26.632C32.1842 26.0789 33.3022 26.1309 34.3054 26.8863C35.6842 27.9253 37.0208 29.0205 38.3747 30.093C39.7708 31.198 41.1658 32.3062 42.5641 33.4101C43.971 34.5216 43.9775 36.133 42.5792 37.2413C39.892 39.369 37.208 41.501 34.5197 43.6266C33.3141 44.58 31.787 44.632 30.6301 43.7683C29.9277 43.2445 29.6182 42.5378 29.6214 41.6677C29.6269 39.5595 29.6225 37.4501 29.6225 35.3408Z"
                fill="white"
              />
              <path
                d="M53.9616 22.0141C53.9616 22.8117 53.9681 23.6093 53.9594 24.4059C53.9486 25.4427 53.2549 26.2446 52.2213 26.2728C50.5373 26.3182 48.8501 26.3031 47.165 26.2695C46.3718 26.2533 45.835 25.7814 45.5622 25.0487C45.2992 24.3399 45.441 23.6818 45.9735 23.144C47.5882 21.513 49.2083 19.8864 50.8393 18.2717C51.4064 17.71 52.1034 17.6202 52.8241 17.9145C53.5514 18.2121 53.9453 18.7825 53.9573 19.5801C53.9692 20.3907 53.9594 21.2013 53.9594 22.013C53.9605 22.0141 53.9616 22.0141 53.9616 22.0141Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1019_34026">
                <rect
                  width="40"
                  height="41.5433"
                  fill="white"
                  transform="translate(15 14)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          type="button"
          className={classes.retakeButton}
          onClick={restartRecording}
        >
          {t('components.RecordVideo.ReviewVideos.retake')}
        </button>
        <button
          type="button"
          className={classes.useButton}
          onClick={uploadMedia}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="9"
              cy="9"
              r="7.5"
              fill="#D52D34"
              stroke="white"
              strokeWidth="1.2"
            />
            <path
              d="M5.66634 9L8.16634 11.5L12.333 6.5"
              stroke="white"
              strokeWidth="1.2"
            />
          </svg>
          <span>{t('components.RecordVideo.ReviewVideos.use')}</span>
        </button>
      </div>
    </div>
  );
}

export default memo(ReviewVideos, (prevProps, nextProps) => {
  const isRecordedMediaEqual =
    prevProps.recordedMedia.length === nextProps.recordedMedia.length &&
    prevProps.recordedMedia.every(
      (value, index) => value.size === nextProps.recordedMedia[index].size
    );
  return (
    isRecordedMediaEqual &&
    prevProps.restartRecording === nextProps.restartRecording &&
    prevProps.uploadMedia === nextProps.uploadMedia
  );
});
