/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, memo } from 'react';

import { useTranslation } from 'react-i18next';

import VideoPlayerModal from '../../../../modals/VideoPlayerModal';
import ResumeHeader from '../ResumeHeader';
import AlertModal from '../../../../modals/AlertModal';

// import { UiContext } from '../../../../context/UiContext';
import formatVideoDuration from '../../../../helpers/formatVideoDuration';
import classes from './styles.module.scss';

const VideoResume = memo(
  ({ file, setFile, resume, isResumeDeleted, setIsResumeDeleted }) => {
    const [videoDuration, setVideoDuration] = useState(0);
    const [isVideoPlayerModalVisible, setIsVideoPlayerModalVisible] =
      useState(false);
    const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);

    console.log(file, resume, isResumeDeleted);

    const fileInputRef = useRef();
    const videoRef = useRef();

    //  const { showModal } = useContext(UiContext);

    const { t } = useTranslation();

    const deleteResume = (event) => {
      event.stopPropagation();

      setFile(null);
      setIsResumeDeleted(true);
      fileInputRef.current.value = '';

      /*  showModal({
        title: t('components.EditProfile.Resume.VideoResume.deleteResumeTitle'),
        text: t(
          'components.EditProfile.Resume.VideoResume.deleteResumeConfirmation'
        ),
        onConfirm: () => {
          setFile(null);
          setIsResumeDeleted(true);
          fileInputRef.current.value = '';
        },
      }); */
    };

    const handleFileDrop = (event) => {
      event.preventDefault();

      if (file || (resume && !isResumeDeleted)) {
        return;
      }

      if (event.dataTransfer.items) {
        const droppedEntity = event.dataTransfer?.items[0];
        if (
          droppedEntity.kind === 'file' &&
          (droppedEntity.type === 'video/webm' ||
            droppedEntity.type === 'video/mp4')
        ) {
          const droppedFile = droppedEntity.getAsFile();
          setFile(droppedFile);
          setIsResumeDeleted(false);
        }
      }
    };

    useEffect(() => {
      const updateVideoDuration = () => {
        if (videoRef.current.duration === Infinity) {
          videoRef.current.currentTime = 1e101;
          videoRef.current.ontimeupdate = () => {
            if (videoRef?.current?.duration) {
              setVideoDuration(videoRef?.current?.duration);
            }
          };
        } else {
          setVideoDuration(videoRef.current.duration);
        }
      };

      if (videoRef.current) {
        videoRef.current.addEventListener(
          'loadedmetadata',
          updateVideoDuration
        );
      }

      return () => {
        videoRef.current?.removeEventListener(
          'loadedmetadata',
          updateVideoDuration
        );
      };
    }, [file, resume]);

    const videoUrl = file ? URL.createObjectURL(file) : resume?.url;

    return (
      <div
        className={classes.VideoResume}
        onClick={() => {
          if (file || (resume && !isResumeDeleted)) {
            return;
          }
          fileInputRef.current.click();
        }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleFileDrop}
      >
        <ResumeHeader
          title={t(
            'components.EditProfile.Resume.VideoResume.videoResumeTitle'
          )}
        />
        <div className={classes.uploader}>
          {(resume || file) && !isResumeDeleted ? (
            <div className={classes.videoContainer}>
              <video
                playsInline
                onClick={() => setIsVideoPlayerModalVisible(true)}
                ref={videoRef}
                src={videoUrl}
              />
              <span className={classes.videoDuration}>
                {formatVideoDuration(videoDuration)?.replace('.', ':')}
              </span>
            </div>
          ) : (
            <p>
              <span className={classes.gray}>
                {t('components.EditProfile.Resume.VideoResume.dragAndDrop')}
              </span>{' '}
              <span className={classes.red}>
                {t('components.EditProfile.Resume.VideoResume.browse')}
              </span>{' '}
              ({t('components.EditProfile.Resume.VideoResume.webMOrMP4Only')})
            </p>
          )}
          {(!resume && !file) || isResumeDeleted ? (
            <button className={classes.addButton} type="button">
              +
            </button>
          ) : (
            <button
              className={classes.deleteButton}
              type="button"
              onClick={deleteResume}
            >
              -
            </button>
          )}
        </div>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          accept=".webm,.mp4"
          onChange={(event) => {
            setFile(event.target.files[0]);
            setIsResumeDeleted(false);
          }}
        />
        <VideoPlayerModal
          show={isVideoPlayerModalVisible}
          handleClose={() => setIsVideoPlayerModalVisible(false)}
          videoUrl={videoUrl}
        />
        {/*
          AlertModal is used here directly and not called via context to prevent unnecessary re-renders of video player
        */}
        <AlertModal
          onConfirm={() => {
            setFile(null);
            setIsResumeDeleted(true);
            fileInputRef.current.value = '';
          }}
          show={isAlertModalVisible}
          title={t(
            'components.EditProfile.Resume.VideoResume.deleteResumeTitle'
          )}
          text={t(
            'components.EditProfile.Resume.VideoResume.deleteResumeConfirmation'
          )}
          handleClose={() => {
            setIsAlertModalVisible(false);
          }}
        />
      </div>
    );
  }
);

export default VideoResume;
